const CarouselWriters = (color: string) => {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 867 1000"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip3)">
        <path
          d="M115.335 222.724C115.335 158.106 167.718 105.724 232.335 105.724H839.937C854.849 105.724 866.937 117.812 866.937 132.724V972.572C866.937 987.484 854.849 999.572 839.937 999.572H232.335C167.718 999.572 115.335 947.189 115.335 882.572V222.724Z"
          fill="currentColor"
          className={color}
        />
        <rect
          x="43.3862"
          y="704.762"
          width="707.937"
          height="161.905"
          fill="black"
        />
        <path
          d="M730.817 23.5535V23.5567V692.558H722.464H704.704H683.704H113.602C66.979 692.558 28.9466 729.117 26.2395 774.979L26.1963 775.712L26.2043 776.446C26.2226 778.12 26.2476 779.488 26.2692 780.519C26.2582 780.635 26.2464 780.766 26.2347 780.907C26.2155 781.14 26.1955 781.412 26.1787 781.715L26.1231 782.72L26.1638 783.726C28.0527 830.34 66.4516 867.739 113.602 867.739H704.702H725.702L725.702 846.739L725.704 734.558H730.817L730.815 870.293C730.815 871.698 729.665 872.85 728.259 872.85H110.394C110.362 872.846 110.324 872.841 110.28 872.835C110.116 872.811 109.925 872.783 109.632 872.738L108.701 872.594L107.76 872.535C59.3528 869.495 20.9031 829.234 20.8853 780.182C20.8866 780.156 20.8932 779.997 20.9233 779.567C20.9272 779.512 20.9321 779.445 20.9378 779.366C20.9765 778.831 21.0525 777.782 21.0862 776.75L21.101 776.299L21.0963 775.847C20.3799 706.063 21.0973 174.204 21.1792 116.176L21.1807 115.107L21.0735 114.042C21.0452 113.762 21.0162 113.51 20.9903 113.298C20.9708 113.138 20.952 112.992 20.9357 112.869C21.3824 62.1615 62.8087 21 113.635 21H151.277H722.51H728.261C729.667 21 730.818 22.1568 730.817 23.5535Z"
          fill="black"
          stroke="white"
          strokeWidth="42"
        />
        <rect
          x="131.674"
          y="98.0352"
          width="43.2507"
          height="513.242"
          rx="21.6254"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip3">
          <rect width="866.667" height="1000" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CarouselWriters
