import React, { useCallback, useEffect, useMemo, useState } from "react"
import { CoinbaseWalletLogo } from "./CoinbaseWalletLogo"
import { WalletButton } from "@rainbow-me/rainbowkit"

const GRADIENT_BORDER_WIDTH = 2

const buttonStyles = {
  background: "transparent",
  border: "1px solid transparent",
  boxSizing: "border-box"
}

function Gradient({
  children,
  style,
  isAnimationDisabled = false
}: {
  children: React.ReactNode
  style: React.CSSProperties
  isAnimationDisabled?: boolean
}) {
  const [isAnimating, setIsAnimating] = useState(false)
  const gradientStyle = useMemo(() => {
    const rotate = isAnimating ? "720deg" : "0deg"
    return {
      transform: `rotate(${rotate})`,
      transition: isAnimating
        ? "transform 2s cubic-bezier(0.27, 0, 0.24, 0.99)"
        : "none",
      ...style
    }
  }, [isAnimating, style])

  const handleMouseEnter = useCallback(() => {
    if (isAnimationDisabled || isAnimating) return
    setIsAnimating(true)
  }, [isAnimationDisabled, isAnimating, setIsAnimating])

  useEffect(() => {
    if (!isAnimating) return
    const animationTimeout = setTimeout(() => {
      setIsAnimating(false)
    }, 2000)
    return () => {
      clearTimeout(animationTimeout)
    }
  }, [isAnimating])

  return (
    <div style={{ position: "relative" }} onMouseEnter={handleMouseEnter}>
      <div className="gradient-background" style={gradientStyle} />
      {children}
    </div>
  )
}

export function BlackCreateWalletButton({ height = 40, width = 180 }) {
  const minButtonHeight = 40
  const minButtonWidth = 180
  const buttonHeight = Math.max(minButtonHeight, height)
  const buttonWidth = Math.max(minButtonWidth, width)
  const gradientDiameter = Math.max(buttonHeight, buttonWidth)
  const styles = useMemo(
    () => ({
      gradientContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        borderRadius: buttonHeight / 2,
        height: buttonHeight,
        width: buttonWidth,
        boxSizing: "border-box",
        overflow: "hidden"
      },
      gradient: {
        background:
          "conic-gradient(from 180deg, #45E1E5 0deg, #0052FF 86.4deg, #B82EA4 165.6deg, #FF9533 255.6deg, #7FD057 320.4deg, #45E1E5 360deg)",
        position: "absolute",
        top: -buttonHeight * 2 - GRADIENT_BORDER_WIDTH,
        left: -GRADIENT_BORDER_WIDTH - 10,
        width: gradientDiameter + 20,
        height: gradientDiameter + 20
      },
      buttonBody: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        backgroundColor: "#000000",
        gap: 8,
        height: buttonHeight - GRADIENT_BORDER_WIDTH * 2,
        width: buttonWidth - GRADIENT_BORDER_WIDTH * 2,
        // fontFamily: "Arial, sans-serif",
        // fontWeight: "100",
        fontSize: 14,
        borderRadius: buttonHeight / 2,
        position: "relative",
        paddingRight: 6,
        color: "white"
      }
    }),
    [buttonHeight, buttonWidth, gradientDiameter]
  )

  return (
    <WalletButton.Custom wallet="coinbaseSW">
      {({ connect }) => {
        return (
          <button
            // @ts-ignore
            style={buttonStyles}
            type="button"
            onClick={connect}
          >
            <div
              // @ts-ignore
              style={styles.gradientContainer}
            >
              <Gradient
                // @ts-ignore
                style={styles.gradient}
              >
                <div
                  // @ts-ignore
                  style={styles.buttonBody}
                >
                  <CoinbaseWalletLogo />
                  <p className="font-medium">Create Wallet</p>
                </div>
              </Gradient>
            </div>
          </button>
        )
      }}
    </WalletButton.Custom>
  )
}
