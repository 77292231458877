export const constants = {
  "1": {
    production: {
      constants: {
        JBProjectId: "168",
        slicerIdCafe: "36"
      },
      strategies: {
        "Linear VRGDA": "0x3b155175B16AE142023827e03C61EF45f68D01b6",
        "Logistic VRGDA": "0xf50D1e551806b1A84cF5fd0C47BEF9f878555B82",
        "NFT Tiered Discount": ""
      },
      addresses: {
        SliceCore: "0x21da1b084175f95285B49b22C018889c45E1820d",
        ProductsModule: "0x689Bba0e25c259b205ECe8e6152Ee1eAcF307f5F",
        FundsModule: "0x6bcA3Dfd6c2b146dcdd460174dE95Fd1e26960BC",
        SlicerManager: "0x3e3E34f43a88EF99d448800B5d0579c6360a32b6",
        ChainlinkFeed: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
        PriceFeed: "0x71c96edD5D36935d5c8d6B78bCcD4113725297e3",
        DeployFactory: "0x3497b05B7466431FfA3DE7bF3cC037c083062f18",
        DeployCloneFactory: "0x76dad5968ab456b5a36698a252b30488a1a4f767",
        JBDirectory: "0xCc8f7a89d89c2AB3559f484E0C656423E979ac9C",
        JBFundingCycles: "0xf507B2A1dD7439201eb07F11E1d62AfB29216e2E",
        ETHPriceFeed: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        USDCPriceFeed: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        BaseBridge: ""
      }
    }
  },
  "5": {
    staging: {
      constants: {
        JBProjectId: "1",
        slicerIdCafe: ""
      },
      strategies: {
        "Linear VRGDA": "0xb32e0747B02717f13f82DB0f74C7CA453646Aa42",
        "Logistic VRGDA": "0x70A50E201036147296AE83d1F4E9B785B2a7CCe7",
        "NFT Tiered Discount": "0x70411D31D73DaC5A4f59A241f703C9b6536FB7B6"
      },
      addresses: {
        SliceCore: "0xAE38a794E839D045460839ABe288a8e5C28B0fc6",
        ProductsModule: "0x0FD0d9aa44a05Ee158DDf6F01d7dcF503388781d",
        FundsModule: "0x5Cef0380cE0aD3DAEefef8bDb85dBDeD7965adf9",
        SlicerManager: "0x61bCd1ED11fC03C958A847A6687b1875f5eAcaaf",
        ChainlinkFeed: "0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e",
        PriceFeed: "0x71c96edD5D36935d5c8d6B78bCcD4113725297e3",
        DeployFactory: "",
        DeployCloneFactory: "",
        JBDirectory: "0x3eb8d0Dc5dFe594619264eAe44e98e913F3F4D20",
        JBFundingCycles: "0x49aF5Bb531e9de95C4aC41ADC570A84b2276ffc5",
        ETHPriceFeed: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
        USDCPriceFeed: "0x07865c6E87B9F70255377e024ace6630C1Eaa37F",
        BaseBridge: ""
      }
    },
    testnet: {
      constants: {
        JBProjectId: "1",
        slicerIdCafe: ""
      },
      strategies: {
        "Linear VRGDA": "0x3b155175B16AE142023827e03C61EF45f68D01b6",
        "Logistic VRGDA": "0xf50D1e551806b1A84cF5fd0C47BEF9f878555B82",
        "NFT Tiered Discount": "0xfa4e199116753d095F9b521E3dC9251F0e00b97c"
      },
      addresses: {
        SliceCore: "0x115978100953D0Aa6f2f8865d11Dc5888f728370",
        ProductsModule: "0xcA6b9D59849EC880e82210e9cb8237E1d0cAA69e",
        FundsModule: "0x8bf3585b3740a2Da2190f911eA8Bc84Dd7972dD6",
        SlicerManager: "0xbd778C62531C11e88Fd6bcE7a1F0a5b955818498",
        ChainlinkFeed: "0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e",
        PriceFeed: "0x71c96edD5D36935d5c8d6B78bCcD4113725297e3",
        DeployFactory: "",
        DeployCloneFactory: "",
        JBDirectory: "0x3eb8d0Dc5dFe594619264eAe44e98e913F3F4D20",
        JBFundingCycles: "0x49aF5Bb531e9de95C4aC41ADC570A84b2276ffc5",
        ETHPriceFeed: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
        USDCPriceFeed: "0x07865c6E87B9F70255377e024ace6630C1Eaa37F",
        BaseBridge: ""
      }
    }
  },
  "10": {
    production: {
      constants: {
        JBProjectId: "",
        slicerIdCafe: "1"
      },
      strategies: {
        "Linear VRGDA": "0xE4f7Bc71421f144BdcFCa992cD20C1D4F9FfA18C",
        "Logistic VRGDA": "0x97556Bd01BE12845d9f24d29E81cD2ee22dD4f0e",
        "NFT Tiered Discount": ""
      },
      addresses: {
        SliceCore: "0xb9d5B99d5D0fA04dD7eb2b0CD7753317C2ea1a84",
        ProductsModule: "0x61bCd1ED11fC03C958A847A6687b1875f5eAcaaf",
        FundsModule: "0x115978100953D0Aa6f2f8865d11Dc5888f728370",
        SlicerManager: "0x8bf3585b3740a2Da2190f911eA8Bc84Dd7972dD6",
        ChainlinkFeed: "0x13e3Ee699D1909E989722E753853AE30b17e08c5",
        PriceFeed: "0x71c96edD5D36935d5c8d6B78bCcD4113725297e3",
        DeployFactory: "0x3497b05B7466431FfA3DE7bF3cC037c083062f18",
        DeployCloneFactory: "0x76dad5968ab456b5a36698a252b30488a1a4f767",
        JBDirectory: "",
        JBFundingCycles: "0xf507B2A1dD7439201eb07F11E1d62AfB29216e2E",
        ETHPriceFeed: "0x4200000000000000000000000000000000000006",
        USDCPriceFeed: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        BaseBridge: ""
      }
    }
  },
  "8453": {
    production: {
      constants: {
        JBProjectId: "",
        slicerIdCafe: "1"
      },
      strategies: {
        "Linear VRGDA": "0xc49976EaA3c18f10793dfB268BC376D4a926843b",
        "Logistic VRGDA": "0xd2258C1450A2323f5a94e1f3F51E2c3f7A5162E8",
        "NFT Tiered Discount": "0xcc612fb8391247AbCCa8a43dc099c82691fC883b",
        "Keys Discount": "0x48f712623Ce3E46D2868bE34EB5f2F7659A2c151"
      },
      addresses: {
        SliceCore: "0x5Cef0380cE0aD3DAEefef8bDb85dBDeD7965adf9",
        ProductsModule: "0xb9d5B99d5D0fA04dD7eb2b0CD7753317C2ea1a84",
        FundsModule: "0x61bCd1ED11fC03C958A847A6687b1875f5eAcaaf",
        SlicerManager: "0xcA6b9D59849EC880e82210e9cb8237E1d0cAA69e",
        ChainlinkFeed: "",
        PriceFeed: "0x2b3eEe365CE2c8A21096a348fc9005233229b58F",
        DeployFactory: "0x3497b05B7466431FfA3DE7bF3cC037c083062f18",
        DeployCloneFactory: "0x76dad5968ab456b5a36698a252b30488a1a4f767",
        JBDirectory: "",
        JBFundingCycles: "0xf507B2A1dD7439201eb07F11E1d62AfB29216e2E",
        ETHPriceFeed: "0x4200000000000000000000000000000000000006",
        USDCPriceFeed: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
        BaseBridge: "0x49048044D57e1C92A77f79988d21Fa8fAF74E97e"
      }
    }
  },
  "84531": {
    testnet: {
      constants: {
        JBProjectId: "",
        slicerIdCafe: "1"
      },
      strategies: {
        "Linear VRGDA": "0xA42e189a2886Aa6686952b57b01f6b2a741b0C1c",
        "Logistic VRGDA": "0xE4f7Bc71421f144BdcFCa992cD20C1D4F9FfA18C",
        "NFT Tiered Discount": "0x3497b05B7466431FfA3DE7bF3cC037c083062f18"
      },
      addresses: {
        SliceCore: "0xAE38a794E839D045460839ABe288a8e5C28B0fc6",
        ProductsModule: "0x0FD0d9aa44a05Ee158DDf6F01d7dcF503388781d",
        FundsModule: "0x5Cef0380cE0aD3DAEefef8bDb85dBDeD7965adf9",
        SlicerManager: "0x61bCd1ED11fC03C958A847A6687b1875f5eAcaaf",
        ChainlinkFeed: "",
        PriceFeed: "0x98cAdA6cD737296bf526A7ba197EFF9798457993",
        DeployFactory: "0x3497b05B7466431FfA3DE7bF3cC037c083062f18",
        DeployCloneFactory: "0x76dad5968ab456b5a36698a252b30488a1a4f767",
        JBDirectory: "",
        JBFundingCycles: "0xf507B2A1dD7439201eb07F11E1d62AfB29216e2E",
        ETHPriceFeed: "0x4200000000000000000000000000000000000006",
        USDCPriceFeed: "0x7F78Dae70049268ff6819B9894a9F1a2a83f1aE4",
        BaseBridge: "0xe93c8cD0D409341205A592f8c4Ac1A5fe5585cfA"
      }
    }
  }
}
