import { track } from "@vercel/analytics"
import { BytesLike, ethers } from "ethers"

export type RedeemTexts = {
  thanks?: string
  instructions?: string
}

export type ProductCart = {
  slicerId: number
  slicerAddress: string
  productId: number
  quantity: number
  price: string
  currency: string
  isUSD: boolean
  extCallValue: string
  extRelativePrice: boolean
  buyerCustomData: BytesLike
  name: string
  externalAddress: string
  dbId: number
  image: string
  uid: string
  creator: string
  texts: RedeemTexts
  shortcodes: string[]
  redeemButton: boolean
  path?: string
  customMessage?: string
}

const handleUpdateCart = async (
  cart: ProductCart[],
  updateCart: (cart: ProductCart[]) => void,
  productCart: ProductCart,
  slicerId: number,
  slicerAddress: string,
  productId: number,
  price: string,
  currency: string,
  isUSD: boolean,
  extCallValue: string,
  buyerCustomData: BytesLike,
  name: string,
  quantity: number,
  path: string,
  dbId: number,
  image: string,
  uid: string,
  creator: string,
  texts: RedeemTexts,
  shortcodes: string[],
  redeemButton: boolean,
  extRelativePrice: boolean,
  externalAddress?: string,
  customMessage?: string
) => {
  const newCart: ProductCart[] = [...cart]
  const formattedAddress =
    externalAddress &&
    externalAddress != "0x00000000" &&
    externalAddress != ethers.constants.AddressZero
      ? externalAddress
      : ethers.constants.AddressZero

  // track("update_cart")

  if (newCart.length != 0 && productCart) {
    const index = newCart.findIndex(
      (p: ProductCart) =>
        p.productId == productId && p.slicerAddress == slicerAddress
    )
    if (quantity != 0) {
      newCart[index] = {
        slicerId,
        slicerAddress,
        productId,
        quantity,
        price: price ? String(Number(price) * quantity) : "0",
        currency,
        isUSD,
        extCallValue: String(Number(extCallValue) * quantity),
        extRelativePrice,
        buyerCustomData,
        name,
        path,
        externalAddress: formattedAddress,
        customMessage,
        dbId,
        image,
        uid,
        creator,
        texts,
        shortcodes,
        redeemButton
      }
    } else {
      newCart.splice(index, 1)
    }
  } else if (quantity != 0) {
    newCart.push({
      slicerId,
      slicerAddress,
      productId,
      quantity,
      price: price ? String(Number(price) * quantity) : "0",
      currency,
      isUSD,
      extCallValue: String(Number(extCallValue) * quantity),
      extRelativePrice,
      buyerCustomData,
      name,
      path,
      externalAddress: formattedAddress,
      customMessage,
      dbId,
      image,
      uid,
      creator,
      texts,
      shortcodes,
      redeemButton
    })
  }
  updateCart(newCart)
}

export default handleUpdateCart
