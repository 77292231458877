import { TagElement } from "@lib/content/tagsList"
import { Dispatch, SetStateAction } from "react"

type Props = {
  label: string
  isActive: boolean
  colors: TagElement["colors"]
  filterTags: string[]
  setFilterTags: Dispatch<SetStateAction<string[]>>
}

function FiltersElement({
  label,
  isActive,
  colors,
  filterTags,
  setFilterTags
}: Props) {
  const { text, bg, border, hover } = colors

  const newTags = isActive
    ? filterTags
        .slice(0, filterTags.indexOf(label))
        .concat(filterTags.slice(filterTags.indexOf(label) + 1))
    : filterTags.concat([label])

  return (
    <button
      className={`text-sm px-2 py-[2px] cursor-pointer border-[1.5px] rounded-full transition-all shadow-sm duration-150 ${border} ${
        isActive
          ? `${bg} ${text}`
          : `shadow-none text-gray-700 dark:!text-gray-200 ${hover}`
      }`}
      onClick={() => setFilterTags(newTags)}
    >
      {label || "Other"}
    </button>
  )
}

export default FiltersElement
