import Wallet from "@components/icons/Wallet"
import Logo from "@components/icons/Logo"
import ShoppingBag from "@components/icons/ShoppingBag"
import { Dispatch, SetStateAction } from "react"
import { DropdownMenuElement } from ".."
import { useAppContext } from "../context"
import Store from "@components/icons/Store"
type Props = {
  setShowDropdown: Dispatch<SetStateAction<boolean>>
  ownedSlicers?: any[]
}

function DropdownMenu({ setShowDropdown, ownedSlicers }: Props) {
  const { account, setModalView } = useAppContext()

  const slicerId = Number(ownedSlicers?.[0]?.slicer?.id)

  return (
    <div
      className={`z-20 absolute top-0 right-[1.5rem] w-48 p-2 mt-20 border border-opacity-80 border-gray-200 space-y-0.5 bg-white rounded-lg shadow-base transition-opacity duration-200 nightwind-prevent-block`}
    >
      <DropdownMenuElement
        href="/profile"
        image={<Logo className="w-[18px] h-[18px]" />}
        label="Your Stores"
        onClick={() => setShowDropdown(false)}
      />
      <DropdownMenuElement
        href="/purchases"
        image={<ShoppingBag strokeWidth="1.5" className="w-[18px] h-[18px]" />}
        label="Your Purchases"
        onClick={() => setShowDropdown(false)}
      />
      <DropdownMenuElement
        image={<Wallet strokeWidth="1.5" className="w-[18px] h-[18px]" />}
        label="Top up wallet"
        onClick={() =>
          setModalView({
            name: "TOPUP_VIEW",
            params: {
              accountTopUpAddress: account
            },
            cross: true
          })
        }
      />
      {ownedSlicers?.length > 0 && (
        <DropdownMenuElement
          href={`${process.env.NEXT_PUBLIC_DASHBOARD_APP_URL}/store/${slicerId}/products`}
          image={<Store className="w-[18px] h-[18px]" />}
          label="Store manager"
          onClick={() => setShowDropdown(false)}
        />
      )}
      {/* <DropdownMenuElement
        href="/earnings"
        image={<Banknotes className="w-4 h-4" />}
        label="Earnings"
        onClick={() => setShowDropdown(false)}
      /> */}
      {/* <DropdownMenuElement
        href={`https://${getSliceSubdomain()}redeem.slice.so/`}
        image={
          <div className="w-5">
            <Package />
          </div>
        }
        label="Redeem"
        onClick={() => setShowDropdown(false)}
      />
      <DropdownMenuElement
        href={`https://${getSliceSubdomain()}redeem.slice.so/forms`}
        image={
          <div className="w-5">
            <FileText />
          </div>
        }
        label="Forms"
        onClick={() => setShowDropdown(false)}
      /> */}
      {/* <div className="xs:hidden">
        <DropdownMenuElement
          image={<Nightwind size="h-5" onClick={null} />}
          label="Toggle dark mode"
          onClick={() => toggle()}
        />
      </div> */}
    </div>
  )
}

export default DropdownMenu
