import React from "react"
import ShoppingBag from "@components/icons/ShoppingBag"
import { RedeemTexts } from "@lib/handleUpdateCart"
import { getSliceSubdomain } from "@utils/getSliceSubdomain"
import Link from "next/link"
import Image from "next/image"
import productDefault from "public/product_default.png"
import Package from "@components/icons/Package"
import ViewPurchaseButton from "../ViewPurchaseButton"

type Props = {
  slicerId: number
  productId: number
  productName: string
  quantity: number
  dbId: number
  image: string
  uid: string
  creator: string
  texts: RedeemTexts
  shortcodes?: string[]
  redeemButton: boolean | undefined
}

const PurchaseListCard = ({
  slicerId,
  productId,
  dbId,
  productName,
  quantity,
  image,
  uid,
  creator,
  texts,
  shortcodes,
  redeemButton
}: Props) => {
  return (
    <div className="flex gap-3 sm:gap-4">
      <div className="flex items-center flex-shrink-0 relative">
        <div>
          <span
            title="Purchases"
            className="absolute text-sm z-10 flex items-center nightwind-prevent bg-white bg-opacity-75 backdrop-blur-sm shadow-md h-[32px] cursor-pointer top-0 left-0 rounded-br-xl rounded-tl-md px-[18px] text-indigo-600"
          >
            <p className="mr-[5px]">{quantity}</p>
            <ShoppingBag className="mb-[1px] w-[15px] h-[15px]" />
          </span>
          <div className="w-24 h-20 sm:w-32 sm:h-24">
            <Image
              src={image || productDefault}
              alt={`${productName} product image`}
              className="object-cover img-background rounded-md"
              fill={true}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between mb-2">
        <p className="text-left font-medium truncate pb-1">{productName}</p>
        <div className="flex gap-2 sm:gap-4 flex-wrap">
          <ViewPurchaseButton
            slicerId={slicerId}
            productId={productId}
            dbId={dbId}
            productName={productName}
            image={image}
            uid={uid}
            creator={creator}
            texts={texts}
            shortcodes={shortcodes}
            redeemButton={redeemButton}
          />
          {redeemButton && (
            <Link
              className="relative cursor-pointer flex gap-2 items-center justify-center h-9 px-3 sm:px-5 text-white transition-colors duration-100 bg-green-600 text-sm font-medium rounded-md nightwind-prevent hover:bg-green-700 hover:text-white"
              href={`https://${getSliceSubdomain()}redeem.slice.so?slicer=${slicerId}&product=${productId}`}
            >
              <p>Redeem</p>
              <Package className="w-5 h-5" />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default PurchaseListCard
