import Link from "next/link"

type Props = {
  image: JSX.Element
  label: string
  onClick: () => void
  href?: string
}

function DropdownMenu({ href, image, label, onClick }: Props) {
  return (
    <div className="text-sm" onClick={onClick}>
      {href ? (
        <Link href={href}>
          <div className="px-2 py-2.5 dark:text-white items-center rounded-md hover:bg-gray-100 hover:text-black flex">
            <div className="w-8 flex justify-center">{image}</div>
            <p className="ml-2.5 font-normal">{label}</p>
          </div>
        </Link>
      ) : (
        <div className="px-2 py-2.5 dark:text-white items-center rounded-md hover:bg-gray-100 hover:text-black flex cursor-pointer">
          <div className="w-8 flex justify-center">{image}</div>
          <p className="ml-2.5 font-normal">{label}</p>
        </div>
      )}
    </div>
  )
}

export default DropdownMenu
