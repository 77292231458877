import { NewTransaction } from "@rainbow-me/rainbowkit/dist/transactions/transactionStore"
import { Dispatch, SetStateAction } from "react"
import {
  Config,
  waitForTransactionReceipt,
  WriteContractReturnType
} from "@wagmi/core"

export type TxData = {
  transactionHash?: string
}

const executeTransaction = async (
  config: Config,
  promise: () => Promise<WriteContractReturnType>,
  setLoading?: Dispatch<SetStateAction<boolean>> | undefined,
  txDescription?: string,
  addRecentTransaction?: (transaction: NewTransaction) => void,
  settlementLogic?: any,
  setData?: Dispatch<SetStateAction<any>>
) => {
  setLoading?.(true)
  setData?.(null)

  try {
    const hash = await promise()

    addRecentTransaction?.({
      hash,
      description: txDescription || "Transaction executed"
    })

    const waitData = await waitForTransactionReceipt(config, {
      hash
    })

    settlementLogic?.()
    setData?.(waitData)
    setLoading?.(false)

    return waitData
  } catch (err) {
    console.log(err)
  }
  setLoading?.(false)
}

export default executeTransaction
