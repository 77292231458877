import { ContractInterface } from "ethers"
import { Dispatch, SetStateAction } from "react"
import * as strategies from "./index"
import linearVrgdaInterface from "./VRGDA/LinearVRGDAPrices.json"
import logisticVrgdaInterface from "./VRGDA/LogisticVRGDAPrices.json"
import NFTDiscount from "./NFTDiscount/NFTDiscount.json"
import KeysDiscount from "./KeysDiscount/KeysDiscount.json"
import { constants } from "@lib/constants"

export type Strategy = {
  label: string
  description: string | JSX.Element
  Component: (args: any) => JSX.Element
}

export type StrategyProps = {
  priceParams: StrategyParams
  setPriceParams: Dispatch<SetStateAction<StrategyParams>>
  productCurrency: string
  disabled?: boolean
}

export type StrategyParams = {
  address: string
  label?: string
  abi?: ContractInterface
  args?: any[]
  fields?: { [key: string]: any }
}

export const strategiesList = {
  LinearVRGDA: {
    label: "Linear VRGDA",
    abi: linearVrgdaInterface.abi
  },
  LogisticVRGDA: {
    label: "Logistic VRGDA",
    abi: logisticVrgdaInterface.abi
  },
  NFTDiscount: {
    label: "NFT Tiered Discount",
    abi: NFTDiscount.abi
  },
  KeysDiscount: {
    label: "Keys Discount",
    abi: KeysDiscount.abi
  }
}

const isStrategy = (strategy: string) =>
  !!constants[process.env.NEXT_PUBLIC_CHAIN_ID][
    process.env.NEXT_PUBLIC_ENVIRONMENT
  ].strategies[strategiesList[strategy]?.label]

export const strategiesRender: Strategy[] = [
  { label: "Standard", description: null, Component: () => null },
  isStrategy("LinearVRGDA") && isStrategy("LogisticVRGDA") && strategies.VRGDA,
  isStrategy("NFTDiscount") && strategies.NFTDiscount,
  isStrategy("KeysDiscount") && strategies.KeysDiscount,
  strategies.Custom
]
