export function Facebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`feather feather-facebook h-full`}
    >
      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
    </svg>
  )
}

export function Reddit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32"
      width="32"
      viewBox="0 0 20 20"
      className="mt-[-5px]"
    >
      <g>
        <circle fill="transparent" cx="10" cy="10" r="10"></circle>
        <path
          fill="currentColor"
          d="M16.67,10A1.46,1.46,0,0,0,14.2,9a7.12,7.12,0,0,0-3.85-1.23L11,4.65,13.14,5.1a1,1,0,1,0,.13-0.61L10.82,4a0.31,0.31,0,0,0-.37.24L9.71,7.71a7.14,7.14,0,0,0-3.9,1.23A1.46,1.46,0,1,0,4.2,11.33a2.87,2.87,0,0,0,0,.44c0,2.24,2.61,4.06,5.83,4.06s5.83-1.82,5.83-4.06a2.87,2.87,0,0,0,0-.44A1.46,1.46,0,0,0,16.67,10Zm-10,1a1,1,0,1,1,1,1A1,1,0,0,1,6.67,11Zm5.81,2.75a3.84,3.84,0,0,1-2.47.77,3.84,3.84,0,0,1-2.47-.77,0.27,0.27,0,0,1,.38-0.38A3.27,3.27,0,0,0,10,14a3.28,3.28,0,0,0,2.09-.61A0.27,0.27,0,1,1,12.48,13.79Zm-0.18-1.71a1,1,0,1,1,1-1A1,1,0,0,1,12.29,12.08Z"
        ></path>
      </g>
    </svg>
  )
}

export function Blog() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-full"
    >
      <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
    </svg>
  )
}

export function Notion() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="12 0.18999999999999906 487.619 510.941"
      fill="currentColor"
      className="h-full"
    >
      <path
        d="M96.085 91.118c15.81 12.845 21.741 11.865 51.43 9.884l279.888-16.806c5.936 0 1-5.922-.98-6.906L379.94 43.686c-8.907-6.915-20.773-14.834-43.516-12.853L65.408 50.6c-9.884.98-11.858 5.922-7.922 9.883zm16.804 65.228v294.491c0 15.827 7.909 21.748 25.71 20.769l307.597-17.799c17.81-.979 19.794-11.865 19.794-24.722V136.57c0-12.836-4.938-19.758-15.84-18.77l-321.442 18.77c-11.863.997-15.82 6.931-15.82 19.776zm303.659 15.797c1.972 8.903 0 17.798-8.92 18.799l-14.82 2.953v217.412c-12.868 6.916-24.734 10.87-34.622 10.87-15.831 0-19.796-4.945-31.654-19.76l-96.944-152.19v147.248l30.677 6.922s0 17.78-24.75 17.78l-68.23 3.958c-1.982-3.958 0-13.832 6.921-15.81l17.805-4.935V210.7l-24.721-1.981c-1.983-8.903 2.955-21.74 16.812-22.736l73.195-4.934 100.889 154.171V198.836l-25.723-2.952c-1.974-10.884 5.927-18.787 15.819-19.767zM42.653 23.919l281.9-20.76c34.618-2.969 43.525-.98 65.283 14.825l89.986 63.247c14.848 10.876 19.797 13.837 19.797 25.693v346.883c0 21.74-7.92 34.597-35.608 36.564L136.64 510.14c-20.785.991-30.677-1.971-41.562-15.815l-66.267-85.978C16.938 392.52 12 380.68 12 366.828V58.495c0-17.778 7.922-32.608 30.653-34.576z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export function Juicebox() {
  return (
    <svg
      viewBox="0 0 341 430"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full"
    >
      <path
        d="M254.529 115.183C223.362 99.9049 139.205 86.1622 102.767 81.4814C102.767 81.4814 75.8277 86.4047 59.1569 91.5919C41.1341 97.1998 14.9656 109.566 14.9656 109.566C7.40663 112.936 120.017 134.655 167.309 143.267C226.851 128.888 258.017 117.991 254.529 115.183Z"
        fill="var(--white)"
        stroke="currentColor"
        strokeWidth="13.3897"
      />
      <path
        d="M15.2311 110.904C15.224 110.969 15.217 111.033 15.2099 111.098C20.861 113.709 142.281 141.91 168.381 144.053C174.183 144.53 195.167 138.435 256.893 117.085C262.716 112.028 256.893 390.143 252.817 395.2C248.741 400.256 224.866 421.045 168.381 422.73C123.193 424.079 11.7372 395.2 15.2311 390.143C0.340738 302.595 8.99996 167.771 15.2099 111.098C14.9351 110.971 14.934 110.904 15.2311 110.904Z"
        fill="currentColor"
      />
      <path
        d="M15.2311 110.904C9.01972 167.464 0.323768 302.495 15.2311 390.143C11.7372 395.2 123.193 424.079 168.381 422.73C224.866 421.045 248.741 400.256 252.817 395.2C256.893 390.143 262.716 112.028 256.893 117.085C195.167 138.435 174.183 144.53 168.381 144.053C141.012 141.806 8.82561 110.904 15.2311 110.904Z"
        stroke="currentColor"
        strokeWidth="13.3897"
      />
      <path
        d="M54.1255 94.7217L209.185 132.97"
        stroke="currentColor"
        strokeWidth="6.69486"
      />
      <path
        d="M227.44 28.9516L206.56 114.407C196.986 121.075 188.377 116.226 185.269 112.968C191.733 88.2588 205.49 34.7826 208.802 18.5474C212.113 2.31229 230.465 2.48634 239.227 4.60275C239.227 4.60275 329.033 41.3827 332.025 45.1218C335.016 48.861 331.277 60.8262 324.546 60.8262L233.962 21.9631C233.699 25.6029 229.504 28.1387 227.44 28.9516Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="6.69486"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3281 240.36L94.2456 150.49L67.2731 211.473H139.2L40.3006 371.956L82.2578 246.78L13.3281 240.36Z"
        fill="var(--white)"
        stroke="currentColor"
        strokeWidth="4.78666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.541 421.479C159.541 421.479 170.492 78.0264 157.031 164.774C143.57 251.522 165.551 404.029 102.439 412.754C126.482 421.479 145.516 422.448 159.541 421.479Z"
        fill="var(--white)"
      />
      <path
        d="M222.291 201.419L174.232 151.941L214.615 138.54L254.998 125.14L227.631 201.419H222.291Z"
        fill="var(--white)"
        stroke="var(--white)"
        strokeWidth="5.98264"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.624 128.131L225.085 208.897"
        stroke="currentColor"
        strokeWidth="5.98264"
      />
      <ellipse
        cx="329.408"
        cy="53.6212"
        rx="7.15618"
        ry="11.7913"
        transform="rotate(25.9338 329.408 53.6212)"
        fill="currentColor"
      />
    </svg>
  )
}

export function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`feather feather-instagram h-full`}
    >
      <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
      <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
    </svg>
  )
}

export function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`feather feather-linkedin h-full`}
    >
      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
      <rect x="2" y="9" width="4" height="12"></rect>
      <circle cx="4" cy="4" r="2"></circle>
    </svg>
  )
}

export function Twitter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`feather feather-twitter h-full`}
    >
      <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
    </svg>
  )
}

export function Github() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`feather feather-github h-full`}
    >
      <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
    </svg>
  )
}

export function Discord() {
  return (
    <svg
      viewBox="0 0 176 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`h-full`}
    >
      <path
        d="M106.8 84.1001C101.1 84.1001 96.6001 89.0001 96.6001 95.1001C96.6001 101.2 101.2 106.1 106.8 106.1C112.5 106.1 117 101.2 117 95.1001C117 89.0001 112.4 84.1001 106.8 84.1001ZM70.3001 84.1001C64.6001 84.1001 60.1001 89.0001 60.1001 95.1001C60.1001 101.2 64.7001 106.1 70.3001 106.1C76.0001 106.1 80.5001 101.2 80.5001 95.1001C80.6001 89.0001 76.0001 84.1001 70.3001 84.1001Z"
        fill="currentColor"
      />
      <path
        d="M155.4 0.899902H21.3999C10.0999 0.899902 0.899902 10.0999 0.899902 21.3999V155.4C0.899902 166.7 10.0999 175.9 21.3999 175.9H134.8L129.5 157.6L142.3 169.4L154.4 180.5L176 199.2V21.3999C175.9 10.0999 166.7 0.899902 155.4 0.899902ZM116.8 130.4C116.8 130.4 113.2 126.1 110.2 122.4C123.3 118.7 128.3 110.6 128.3 110.6C124.2 113.3 120.3 115.2 116.8 116.5C111.8 118.6 107 119.9 102.3 120.8C92.6999 122.6 83.8999 122.1 76.3999 120.7C70.6999 119.6 65.7999 118.1 61.6999 116.4C59.3999 115.5 56.8999 114.4 54.3999 113C54.0999 112.8 53.7999 112.7 53.4999 112.5C53.2999 112.4 53.1999 112.3 53.0999 112.3C51.2999 111.3 50.2999 110.6 50.2999 110.6C50.2999 110.6 55.0999 118.5 67.7999 122.3C64.7999 126.1 61.0999 130.5 61.0999 130.5C38.9999 129.8 30.5999 115.4 30.5999 115.4C30.5999 83.4999 44.9999 57.5999 44.9999 57.5999C59.3999 46.8999 72.9999 47.1999 72.9999 47.1999L73.9999 48.3999C55.9999 53.4999 47.7999 61.3999 47.7999 61.3999C47.7999 61.3999 49.9999 60.1999 53.6999 58.5999C64.3999 53.8999 72.8999 52.6999 76.3999 52.2999C76.9999 52.1999 77.4999 52.0999 78.0999 52.0999C84.1999 51.2999 91.0999 51.0999 98.2999 51.8999C107.8 52.9999 118 55.7999 128.4 61.3999C128.4 61.3999 120.5 53.8999 103.5 48.7999L104.9 47.1999C104.9 47.1999 118.6 46.8999 132.9 57.5999C132.9 57.5999 147.3 83.4999 147.3 115.4C147.3 115.3 138.9 129.7 116.8 130.4Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function Mail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`feather feather-mail h-full`}
    >
      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
      <polyline points="22,6 12,13 2,6"></polyline>
    </svg>
  )
}
