import { constants } from "@lib/constants"
import { Config, getBalance } from "@wagmi/core"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { zeroAddress } from "viem"
import { useConfig } from "wagmi"

type AccountBalance = {
  ethBalance: bigint
  usdcBalance: bigint
  ethSenderBalance: bigint
  usdcSenderBalance: bigint
  // ethSenderBridge: bigint
}

export const getAccountBalance = async ({
  config,
  account,
  sender,
  setAccountBalance,
  // bridgeChainId,
  chainId
}: {
  config: Config
  account: `0x${string}`
  sender: `0x${string}`
  setAccountBalance: Dispatch<SetStateAction<AccountBalance>>
  // bridgeChainId: number
  chainId: number
}) => {
  const currentAccountBalance = await _getAccountBalance({
    config,
    account,
    sender,
    // bridgeChainId,
    chainId
  })

  setAccountBalance(currentAccountBalance!)
}

export const useAccountBalance = ({
  account,
  sender,
  initAccountBalance,
  bridgeChainId,
  chainId
}: {
  account: `0x${string}`
  sender?: `0x${string}`
  initAccountBalance?: AccountBalance
  bridgeChainId?: number
  chainId?: number
}) => {
  const [accountBalance, setAccountBalance] = useState<AccountBalance>(
    initAccountBalance!
  )
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const config = useConfig()

  const clearAndSetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    intervalRef.current = setInterval(() => {
      getAccountBalance({
        config,
        account,
        sender: sender || zeroAddress,
        setAccountBalance,
        // bridgeChainId: bridgeChainId!,
        chainId: chainId!
      })
    }, 15000) as NodeJS.Timeout
  }

  const mutate = () => {
    getAccountBalance({
      config,
      account,
      sender: sender || zeroAddress,
      setAccountBalance,
      // bridgeChainId: bridgeChainId!,
      chainId: chainId!
    })
    clearAndSetInterval()
  }

  useEffect(() => {
    if (
      initAccountBalance &&
      Object.values(initAccountBalance).some((v) => v === undefined)
    ) {
      getAccountBalance({
        config,
        account,
        sender: sender || zeroAddress,
        setAccountBalance,
        // bridgeChainId: bridgeChainId!,
        chainId: chainId!
      })
    }

    clearAndSetInterval()

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [account, sender])

  return { data: accountBalance, mutate }
}

export const _getAccountBalance = async ({
  config,
  account,
  sender,
  // bridgeChainId,
  chainId
}: {
  config: Config
  account: `0x${string}`
  sender?: `0x${string}`
  // bridgeChainId: number
  chainId: number
}) => {
  if (account) {
    const [
      ethBalance,
      usdcBalance,
      ethSenderBalance,
      usdcSenderBalance
      // ethSenderBalanceBridge
    ] = await Promise.all([
      getBalance(config, {
        address: account,
        chainId
      }),
      getBalance(config, {
        address: account,
        token:
          constants[chainId][process.env.NEXT_PUBLIC_ENVIRONMENT].addresses[
            "USDCPriceFeed"
          ],
        chainId
      }),
      sender &&
        sender != account &&
        getBalance(config, {
          address: sender,
          chainId
        }),
      sender &&
        sender != account &&
        getBalance(config, {
          address: sender,
          token:
            constants[chainId][process.env.NEXT_PUBLIC_ENVIRONMENT].addresses[
              "USDCPriceFeed"
            ],
          chainId
        })
      // sender &&
      //   getBalance(config, {
      //     address: sender,
      //     chainId: bridgeChainId
      //   })
    ])

    return {
      ethBalance: ethBalance?.value,
      usdcBalance: usdcBalance?.value,
      // @ts-ignore
      ethSenderBalance: ethSenderBalance?.value || BigInt(0),
      // @ts-ignore
      usdcSenderBalance: usdcSenderBalance?.value || BigInt(0)
      // ethSenderBridge: ethSenderBalanceBridge?.value
    }
  }
}
