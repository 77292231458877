import { useEffect, useState } from "react"
import { CBPayInstanceType, initOnRamp } from "@coinbase/cbpay-js"
import { BrandedButton } from ".."
import CoinbaseLogo from "@components/icons/CoinbaseLogo"
import { useAccount } from "wagmi"

type Props = {
  ethTopUpAmount?: number
  usdcTopUpAmount?: number
  mutate?: () => void
}

const PayWithCoinbaseButton = ({
  ethTopUpAmount,
  usdcTopUpAmount,
  mutate
}: Props) => {
  const [onrampInstance, setOnrampInstance] = useState<
    CBPayInstanceType | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)
  const { address: account } = useAccount()

  useEffect(() => {
    initOnRamp(
      {
        appId: "5ccef000-d63c-40b4-a0ae-54ecd0c6a622",
        widgetParameters: {
          presetCryptoAmount: usdcTopUpAmount,
          // presetFiatAmount: usdcTopUpAmount,
          destinationWallets: [
            {
              address: account,
              assets: ["USDC"],
              blockchains: ["base"]
            }
          ],
          defaultAsset: "USDC",
          defaultNetwork: "base",
          defaultExperience: "buy"
        },
        onSuccess: () => {
          mutate()
        },
        onExit: () => {
          console.log("exit")
        },
        onEvent: (event) => {
          console.log("event", event)
        },
        experienceLoggedIn: "popup",
        experienceLoggedOut: "popup",
        closeOnExit: true,
        closeOnSuccess: true
      },
      (_, instance) => {
        setOnrampInstance(instance)
      }
    )

    return () => {
      onrampInstance?.destroy()
    }
  }, [])

  const handleClick = () => {
    if (onrampInstance) {
      onrampInstance.open()
    } else {
      setIsLoading(true)
    }
  }

  useEffect(() => {
    if (isLoading && onrampInstance) {
      onrampInstance?.open()
      setIsLoading(false)
    }
  }, [isLoading, onrampInstance])

  return (
    <BrandedButton
      text="Buy with card on Coinbase"
      bgColor="bg-blue-700 text-blue-700"
      logo={<CoinbaseLogo />}
      onClick={handleClick}
      loading={isLoading}
    />
  )
}

export default PayWithCoinbaseButton
