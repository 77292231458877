import { Input } from "@components/ui"
import Delete from "@components/icons/Delete"
import { isAddress } from "viem"
import { DiscountParams } from "./NFTDiscount"
import { Dispatch, SetStateAction } from "react"
import { useNftType } from "@utils/useNftType"

type Props = {
  index: number
  discountParams: DiscountParams
  setDiscountParams: Dispatch<SetStateAction<DiscountParams>>
}

const DiscountList = ({ index, discountParams, setDiscountParams }: Props) => {
  const nftType = useNftType(
    index,
    discountParams.discounts[index].nft as `0x${string}`,
    setDiscountParams
  )

  const handleSetNft = (index: number, value: string) => {
    let items = [...discountParams.discounts]
    items[index].nft = value
    setDiscountParams((prev) => ({
      ...prev,
      discounts: items
    }))
  }

  const handleSetDiscount = (index: number, value: number) => {
    let items = [...discountParams.discounts]
    items[index].discount = value
    setDiscountParams((prev) => ({
      ...prev,
      discounts: items
    }))
  }

  const handleSetMinQuantity = (index: number, value: number) => {
    let items = [...discountParams.discounts]
    items[index].minQuantity = value
    setDiscountParams((prev) => ({
      ...prev,
      discounts: items
    }))
  }

  const handleSetTokenId = (index: number, value: number) => {
    let items = [...discountParams.discounts]
    items[index].tokenId = value
    setDiscountParams((prev) => ({
      ...prev,
      discounts: items
    }))
  }

  const handleRemove = (index: number) => {
    let items = [...discountParams.discounts]
    items.splice(index, 1)
    setDiscountParams((prev) => ({
      ...prev,
      discounts: items
    }))
  }

  return (
    <li className="flex items-center gap-4">
      <div className="pt-4">
        <Delete
          disabled={discountParams.discounts?.length === 1}
          onClick={() => handleRemove(index)}
        />
      </div>

      <div className="sm:flex gap-4 flex-grow space-y-8 sm:space-y-0">
        <div className="relative flex-grow">
          <Input
            label="NFT address"
            value={discountParams.discounts[index].nft}
            handleOnChangeCustom={(e: any) =>
              handleSetNft(index, e.target.value)
            }
            placeholder="0x…"
            error={
              discountParams.discounts[index].nft &&
              !isAddress(discountParams.discounts[index].nft)
            }
            required
          />
          <p
            className={
              "text-blue-600 dark:text-sky-300 absolute text-xs opacity-80 font-black left-0 bottom-[-23px] sm:bottom-[-12px]"
            }
          >
            {nftType}
          </p>
        </div>
        <div className="flex gap-4">
          {nftType == "ERC1155" && (
            <div className="flex-1 sm:w-20">
              <Input
                type="number"
                label={"Token Id"}
                placeholder={"1"}
                value={discountParams.discounts[index].tokenId || ""}
                handleOnChangeCustom={(e: any) =>
                  handleSetTokenId(index, e.target.value)
                }
                required
              />
            </div>
          )}
          <div className="flex-1 sm:w-20">
            <Input
              type="number"
              label={"Discount %"}
              placeholder={"10"}
              min={0}
              max={100}
              step={0.01}
              value={discountParams.discounts[index].discount || ""}
              handleOnChangeCustom={(e: any) =>
                handleSetDiscount(index, e.target.value)
              }
              required
            />
          </div>
          <div className="flex-1 sm:w-20">
            <Input
              type="number"
              label={"Min owned"}
              placeholder={"1"}
              min={1}
              value={discountParams.discounts[index].minQuantity || ""}
              handleOnChangeCustom={(e: any) =>
                handleSetMinQuantity(index, e.target.value)
              }
              required
            />
          </div>
        </div>
      </div>
    </li>
  )
}

export default DiscountList
