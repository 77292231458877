import dynamic from "next/dynamic"
import { Footer, Navbar } from "@components/ui"
import { useAppContext } from "@components/ui/context"
import { useEffect, useState } from "react"
import { useAccount, useChainId } from "wagmi"

const FloatingCart = dynamic(() => import("@components/ui/FloatingCart"), {
  ssr: false
})
const Modal = dynamic(() => import("@components/ui/Modal"), {
  ssr: false
})

export default function Layout({ children }) {
  const {
    account,
    cart,
    modalView,
    setModalView,
    switchNetwork,
    isModalTrigger
  } = useAppContext()
  const [showCart, setShowCart] = useState(false)
  const { chain } = useAccount()

  const [isActive, setIsActive] = useState(true)

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }

  useEffect(() => {
    if (modalView?.name != "TOPUP_VIEW") {
      const switchChain = () => {
        try {
          switchNetwork?.(Number(process.env.NEXT_PUBLIC_CHAIN_ID))
        } catch (error) {
          console.log(error)
        }

        if (Number(chain?.id) !== Number(process.env.NEXT_PUBLIC_CHAIN_ID)) {
          setModalView({
            name: ""
          })

          setTimeout(() => {
            setModalView({
              cross: false,
              name: "NETWORK_VIEW",
              params: { userChainId: chain.id }
            })
          }, 0)
        }
      }

      // Attach the event listener for visibility change
      document.addEventListener("visibilitychange", handleVisibilityChange)

      if (
        account &&
        chain &&
        Number(chain.id) !== Number(process.env.NEXT_PUBLIC_CHAIN_ID) &&
        isActive
      ) {
        switchChain()
      } else {
        if (modalView.name == "NETWORK_VIEW") {
          setModalView({ name: "" })
        }
      }

      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange)
      }
    }
  }, [account, chain, switchNetwork, isActive, isModalTrigger])

  useEffect(() => {
    setShowCart(!!(cart && cart?.length != 0))
  }, [cart])

  return (
    <>
      <div className="relative">
        <div className="flex flex-col justify-between min-h-screen">
          <Navbar />
          {children}
          <Footer />
        </div>
        {modalView.name && (
          <Modal modalView={modalView} setModalView={setModalView} />
        )}
        {showCart && <FloatingCart />}
      </div>
    </>
  )
}
