// import { Button, DoubleText, Input } from "@components/ui"
// import { useState } from "react"
// import {
//   useContractWrite,
//   usePrepareContractWrite,
//   useWaitForTransaction
// } from "wagmi"
// import SlicerContract from "artifacts/contracts/Slicer.sol/Slicer.json"

// export const FEE_CHANGE_VIEW = (params: any) => {
//   const { slicerId, slicerAddress, protocolFee } = params

//   const [newProtocolFee, setNewProtocolFee] = useState(protocolFee)

//   const { config } = usePrepareContractWrite({
//     address: slicerAddress as `0x${string}`,
//     abi: SlicerContract.abi,
//     functionName: "_setCustomFee",
//     args: [true, newProtocolFee]
//   })
//   const { data, isLoading, write } = useWriteContract()

//   const { isSuccess, isLoading: isLoadingTransaction } = useWaitForTransaction({
//     hash: data?.hash
//   })

//   return (
//     <div className="space-y-4">
//       <div className="pt-4 pb-8 text-center">
//         <DoubleText
//           inactive
//           logoText="Change protocol fee"
//           size="text-3xl sm:text-4xl"
//         />
//         <p className="max-w-sm pt-6 mx-auto text-gray-500">
//           Slicer #{slicerId}
//         </p>
//       </div>
//       <div className="prose">
//         <p>
//           Slicer owners receive an amount of SLC tokens when they withdraw ETH
//           or USDC, based on the protocol fee set.
//         </p>
//         {/* <p>
//           Besides influencing the SLC tokens minted, a higher fee will result in a better
//           positioning in our explore page and promotion of your slicer by Slice.
//         </p> */}
//         <p>
//           As a superowner, you can set the fee for the slicer. Note that this
//           affects all other owners as well.
//         </p>
//       </div>
//       {!isSuccess ? (
//         <>
//           <div className="py-4">
//             <Input
//               type="range"
//               label="Protocol fee"
//               min={0}
//               max={100}
//               value={newProtocolFee}
//               onChange={setNewProtocolFee}
//             />
//           </div>
//           <div className="text-center">
//             <Button
//               label={`Set fee to ${(Math.round(newProtocolFee) / 10).toFixed(
//                 1
//               )}%`}
//               className="h-[38px] font-bold tracking-wide rounded-md overflow-hidden px-5 min-w-[180px] focus:outline-none"
//               onClick={async () => write()}
//               loading={isLoading || isLoadingTransaction}
//             />
//           </div>
//         </>
//       ) : (
//         <div className="pt-4 text-center ">
//           <p className="font-semibold text-green-600">
//             Custom fee set to {Math.round(newProtocolFee) / 10}%!
//           </p>
//           <p className="pt-3 text-gray-600">
//             Reload the website to see it reflected in your slicers list
//           </p>
//         </div>
//       )}
//     </div>
//   )
// }

export default {}
