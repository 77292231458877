import { DoubleText, OwnerBlock } from "@components/ui"
import { useEffect } from "react"
import { track } from "@vercel/analytics"
import formatNumber from "@utils/formatNumber"

export const OWNERS_VIEW = (params: any) => {
  const {
    slicerId,
    slicerAddress,
    totalSlices,
    owners,
    unreleased,
    setUnreleased
  } = params

  // useEffect(() => {
  // track("owners_open_modal")
  // }, [])

  return (
    <>
      <div className="pb-6 text-center">
        <DoubleText inactive logoText="Slicer owners" />
        <p className="pt-4">{formatNumber(totalSlices)} Total slices</p>
      </div>
      <ul className="max-w-sm pt-6 mx-auto">
        {owners.map((el, key) => (
          <li key={key}>
            <OwnerBlock
              index={Number(key)}
              slicerAddress={slicerAddress}
              slicerId={slicerId}
              totalSlices={totalSlices}
              owner={owners[Number(key)]}
              unreleasedOwner={unreleased[Number(key)].result}
              unreleased={unreleased}
              setUnreleased={setUnreleased}
            />
            {key != owners.length - 1 && (
              <hr className="my-8 border-gray-300" />
            )}
          </li>
        ))}
      </ul>
    </>
  )
}

export default OWNERS_VIEW
