import { Button } from ".."

type Props = {
  text: string
  bgColor: string
  textColor?: string
  logo?: JSX.Element
  [x: string]: any
}

const BrandedButton = ({
  text,
  bgColor,
  textColor = "text-black hover:text-white dark:hover:text-white",
  logo,
  ...rest
}: Props) => {
  return (
    <Button
      className="h-[38px] group font-semibold tracking-wide m-[2px] rounded-[5px] overflow-hidden px-5 min-w-[320px] focus:outline-none"
      color={`${textColor} bg-white hover:bg-transparent duration-150 text-shadow`}
      bgColor={`${bgColor} nightwind-prevent shadow-light-current`}
      label={
        <span className="flex items-center gap-x-2">
          <span>{text}</span>
          {logo && <span className="w-6 h-6">{logo}</span>}
        </span>
      }
      {...rest}
    />
  )
}

export default BrandedButton
