import { useEffect, useState, Dispatch, SetStateAction } from "react"
import {
  Input,
  SliceFormAdvancedSettings,
  SliceFormInputBlock,
  Question,
  InputRange
} from "@components/ui"
import Add from "@components/icons/Add"
import { useAppContext } from "@components/ui/context"
import formatNumber from "@utils/formatNumber"

type Props = {
  success: boolean
  addresses: string[]
  shares: number[]
  superownerThreshold: number
  totalShares: number
  isImmutable: boolean
  isCreatorMetadata: boolean
  setAddresses: Dispatch<SetStateAction<string[]>>
  setShares: Dispatch<SetStateAction<number[]>>
  setSuperownerThreshold: Dispatch<SetStateAction<number>>
  setTotalShares: Dispatch<SetStateAction<number>>
  setIsImmutable: Dispatch<SetStateAction<boolean>>
  setIsCreatorMetadata: Dispatch<SetStateAction<boolean>>
}

const SliceFormBlockSplitter = ({
  success,
  addresses,
  shares,
  superownerThreshold,
  totalShares,
  isImmutable,
  isCreatorMetadata,
  setAddresses,
  setShares,
  setSuperownerThreshold,
  setTotalShares,
  setIsImmutable,
  setIsCreatorMetadata
}: Props) => {
  const { account } = useAppContext()
  const [initAddress, setInitAddress] = useState("")
  const [inputCount, setInputCount] = useState(1)
  const [removedCount, setRemovedCount] = useState(0)

  useEffect(() => {
    setInitAddress(account)
  }, [account])

  useEffect(() => {
    if (success) {
      resetInputs()
    }
  }, [success])

  const resetInputs = () => {
    setInputCount(1)
    setRemovedCount(0)
    setAddresses([initAddress])
    setShares([1000000])
    setSuperownerThreshold(51)
    setTotalShares(1000000)
  }

  return (
    <div className="grid items-center grid-cols-8 text-left xs:grid-cols-10 md:grid-cols-12 gap-x-4 gap-y-2 xs:gap-y-3">
      <p className="mb-[-18px] text-sm text-gray-700 font-semibold hidden xs:block xs:col-span-5 xs:col-start-2 md:col-span-7 md:col-start-2">
        Initial owners
      </p>
      <div className="mb-[-18px] text-gray-700 relative items-center hidden xs:flex">
        <p className="pr-1 text-sm font-semibold">Slices</p>
        <Question
          text={
            <>
              <p>
                Slices are ERC1155 tokens which represent ownership over a store
                and its earnings.
              </p>
              <p>
                {totalShares ? (
                  <>
                    With {formatNumber(totalShares)} total slices,{" "}
                    <b>
                      1 Slice ={" "}
                      {Math.floor((1 / totalShares) * 10000000) / 100000}%
                    </b>{" "}
                    of ownership.
                  </>
                ) : (
                  <>
                    If a slicer has 100 slices, <b>1 Slice = 1%</b> of
                    ownership.
                  </>
                )}
              </p>
              <p>
                Slices can be transferred anytime, but you cannot increase the
                total amount once the store is created.
              </p>
            </>
          }
          position="bottom-0 right-[-35px]"
        />
      </div>
      {[...Array(inputCount)].map((el, key) => {
        const i = key
        return (
          <SliceFormInputBlock
            key={key}
            index={i}
            signerAddress={initAddress as `0x${string}`}
            addresses={addresses as `0x${string}`[]}
            shares={shares}
            totalShares={totalShares}
            superownerThreshold={superownerThreshold}
            removedCount={removedCount}
            setAddresses={setAddresses}
            setShares={setShares}
            setTotalShares={setTotalShares}
            setRemovedCount={setRemovedCount}
          />
        )
      })}

      <div className="col-span-7 col-start-1 sm:ml-3 mt-2">
        <div
          className="inline-flex gap-4 text-green-600 opacity-75 cursor-pointer hover:opacity-100"
          onClick={() => setInputCount(inputCount + 1)}
        >
          <Add />

          <p className="inline-block font-semibold">Add owner</p>
        </div>
      </div>

      <div className="col-span-7 xs:col-span-8 xs:col-start-2 mt-10">
        <div className="flex-grow">
          <InputRange
            label="Superowner threshold"
            helpText="Superowners can edit the store and add products on sale."
            min={1}
            max={100}
            value={superownerThreshold}
            onChange={setSuperownerThreshold}
          />
        </div>
      </div>
      <p className="self-end text-sm font-bold mb-1">{superownerThreshold}%</p>

      {/* 
      <SliceFormAdvancedSettings
        isImmutable={isImmutable}
        isCreatorMetadata={isCreatorMetadata}
        setIsImmutable={setIsImmutable}
        setIsCreatorMetadata={setIsCreatorMetadata}
      /> 
      */}
    </div>
  )
}

export default SliceFormBlockSplitter
