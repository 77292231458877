const CarouselEvents = (color: string) => {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 1034 958"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M97.0544 918.942C77.5158 904.038 69.9174 879.755 68.5605 869.476L380.641 216.939C381.639 213.357 383.047 210.533 384.707 208.438C416.141 187.666 522.97 190.065 698.818 365.828C874.667 541.591 863.902 625.361 836.539 645.276L137.082 940.784C131.88 939.713 116.593 933.846 97.0544 918.942Z"
        fill="currentColor"
        className={color}
      />
      <path
        d="M836.539 645.276L137.082 940.784C131.88 939.713 116.593 933.846 97.0544 918.942C77.5158 904.038 69.9174 879.755 68.5605 869.476L384.707 208.438M836.539 645.276C788.597 651.7 657.434 624.59 516.322 464.759C375.209 304.927 369.782 227.282 384.707 208.438M836.539 645.276C863.902 625.361 874.667 541.591 698.818 365.828C522.97 190.065 416.141 187.666 384.707 208.438"
        stroke="currentColor"
        className={color}
        strokeWidth="33"
      />
      <path
        d="M683.215 488.528C711.935 458.253 818.9 398.689 1017 402.632"
        stroke="currentColor"
        className={color}
        strokeWidth="33"
        strokeLinecap="round"
      />
      <path
        d="M560.503 333.708C528.843 317.862 475.021 283.6 513.013 273.322C560.503 260.474 711.115 239.917 635.809 203.299C560.503 166.682 443.813 135.846 513.013 121.713C582.213 107.58 709.757 114.647 656.161 80.5991C627.846 62.6116 583.569 45.2666 583.569 45.2666"
        stroke="currentColor"
        className={color}
        strokeWidth="33"
        strokeLinecap="round"
      />
      <ellipse
        cx="803.974"
        cy="98.5865"
        rx="56.9878"
        ry="53.3199"
        fill="currentColor"
        className={color}
      />
      <ellipse
        cx="896.24"
        cy="260.473"
        rx="56.9878"
        ry="53.3199"
        fill="currentColor"
        className={color}
      />
      <path
        d="M45.4939 864.979C25.9552 850.075 18.3569 825.792 17 815.513L329.081 162.977C330.078 159.394 331.487 156.571 333.147 154.475C364.58 133.704 471.41 136.102 647.258 311.865C823.106 487.628 812.342 571.398 784.978 591.313L85.521 886.821C80.3198 885.75 65.0326 879.883 45.4939 864.979Z"
        fill="black"
      />
      <path
        d="M784.978 591.313L85.521 886.821C80.3198 885.75 65.0326 879.883 45.4939 864.979C25.9552 850.075 18.3569 825.792 17 815.513L333.147 154.475M784.978 591.313C737.036 597.737 605.874 570.627 464.761 410.796C323.649 250.965 318.221 173.319 333.147 154.475M784.978 591.313C812.342 571.398 823.106 487.628 647.258 311.865C471.41 136.102 364.58 133.704 333.147 154.475"
        stroke="white"
        strokeWidth="33"
      />
      <path
        d="M631.654 460.261C660.374 429.987 767.34 370.422 965.44 374.365"
        stroke="white"
        strokeWidth="33"
        strokeLinecap="round"
      />
      <path
        d="M522.511 305.442C490.851 289.596 437.029 255.334 475.021 245.055C522.511 232.207 673.123 211.65 597.817 175.033C522.511 138.415 405.821 107.58 475.021 93.4467C544.221 79.3137 671.765 86.3802 618.169 52.3325C589.854 34.345 545.577 17 545.577 17"
        stroke="white"
        strokeWidth="33"
        strokeLinecap="round"
      />
      <ellipse
        cx="765.983"
        cy="70.3199"
        rx="56.9878"
        ry="53.3199"
        fill="white"
      />
      <ellipse
        cx="858.249"
        cy="232.207"
        rx="56.9878"
        ry="53.3199"
        fill="white"
      />
    </svg>
  )
}

export default CarouselEvents
