import { Analytics } from "@vercel/analytics/react"
import { Background, Layout } from "@components/ui"
import { AppWrapper } from "@components/ui/context"
import { ThemeProvider } from "next-themes"
import Head from "@components/common/Head"
import { AppProps } from "next/dist/shared/lib/router/router"
import "../styles/global/styles.scss"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import {
  RainbowKitProvider,
  lightTheme,
  connectorsForWallets
} from "@rainbow-me/rainbowkit"
import { createConfig, WagmiProvider } from "wagmi"
import { base, Chain, mainnet } from "wagmi/chains"
import "@rainbow-me/rainbowkit/styles.css"
import { transports } from "@utils/alchemy"
import {
  coinbaseWallet,
  walletConnectWallet as walletConnect,
  metaMaskWallet as metamask,
  injectedWallet,
  rainbowWallet
} from "@rainbow-me/rainbowkit/wallets"
import { coinbaseWalletSW } from "@components/ui/BlackCreateWalletButton/CoinbaseWalletSWConnector"

const projectId = process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID || ""

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [
        coinbaseWalletSW,
        coinbaseWallet,
        walletConnect,
        rainbowWallet,
        metamask,
        injectedWallet
      ]
    }
  ],
  {
    appName: "Slice",
    appIcon: "https://shop.slice.so/sliceLogo.png",
    projectId
  }
)

export const wagmiConfigClient = createConfig({
  chains: [base as Chain, mainnet],
  connectors,
  transports,
  ssr: true
})

function MyApp({ Component, pageProps }: AppProps) {
  const queryClient = new QueryClient()

  return (
    <>
      <Head />
      <ThemeProvider
        attribute="class"
        storageKey="nightwind-mode"
        defaultTheme="system"
      >
        <WagmiProvider config={wagmiConfigClient}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider
              theme={
                // isDark
                //   ? midnightTheme({
                //       accentColor: "#2563eb",
                //       accentColorForeground: "white",
                //       borderRadius: "medium"
                //     })
                //   :
                lightTheme({
                  accentColor: "#2563eb",
                  accentColorForeground: "white",
                  borderRadius: "medium"
                })
              }
              showRecentTransactions={true}
            >
              <AppWrapper>
                <Layout>
                  <Background />
                  <Component {...pageProps} />
                </Layout>
              </AppWrapper>
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </ThemeProvider>
      <Analytics />
    </>
  )
}

export default MyApp
