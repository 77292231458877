import useExternalPrices from "@lib/useExternalPrices"
import useEthUsd from "@utils/useEthUsd"
import { useRouter } from "next/router"
import { BlockchainProduct } from "pages/slicer/[id]"
import { useEffect, useState } from "react"
import { ProductCard } from ".."
import { useAppContext } from "../context"
import { Product } from "../SlicerProducts/SlicerProducts"

export type ExternalPrices = {
  [productId: string]: {
    [currency: string]: {
      ethPrice: string
      currencyPrice: string
    }
  }
}

type Props = {
  slicerId: number
  slicerAddress: string
  products: Product[]
  editMode: boolean
  blockchainProducts: BlockchainProduct[]
  storeClosed: boolean
}

const ProductsGrid = ({
  slicerId,
  slicerAddress,
  products,
  editMode,
  blockchainProducts,
  storeClosed
}: Props) => {
  const { account, updateCart } = useAppContext()
  const router = useRouter()
  const { product: productQuery, cart: cartQuery, resetCart } = router.query
  const increment = 12
  const [visibleItems, setVisibleItems] = useState(increment)
  const [addToCart, setAddToCart] = useState({})

  const ethUsd = useEthUsd()

  const productQueryIndex = products?.findIndex(
    (p) => p.productId == Number(productQuery)
  )

  const externalPrices: ExternalPrices = useExternalPrices(
    account,
    blockchainProducts
  )

  useEffect(() => {
    if (productQueryIndex != -1 && productQueryIndex >= increment) {
      const incrementFactor =
        Math.floor(Number(productQueryIndex) / increment) * increment

      setVisibleItems(visibleItems + incrementFactor)
    }
  }, [productQueryIndex])

  useEffect(() => {
    if (resetCart == "true") {
      updateCart([])
    }
    if (cartQuery) {
      if (typeof cartQuery == "string") {
        const [productId, quantity] = cartQuery.split("-")
        setAddToCart({ [productId]: Number(quantity) })
      } else {
        const newCart = {}
        cartQuery.forEach((item) => {
          const [productId, quantity] = item.split("-")
          newCart[productId] = Number(quantity)
        })
        setAddToCart(newCart)
      }
    }
  }, [cartQuery])

  return blockchainProducts && blockchainProducts.length != 0 ? (
    <>
      <div className="grid items-center justify-center grid-cols-1 gap-8 max-w-[400px] mx-auto sm:max-w-none sm:grid-cols-2">
        {products.slice(0, visibleItems).map((product) => {
          const chainInfo = blockchainProducts?.find(
            (p) => Number(p.id.split("-").pop()) == product?.productId
          )
          const displayProduct =
            products[productQueryIndex] &&
            Number(productQuery) == product?.productId

          return (
            (!cartQuery || Object.keys(addToCart).length != 0) && (
              <ProductCard
                slicerId={slicerId}
                slicerAddress={slicerAddress}
                key={product.id}
                product={product}
                chainInfo={chainInfo}
                editMode={false}
                ethUsd={ethUsd}
                displayProduct={displayProduct}
                externalPrices={externalPrices}
                addToCartQuantity={addToCart[product.productId] || 0}
                storeClosed={storeClosed}
              />
            )
          )
        })}
      </div>
      {visibleItems < products?.length && (
        <p className="py-6 text-center">
          <a onClick={() => setVisibleItems(visibleItems + increment)}>
            Load more
          </a>
        </p>
      )}
    </>
  ) : null
}

export default ProductsGrid

// TODO: Remove `displayProduct` open modal logic when product page is done
