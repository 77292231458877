import React from "react"

type Props = {
  values: {
    name: string
    value: string
  }[]
  value: string
  onChange: (e: any) => void
  label?: string
  defaultMessage?: string
  disabled?: boolean
  withBorder?: boolean
}

const InputSelect = ({
  label,
  defaultMessage,
  values,
  value,
  onChange,
  disabled,
  withBorder = true
}: Props) => {
  return (
    <div className="text-left">
      {label && (
        <p className="pb-2 pr-1 text-sm font-semibold text-gray-700">{label}</p>
      )}
      <select
        className={`w-full appearance-none focus:outline-none  ease-in-out pl-5 py-2 pr-4 text-black transition-all duration-150 bg-white  rounded-sm disabled:text-gray-500 disabled:border-blue-100 disabled:bg-gray-50 ${
          withBorder
            ? "shadow-light-focusable border-b-[3px] border-blue-300 focus:border-sky-600"
            : "border-input"
        }`}
        value={value}
        onChange={onChange}
        disabled={disabled}
        required
      >
        {defaultMessage && <option value="">{defaultMessage}</option>}
        {values.map((el, key) => (
          <option key={key} value={el.value}>
            {el.name || el.value}
          </option>
        ))}
      </select>
    </div>
  )
}

export default InputSelect
