import { Dispatch, SetStateAction } from "react"
import { PublicClient } from "viem"
import { publicClientToProvider } from "./ethers"
import { lookupAddress } from "./lookupAddress"

export const NNS_REGISTRY = "0x3e1970dc478991b49c4327973ea8a4862ef5a4de"
export const ENS_REGISTRY = "0x00000000000c2e074ec69a0dfb2997ba6c7d2e1e"

const resolveEns = async (
  provider: PublicClient,
  address: string,
  setAddress: Dispatch<SetStateAction<string>>
) => {
  if (address) {
    try {
      let resolved: string

      if (address.substring(address.length - 4) === ".eth") {
        // Set ENS registry address and resolve
        resolved = await provider.getEnsAddress({
          name: address
        })
      } else if (address.substring(address.length - 4) === "⌐◨-◨") {
        // TODO: How to do this with viem's public client? this is a lot slower
        // Set NNS registry address and resolve
        resolved = await publicClientToProvider(
          provider,
          NNS_REGISTRY
        ).resolveName(address)
      } else {
        resolved = await lookupAddress(provider, address)
      }

      if (
        (address.substring(address.length - 4) === ".eth" ||
          address.substring(address.length - 4) === "⌐◨-◨") &&
        !resolved
      ) {
        throw Error
      }

      setAddress(resolved)
    } catch (err) {
      console.log(err)
      // Skip when network is unsupported
      if (err.code != "UNSUPPORTED_OPERATION") {
        setAddress("Invalid ENS or NNS name")
      }
    }
  }
}

export default resolveEns
