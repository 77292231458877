import {
  ApolloClient,
  gql as apolloGql,
  InMemoryCache
} from "@apollo/client/core/index.js"

export const thegraphApiKey =
  process.env.THEGRAPH_API_KEY || process.env.NEXT_PUBLIC_THEGRAPH_API_KEY

export const getSubgraphUrl = (chainId = 8453) => {
  let chainUrl = ""

  switch (chainId) {
    case 5:
      chainUrl = "goerli"
      break
    case 8453:
      chainUrl = "base"
      break
    case 84531:
      chainUrl = "base-goerli"
      break
    default:
      chainUrl = "base"
  }

  return `https://api.studio.thegraph.com/query/5023/slice-${chainUrl}/version/latest`
}

const APIURL = thegraphApiKey
  ? `https://gateway-arbitrum.network.thegraph.com/api/${thegraphApiKey}/subgraphs/id/HCoNL7MuVQqbLkBsXVHrZB8cQsKB2Z99EJ3mv9ED4578`
  : getSubgraphUrl(8453)

const client = new ApolloClient({
  uri: APIURL,
  cache: new InMemoryCache()
})

export default client

export const gql = apolloGql
