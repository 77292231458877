import { Config, readContracts } from "@wagmi/core"
import { Abi } from "viem"

const multicall = async (
  config: Config,
  to: `0x${string}` | `0x${string}`[],
  abi: Abi,
  functionName: string,
  allArgs: string[][]
) => {
  if (typeof to === "string") {
    return await readContracts(config, {
      // @ts-ignore
      contracts: allArgs.map((args) => ({
        address: to,
        abi,
        functionName,
        args
      }))
    })
  } else {
    return await readContracts(config, {
      contracts: to.map((address, i) => ({
        address,
        abi,
        functionName,
        args: allArgs[i]
      }))
    })
  }
}

export default multicall
