import resolveEns from "@utils/resolveEns"
import React, {
  Dispatch,
  InputHTMLAttributes,
  SetStateAction,
  useEffect
} from "react"
import Input from "../Input/Input"
import { usePublicClient } from "wagmi"

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  address: `0x${string}`
  resolvedAddress: string
  setResolvedAddress: Dispatch<SetStateAction<string>>
  label?: string
  onChange?: (...args: any[]) => any
  placeholder?: string
  resolve?: boolean
  disabled?: boolean
}

const InputAddress: React.FC<Props> = (props) => {
  const mainnetPublicClient = usePublicClient({ chainId: 1 })
  const {
    address,
    required,
    disabled,
    label,
    placeholder = "0x… / slice-so.eth",
    onChange,
    resolvedAddress,
    setResolvedAddress,
    ...rest
  } = props

  const addressReduced = resolvedAddress
    ? ![".eth", "⌐◨-◨"].includes(
        resolvedAddress.substring(resolvedAddress.length - 4)
      ) && resolvedAddress !== "Invalid ENS or NNS name"
      ? resolvedAddress.replace(
          resolvedAddress.substring(5, resolvedAddress.length - 3),
          `___`
        )
      : resolvedAddress
    : null

  useEffect(() => {
    if (mainnetPublicClient && address) {
      const timeout = setTimeout(
        () => resolveEns(mainnetPublicClient, address, setResolvedAddress),
        200
      )
      return () => {
        clearTimeout(timeout)
        setResolvedAddress("")
      }
    }
  }, [mainnetPublicClient, address])

  return (
    <div className="relative mb-2">
      <Input
        type="string"
        value={address}
        placeholder={placeholder}
        label={label}
        required={required}
        disabled={disabled}
        error={resolvedAddress === "Invalid ENS or NNS name"}
        onChange={onChange}
      />
      {resolvedAddress && (
        <p
          className={`${
            resolvedAddress === "Invalid ENS or NNS name"
              ? "text-red-500"
              : "text-blue-600 dark:text-sky-300"
          } absolute text-xs opacity-80 font-black left-0 bottom-[-23px]
          }`}
        >
          {addressReduced}
        </p>
      )}
    </div>
  )
}

export default InputAddress
