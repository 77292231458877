import { sliceCoreAddress } from "@lib/initProvider"
import SliceCore from "artifacts/contracts/SliceCore.sol/SliceCore.json"
import { base, Chain } from "viem/chains"

const SliceTransferConfig = (
  batchMode: boolean,
  account: string,
  addresses: string[],
  slicerId: number,
  shares: number[],
  toRelease: boolean
) => {
  return {
    address: sliceCoreAddress,
    abi: SliceCore.abi,
    functionName: (() => {
      if (batchMode) return "slicerBatchTransfer"
      return toRelease ? "safeTransferFrom" : "safeTransferFromUnreleased"
    })(),
    args: (() => {
      if (batchMode) {
        return [account, addresses, slicerId, shares, toRelease]
      }
      return [account, addresses[0], slicerId, shares[0], ""]
    })(),
    account: account as `0x${string}`,
    chain: base as Chain
  }
}

export default SliceTransferConfig
