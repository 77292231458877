import { Input } from "@components/ui"
import Delete from "@components/icons/Delete"
import { isAddress } from "viem"
import { Dispatch, SetStateAction } from "react"
import { DiscountParams } from "../NFTDiscount/NFTDiscount"

type Props = {
  index: number
  discountParams: DiscountParams
  setDiscountParams: Dispatch<SetStateAction<DiscountParams>>
}

const DiscountList = ({ index, discountParams, setDiscountParams }: Props) => {
  const handleSetNft = (index: number, value: string) => {
    let items = [...discountParams.discounts]
    items[index].nft = value
    setDiscountParams((prev) => ({
      ...prev,
      discounts: items
    }))
  }

  const handleSetDiscount = (index: number, value: number) => {
    let items = [...discountParams.discounts]
    items[index].discount = value
    setDiscountParams((prev) => ({
      ...prev,
      discounts: items
    }))
  }

  const handleSetMinQuantity = (index: number, value: number) => {
    let items = [...discountParams.discounts]
    items[index].minQuantity = value
    setDiscountParams((prev) => ({
      ...prev,
      discounts: items
    }))
  }

  const handleRemove = (index: number) => {
    let items = [...discountParams.discounts]
    items.splice(index, 1)
    setDiscountParams((prev) => ({
      ...prev,
      discounts: items
    }))
  }

  return (
    <li className="flex items-center gap-4">
      <div className="pt-4">
        <Delete
          disabled={discountParams.discounts?.length === 1}
          onClick={() => handleRemove(index)}
        />
      </div>

      <div className="flex-grow">
        <Input
          label="Shares Subject address"
          value={discountParams.discounts[index].nft}
          handleOnChangeCustom={(e: any) => handleSetNft(index, e.target.value)}
          placeholder="0x…"
          error={
            discountParams.discounts[index].nft &&
            !isAddress(discountParams.discounts[index].nft)
          }
          required
        />
      </div>
      <div className="w-24">
        <Input
          type="number"
          label={"Discount %"}
          placeholder={"10"}
          min={0}
          max={100}
          step={0.01}
          value={discountParams.discounts[index].discount || ""}
          handleOnChangeCustom={(e: any) =>
            handleSetDiscount(index, e.target.value)
          }
          required
        />
      </div>
      <div className="w-24">
        <Input
          type="number"
          label={"Min owned"}
          placeholder={"1"}
          min={1}
          value={discountParams.discounts[index].minQuantity || ""}
          handleOnChangeCustom={(e: any) =>
            handleSetMinQuantity(index, e.target.value)
          }
          required
        />
      </div>
    </li>
  )
}

export default DiscountList
