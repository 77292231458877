import {
  Button,
  CardImage,
  DoubleText,
  FilesList,
  MarkdownBlock
} from "@components/ui"
import { useAppContext } from "@components/ui/context"
import { useEffect, useState } from "react"
import useDecodeShortcode from "@utils/useDecodeShortcode"
import handleDecryptData from "@lib/handleDecryptData"
import Spinner from "@components/icons/Spinner"
import { getSliceSubdomain } from "@utils/getSliceSubdomain"

export const PURCHASE_PRODUCT_VIEW = (params: any) => {
  const { account } = useAppContext()
  const {
    slicerId,
    productId,
    name,
    image,
    purchasedQuantity,
    texts,
    creator,
    redeemButton,
    uid,
    purchaseHash,
    decryptedFiles: decryptedFilesParam,
    decryptedTexts: decryptedTextsParam,
    accountCodes
  } = params
  const { thanks, instructions } = texts || {
    thanks: undefined,
    instructions: undefined
  }

  const decodedInstructions = useDecodeShortcode(
    account,
    purchasedQuantity,
    slicerId,
    productId,
    instructions,
    accountCodes
  )

  const [decrypted, setDecrypted] = useState(null)

  useEffect(() => {
    if (purchaseHash) {
      const handleDecrypt = async () => {
        const data = await handleDecryptData(
          slicerId,
          name,
          creator,
          uid,
          purchaseHash
        )
        setDecrypted(data || {})
      }

      handleDecrypt()
    }
  }, [])

  const decryptedFiles = decryptedFilesParam || decrypted?.decryptedFiles || []
  const decryptedTexts = decryptedTextsParam || decrypted?.decryptedTexts || {}

  const { notes } = decryptedTexts || { notes: undefined }
  return (
    <>
      <div className="pt-4 pb-12 text-center sm:pb-16">
        <DoubleText inactive logoText={name} size="text-3xl sm:text-4xl" />
      </div>
      <div>
        <CardImage
          name={name}
          imageUrl={image}
          size="h-52 xs:h-72"
          product
          disableHover
        />
        {purchasedQuantity != 1 && (
          <p className="pt-6 text-sm text-center text-gray-500">
            You bought this product {purchasedQuantity} times
          </p>
        )}
        {thanks && (
          <div className="pb-6 pt-14">
            <div>
              <MarkdownBlock className="prose text-center" content={thanks} />
            </div>
          </div>
        )}
        {instructions && (
          <div className="py-8">
            <div>
              <MarkdownBlock content={decodedInstructions} />
            </div>
          </div>
        )}
        {purchaseHash && !decrypted ? (
          <div className="flex items-center justify-center gap-4 text-gray-600">
            Decrypting data
            <Spinner />
          </div>
        ) : (
          <>
            {notes && (
              <div className="py-8">
                <h3>Notes</h3>
                <div>
                  <MarkdownBlock content={notes} />
                </div>
              </div>
            )}
            {decryptedFiles.length != 0 && (
              <div className="max-w-sm py-6 mx-auto text-center">
                <FilesList
                  title="Download files"
                  files={decryptedFiles}
                  uploadable={false}
                  backgroundColor="nightwind-prevent bg-blue-600 text-white"
                  downloadable={true}
                />
              </div>
            )}
          </>
        )}

        {redeemButton && (
          <div className="text-center pt-4 space-y-6 max-w-[420px] mx-auto">
            <p className="text-sm text-gray-800 text-opacity-60">
              To redeem the purchased product you need to provide more info to
              the seller. Click the button below to proceed.
            </p>
            <Button
              label="Redeem"
              href={`https://${getSliceSubdomain()}redeem.slice.so?slicer=${slicerId}&product=${productId}`}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default PURCHASE_PRODUCT_VIEW
