import { Button, DoubleText } from "@components/ui"
import { useAppContext } from "@components/ui/context"
import { useEffect, useState } from "react"
import { chainData } from "@utils/chainData"

export const NETWORK_VIEW = (params: any) => {
  const { userChainId } = params
  const appChainId = Number(process.env.NEXT_PUBLIC_CHAIN_ID)
  const { switchNetwork, isSwitchingNetwork } = useAppContext()
  const [showHelptext, setShowHelptext] = useState(false)

  useEffect(() => {
    if (!showHelptext) {
      setTimeout(() => setShowHelptext(true), 2000)
    }
  }, [])

  return (
    <div className="text-center">
      <div className="pb-6">
        <DoubleText
          inactive
          size="text-2xl sm:text-3xl"
          logoText={
            isSwitchingNetwork
              ? `Connecting to ${chainData[appChainId].label}`
              : "Switch network"
          }
        />
      </div>
      {!isSwitchingNetwork && (
        <p className="text-lg">
          Connect to the{" "}
          <span className="font-black">{chainData[appChainId].label}</span>{" "}
          Network
        </p>
      )}
      <div className="flex justify-center pt-6">
        <Button
          label="Switch network"
          onClick={() =>
            switchNetwork({
              chainId: Number(process.env.NEXT_PUBLIC_CHAIN_ID)
            })
          }
          loading={isSwitchingNetwork}
        />
      </div>

      {isSwitchingNetwork && showHelptext && (
        <p className="pt-6 text-gray-600">Switch network in your wallet</p>
      )}

      {chainData[userChainId]?.url && (
        <p className="pt-6 text-gray-600">
          Or go to{" "}
          <a
            href={chainData[userChainId]?.url}
            target="_blank"
            rel="noreferrer"
            className="highlight"
          >
            {chainData[userChainId]?.label}
          </a>
        </p>
      )}
    </div>
  )
}

export default NETWORK_VIEW
