import Package from "@components/icons/Package"
import { Button, DoubleText, PurchasesConfirmationList } from "@components/ui"
import { useAppContext } from "@components/ui/context"
import { ProductCart } from "@lib/handleUpdateCart"
import { getSliceSubdomain } from "@utils/getSliceSubdomain"

export const CONFIRM_PURCHASE_VIEW = (params: any) => {
  const { setModalView } = useAppContext()
  const { cartItems } = params as { cartItems: ProductCart[] }

  const redeemableProducts = cartItems
    .filter((el) => el.redeemButton)
    .map(({ slicerId, productId }) => `slicer=${slicerId}&product=${productId}`)
    .join("&")

  return (
    <div className="text-center">
      <div className="pb-12">
        <DoubleText inactive logoText="Purchase summary" />
      </div>
      <PurchasesConfirmationList cart={cartItems} />
      {redeemableProducts.length != 0 ? (
        <div className="space-y-8">
          <Button
            label={
              <span className="flex items-center gap-2">
                <span>Redeem all</span>
                <Package className="w-5 h-5" />
              </span>
            }
            href={`https://${getSliceSubdomain()}redeem.slice.so?${redeemableProducts}`}
          />
          <p className="text-sm text-gray-500">
            Some products require an additional step to be redeemed
          </p>
        </div>
      ) : (
        <Button label="Close" onClick={() => setModalView({ name: "" })} />
      )}
    </div>
  )
}

export default CONFIRM_PURCHASE_VIEW
