const CarouselMerchandise = (color: string) => {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 1000 878"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip1)">
        <path
          d="M807.325 97.7892C800.873 92.0057 793.079 87.9259 784.65 85.9205L705.489 67.0869C682.99 61.7342 659.448 72.246 641.827 87.2238C622.066 104.02 590.278 121.83 544.32 121.83C498.362 121.83 466.574 104.02 446.813 87.2238C429.192 72.246 405.65 61.7342 383.151 67.0869L303.99 85.9205C295.561 87.9259 287.766 92.0057 281.315 97.7892L134.807 229.128C115.766 246.198 111.962 274.579 125.836 296.06L171.572 366.875C185.683 388.723 213.767 396.786 237.319 385.749L276.902 367.199C283.533 364.092 291.145 368.931 291.145 376.255V826.055C291.145 854.774 314.427 878.056 343.145 878.056H745.494C774.213 878.056 797.494 854.774 797.494 826.056V376.255C797.494 368.931 805.106 364.092 811.738 367.199L851.321 385.749C874.872 396.786 902.956 388.724 917.068 366.875L962.804 296.06C976.678 274.579 972.874 246.198 953.833 229.128L807.325 97.7892Z"
          fill="currentColor"
          className={color}
        />
        <path
          d="M166.243 290.241L175.154 309.256L166.243 290.241L126.66 308.79C112.619 315.37 95.877 310.563 87.4646 297.538L41.7282 226.723C33.4572 213.917 35.725 196.998 47.0763 186.822L193.584 55.4825C197.43 52.0346 202.077 49.6025 207.102 48.4069L286.264 29.5733C299.445 26.4371 316.245 32.3449 331.464 45.2811C354.241 64.641 390.693 84.8864 442.572 84.8864C494.45 84.8864 530.903 64.641 553.679 45.2811C568.898 32.3449 585.698 26.4371 598.88 29.5733L678.041 48.4069C683.066 49.6025 687.713 52.0346 691.559 55.4825L838.067 186.822C849.419 196.998 851.686 213.917 843.415 226.723L797.679 297.538C789.266 310.563 772.524 315.37 758.484 308.79L718.901 290.241C698.344 280.607 674.746 295.609 674.746 318.311V768.112C674.746 785.233 660.867 799.112 643.746 799.112H241.397C224.277 799.112 210.397 785.233 210.397 768.112V318.311C210.397 295.609 186.799 280.607 166.243 290.241Z"
          fill="black"
          stroke="white"
          strokeWidth="42"
        />
      </g>
      <defs>
        <clipPath id="clip1">
          <rect width="1000" height="878" rx="145" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CarouselMerchandise
