import useDecodeShortcode, { ReducedShortcode } from "@utils/useDecodeShortcode"
import React, { Dispatch, SetStateAction, useState } from "react"
import { InputSwitch, MySwitch, Shortcodes, Textarea } from ".."
import { getSliceSubdomain } from "@utils/getSliceSubdomain"

type Props = {
  slicerId: number
  instructions: string
  setInstructions: Dispatch<SetStateAction<string>>
  customShortcodes: ReducedShortcode
  setCustomShortcodes: Dispatch<SetStateAction<ReducedShortcode>>
  redeemButton: boolean
  setRedeemButton: Dispatch<SetStateAction<boolean>>
}

const AddProductFormPurchases = ({
  slicerId,
  instructions,
  setInstructions,
  customShortcodes,
  setCustomShortcodes,
  redeemButton,
  setRedeemButton
}: Props) => {
  const decodedInstructions = useDecodeShortcode(
    "buyerAddress",
    "0",
    String(slicerId),
    "0",
    instructions
  )

  return (
    <>
      <div className="pb-10">
        <h1 className="pb-6">Purchase view</h1>
        <p className="text-lg text-gray-600">
          Customize what buyers see after buying the product
        </p>
      </div>
      <div>
        <Textarea
          label="Purchase information"
          placeholder="What do you want to say to buyers after purchasing? Is there any additional step they should take to redeem or use the product?"
          value={instructions}
          previewValue={decodedInstructions}
          onChange={setInstructions}
          rows={4}
        />
      </div>
      <Shortcodes
        customShortcodes={customShortcodes}
        setCustomShortcodes={setCustomShortcodes}
        instructions={instructions}
        setInstructions={setInstructions}
      />
      {/* <div>
        <InputSwitch
          label="Prompt redeem on Slice"
          enabled={redeemButton}
          setEnabled={setRedeemButton}
          questionText={
            <>
              <p>
                Shows a redeem button in the instructions which redirects the
                buyer to your form on Slice Redeem.
              </p>
              <p>
                Note that you have to first{" "}
                <a
                  href={`
                  https://${getSliceSubdomain()}redeem.slice.so/forms
                  `}
                  className="highlight"
                  target="_blank"
                  rel="noreferrer"
                >
                  create a Slice form
                </a>{" "}
                before users can redeem.
              </p>
            </>
          }
        />
      </div> */}
    </>
  )
}

export default AddProductFormPurchases

// Other kinds of purchase data
// - A link of some sort? Webhook? What else?
// - Additional text info
