const CarouselStartups = (color: string) => {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 1000 926"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip2)">
        <path
          d="M179.654 610.252C172.653 620.984 174.127 635.152 183.187 644.212L359.502 820.527C368.562 829.587 382.731 831.061 393.462 824.06L434.238 797.457C448.648 788.055 450.758 767.777 438.591 755.61L248.103 565.122C235.937 552.955 215.658 555.065 206.256 569.476L179.654 610.252Z"
          fill="currentColor"
          className={color}
        />
        <path
          d="M407.955 241.224C425.474 223.706 412.232 193.807 387.486 195.007L299.526 199.274C292.803 199.6 286.442 202.417 281.682 207.177L142.743 346.116C128.39 360.469 134.336 384.926 153.677 391.087L217.151 411.305C226.804 414.38 237.366 411.812 244.53 404.648L407.955 241.224Z"
          fill="currentColor"
          className={color}
        />
        <path
          d="M612.627 850.034C618.787 869.375 643.244 875.322 657.597 860.969L796.537 722.03C801.296 717.27 804.114 710.909 804.44 704.185L808.707 616.225C809.907 591.48 780.008 578.237 762.49 595.756L599.065 759.181C591.902 766.345 589.334 776.907 592.408 786.561L612.627 850.034Z"
          fill="currentColor"
          className={color}
        />
        <path
          d="M59.6394 738.393C49.0591 727.813 49.0587 710.659 59.6387 700.079L88.333 671.384C98.913 660.804 116.067 660.804 126.647 671.384L130.141 674.878C140.721 685.458 140.721 702.612 130.141 713.192L101.447 741.886C90.8668 752.466 73.7135 752.466 63.1333 741.887L59.6394 738.393Z"
          fill="currentColor"
          className={color}
        />
        <path
          d="M266.563 907.018C255.983 917.598 238.829 917.599 228.249 907.018L224.755 903.524C214.175 892.944 214.175 875.791 224.754 865.21L253.449 836.515C264.029 825.935 281.183 825.934 291.763 836.515L295.258 840.009C305.838 850.589 305.838 867.742 295.258 878.323L266.563 907.018Z"
          fill="currentColor"
          className={color}
        />
        <path
          d="M108.95 851.256C98.3703 840.676 98.3703 823.522 108.95 812.942L169.421 752.471C180.001 741.891 197.155 741.891 207.735 752.471L211.229 755.966C221.809 766.546 221.809 783.7 211.229 794.28L150.759 854.75C140.179 865.33 123.025 865.33 112.445 854.75L108.95 851.256Z"
          fill="currentColor"
          className={color}
        />
        <path
          d="M986.816 52.5351C984.344 45.1574 978.556 39.3699 971.179 36.898C857.836 -1.07803 727.732 25.0668 637.466 115.331L288.855 463.942C278.275 474.522 278.275 491.676 288.855 502.256L521.458 734.857C532.038 745.437 549.192 745.437 559.772 734.857L908.383 386.246C998.647 295.982 1024.79 165.878 986.816 52.5351Z"
          fill="currentColor"
          className={color}
        />
        <path
          d="M128.477 612.869C124.767 609.16 124.164 603.359 127.03 598.965L153.632 558.189C157.481 552.289 165.784 551.425 170.765 556.406L361.253 746.894C366.234 751.876 365.371 760.178 359.471 764.027L318.695 790.63C314.302 793.497 308.501 792.893 304.791 789.184L128.477 612.869Z"
          fill="black"
          stroke="white"
          strokeWidth="32"
        />
        <path
          d="M322.237 190.959C332.369 190.468 337.79 202.709 330.618 209.881L167.192 373.305C164.259 376.238 159.935 377.29 155.983 376.031L92.5092 355.813C84.5906 353.29 82.156 343.277 88.0324 337.401L226.972 198.462C228.92 196.513 231.525 195.359 234.277 195.226L322.237 190.959Z"
          fill="black"
          stroke="white"
          strokeWidth="32"
        />
        <path
          d="M591.574 840.939L580.26 829.626C574.383 835.502 564.37 833.068 561.848 825.149L541.63 761.675C540.371 757.723 541.422 753.399 544.355 750.466L707.779 587.04C714.952 579.868 727.193 585.289 726.701 595.421L722.435 683.381C722.301 686.133 721.148 688.738 719.199 690.687L580.26 829.626L591.574 840.939Z"
          fill="black"
          stroke="white"
          strokeWidth="32"
        />
        <path
          d="M34.0083 715.028C31.6292 712.649 31.6292 708.792 34.0082 706.413L62.7025 677.717C65.0815 675.338 68.9387 675.338 71.3178 677.717L74.8121 681.212C77.1911 683.591 77.1911 687.448 74.812 689.827L46.1174 718.521C43.7384 720.9 39.8813 720.9 37.5023 718.522L34.0084 715.028L19.1595 729.877L34.0083 715.028Z"
          fill="black"
          stroke="white"
          strokeWidth="42"
        />
        <path
          d="M211.234 883.654C208.855 886.033 204.998 886.033 202.619 883.654L199.124 880.159C196.745 877.78 196.745 873.923 199.124 871.544L227.819 842.848C230.198 840.469 234.055 840.469 236.434 842.848L239.929 846.343C242.308 848.722 242.308 852.579 239.929 854.958L211.234 883.654Z"
          fill="black"
          stroke="white"
          strokeWidth="42"
        />
        <path
          d="M83.3196 827.891C80.9405 825.512 80.9405 821.655 83.3196 819.276L143.79 758.805C146.169 756.426 150.026 756.426 152.406 758.805L155.9 762.3C158.279 764.679 158.279 768.536 155.9 770.915L95.4294 831.385C93.0503 833.765 89.1931 833.765 86.8141 831.385L83.3196 827.891Z"
          fill="black"
          stroke="white"
          strokeWidth="42"
        />
        <path
          d="M880.88 39.1771L900.792 32.5058L880.88 39.1771C916.421 145.259 891.926 266.951 807.51 351.367L458.898 699.979C456.52 702.358 452.662 702.358 450.283 699.979L217.68 467.377C215.301 464.998 215.301 461.141 217.68 458.762L566.291 110.151C650.71 25.7341 772.402 1.23762 878.483 36.7808L885.126 16.9542L878.483 36.7808C879.615 37.16 880.5 38.0453 880.88 39.1771Z"
          fill="black"
          stroke="white"
          strokeWidth="42"
        />
        <ellipse
          cx="719.218"
          cy="198.698"
          rx="61.8892"
          ry="61.8892"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip2">
          <rect width="1000" height="926" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CarouselStartups
