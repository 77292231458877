import { ProductCart } from "@lib/handleUpdateCart"
import { ListCard } from ".."
import { ethers, utils } from "ethers"

type Props = {
  cart: ProductCart[]
  updateCart: (cart: ProductCart[]) => void
  ethUsd: number
}

const CartList = ({ cart, updateCart, ethUsd }: Props) => {
  return (
    <div className="p-3 space-y-3 shadow-lg bg-gray-300 bg-opacity-30 backdrop-blur-md rounded-xl max-h-[300px] sm:max-h-[450px] w-[21rem] sm:w-[22rem] overflow-y-scroll">
      {cart?.map((product, key) => {
        const {
          slicerId,
          slicerAddress,
          productId,
          quantity,
          price,
          currency,
          isUSD,
          extCallValue,
          name,
          externalAddress,
          path
        } = product
        const productPrice = isUSD
          ? Math.floor(Number(price) / (ethUsd * 100)) / 10000
          : currency === ethers.constants.AddressZero
          ? Math.floor(Number(price) / 10 ** 14) / 10000
          : Math.floor(Number(price) / 10 ** 4) / 100
        const externalCallEth = utils.formatEther(extCallValue)

        const index = cart.findIndex(
          (p: ProductCart) =>
            p.productId == productId && p.slicerAddress == slicerAddress
        )

        const handleRemove = () => {
          const newCart = [...cart]
          newCart.splice(index, 1)
          updateCart(newCart)
        }
        const decimals = currency === ethers.constants.AddressZero ? 10000 : 100
        const formattedPrice =
          Math.floor(
            (Number(productPrice) + Number(externalCallEth)) * decimals
          ) / decimals
        const icon = currency === ethers.constants.AddressZero ? "Ξ" : "USDC"
        return (
          <ListCard
            key={key}
            label={name}
            sideLabel={`${slicerId} / #${productId}`}
            subLabel={`${icon} ${formattedPrice}${
              quantity != 1 ? ` (${quantity})` : ""
            }`}
            href={`/slicer/${path}?product=${productId}`}
            remove={() => handleRemove()}
            externalAddress={externalAddress}
          />
        )
      })}
    </div>
  )
}

export default CartList
