import formatBlockscanUrl from "@utils/formatBlockscanUrl"
import resolveEns from "@utils/resolveEns"
import { useEffect, useState } from "react"
import { usePublicClient } from "wagmi"

type Props = {
  address: `0x${string}`
  href?: string
}

const ResolvedAddress = ({ address, href }: Props) => {
  const mainnetPublicClient = usePublicClient({ chainId: 1 })
  const [resolvedAddress, setResolvedAddress] = useState("")
  const addressReduced = address.replace(
    address.substring(5, address.length - 3),
    `\xa0\xa0\xa0\xa0\xa0\xa0`
  )

  useEffect(() => {
    resolveEns(mainnetPublicClient, address, setResolvedAddress)
  }, [])

  return (
    <a
      className="highlight"
      href={href || formatBlockscanUrl(address)}
      target="_blank"
      rel="noreferrer"
    >
      {resolvedAddress || addressReduced}
    </a>
  )
}

export default ResolvedAddress
