import { useAppContext } from "../context"

type Props = {
  text: JSX.Element
  image: JSX.Element
  side?: "left" | "right"
  preventShuffleColor?: boolean
}

const HomeSection = ({
  text,
  image,
  side = "left",
  preventShuffleColor
}: Props) => {
  const { shuffleColors } = useAppContext()
  return (
    <div
      className={`flex flex-col items-center ${
        side === "right" ? "md:flex-row-reverse" : "md:flex-row"
      }`}
    >
      <div
        className={`prose flex-1 text-left ${
          side === "right" ? "md:text-right" : ""
        }`}
      >
        {text}
      </div>
      <div className="flex-1 pt-12 md:max-w-[420px] md:pt-0">
        <div
          className={`px-4 flex items-center ${
            side === "right" ? "justify-start" : "justify-end"
          } w-full h-full "`}
        >
          <div onClick={() => !preventShuffleColor && shuffleColors()}>
            {image}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeSection
