import Cart from "@components/icons/Cart"
import Minus from "@components/icons/Minus"
import Plus from "@components/icons/Plus"
import Trash from "@components/icons/Trash"
import handleUpdateCart, {
  ProductCart,
  RedeemTexts
} from "@lib/handleUpdateCart"
import ShoppingBag from "@components/icons/ShoppingBag"
import { useAppContext } from "../context"
import { useEffect, useState } from "react"
import handleRedeemProduct from "@utils/handleRedeemProduct"
import Spinner from "@components/icons/Spinner"
import Lock from "@components/icons/Lock"
import { ExtCall } from "@lib/handlers/chain"
import { MerkleTree } from "merkletreejs"
import keccak256 from "keccak256"
import { ethers } from "ethers"
import { useRouter } from "next/router"
import { useEthersProvider } from "@utils/ethers"
import { useConfig } from "wagmi"
import { useConnectModal } from "@rainbow-me/rainbowkit"

type Props = {
  productCart: ProductCart
  dbId: number
  slicerId: number
  slicerAddress: string
  productId: number
  price: string
  currency: string
  isUSD: boolean
  extAddress: string
  extCallValue: string
  extRelativePrice: boolean
  extCheckSig: string
  name: string
  image: string
  maxUnits: number
  uid: string
  creator: string
  texts: RedeemTexts
  allowedAddresses: string[]
  availableUnits: number
  purchasedQuantity: number
  redeemButton: boolean | undefined
  isDelivery: boolean
  labelAdd?: string
  labelRemove?: string
  preview?: boolean
  shortcodes?: string[]
  externalPriceAddress?: string
  customMessage?: string
  addToCartQuantity?: number
}

const CartButton = ({
  dbId,
  slicerId,
  productCart,
  slicerAddress,
  productId,
  price,
  currency,
  isUSD,
  extAddress,
  extCallValue,
  extRelativePrice,
  extCheckSig,
  name,
  maxUnits,
  image,
  uid,
  creator,
  texts,
  redeemButton,
  isDelivery,
  allowedAddresses,
  availableUnits,
  purchasedQuantity,
  labelAdd,
  labelRemove,
  preview,
  shortcodes,
  externalPriceAddress,
  customMessage,
  addToCartQuantity
}: Props) => {
  const { account, cart, updateCart, setModalView } = useAppContext()
  const provider = useEthersProvider()
  const router = useRouter()
  const { id } = router.query
  const [loading, setLoading] = useState(false)
  const [isUnlocked, setIsUnlocked] = useState(false)
  const [isLoadingExtCall, setisLoadingExtCall] = useState(false)
  const [isSuccessExtCall, setSuccessExtCall] = useState(false)
  const [isFailExtCall, setIsFailExtCall] = useState(false)
  const { openConnectModal } = useConnectModal()
  const isDisabled = isDelivery
  const config = useConfig()

  const adjustedAvailability = availableUnits - productCart?.quantity
  let buyerCustomData: any = "0x"

  const handleExtCall = async () => {
    setisLoadingExtCall(true)
    try {
      const call = await ExtCall(
        provider,
        extAddress,
        extCheckSig,
        slicerId,
        productId,
        account,
        1,
        [],
        buyerCustomData
      )
      if (Number(call) === 1) {
        setSuccessExtCall(true)
      } else {
        setIsFailExtCall(true)
        setTimeout(() => {
          setIsFailExtCall(false)
        }, 1500)
      }
    } catch (err) {
      setIsFailExtCall(true)
      setTimeout(() => {
        setIsFailExtCall(false)
      }, 1500)
    }
    setisLoadingExtCall(false)
  }

  useEffect(() => {
    setSuccessExtCall(false)

    if (account && extCheckSig != "0x" && extCheckSig != "0x00000000") {
      handleExtCall()
    }
  }, [account])

  if (account && allowedAddresses.length != 0) {
    const leafNodes = allowedAddresses.map((addr) => keccak256(addr))
    const tree = new MerkleTree(leafNodes, keccak256, {
      sortPairs: true
    })
    const proof = tree.getHexProof(keccak256(account.toLowerCase()))
    if (proof.length != 0) {
      buyerCustomData = ethers.utils.defaultAbiCoder.encode(
        ["bytes32[]"],
        [proof]
      )
    }

    // TODO: Fix proof generation when allowedAddresses.length == 1
  }

  useEffect(() => {
    if (addToCartQuantity) {
      handleUpdateCart(
        (typeof window !== "undefined" &&
          JSON.parse(localStorage.getItem("cart"))) ||
          [],
        updateCart,
        productCart,
        slicerId,
        slicerAddress,
        productId,
        price,
        currency,
        isUSD,
        extCallValue,
        buyerCustomData,
        name,
        maxUnits != 0 &&
          (productCart?.quantity || 0) + addToCartQuantity >
            maxUnits - purchasedQuantity
          ? maxUnits - purchasedQuantity
          : (productCart?.quantity || 0) + addToCartQuantity,
        String(id),
        dbId,
        image,
        uid,
        creator,
        texts,
        shortcodes,
        redeemButton,
        extRelativePrice,
        externalPriceAddress,
        customMessage
      )
    }
  }, [])

  // if (maxUnits == 0) {
  //   labelAdd = "Unavailable"
  // }

  return !productCart && purchasedQuantity != 0 ? (
    maxUnits == 1 || maxUnits == purchasedQuantity ? (
      <div
        className="relative z-10 flex items-center justify-center w-full text-center text-white transition-colors duration-100 bg-blue-500 rounded-md h-9 hover:text-white nightwind-prevent group-cart hover:bg-blue-600"
        onClick={async () =>
          await handleRedeemProduct(
            config,
            account,
            dbId,
            slicerId,
            productId,
            name,
            image,
            uid,
            creator,
            texts,
            setLoading,
            setModalView,
            shortcodes,
            redeemButton
          )
        }
      >
        <p className="mr-2 text-sm font-medium sm:text-base">
          {`Redeem${purchasedQuantity != 1 ? ` (${purchasedQuantity})` : ""}`}
        </p>
        {loading ? (
          <Spinner />
        ) : (
          <ShoppingBag className="w-5 h-5 group-cart-el" />
        )}
      </div>
    ) : (
      <div className="relative z-10 flex items-center justify-center w-full overflow-hidden text-center bg-white border border-gray-100 rounded-md shadow-md nightwind-prevent-block">
        {extCheckSig != "0x" &&
        extCheckSig != "0x00000000" &&
        !isSuccessExtCall ? (
          <div
            className={`relative z-10 flex flex-grow items-center justify-center h-9 text-white group-cart ${
              isFailExtCall
                ? "bg-red-500 hover:bg-red-600"
                : "bg-gray-500 hover:bg-gray-600"
            } transition-colors duration-100`}
            onClick={
              account
                ? async () => await handleExtCall()
                : () => openConnectModal()
            }
            onMouseEnter={() => setIsUnlocked(true)}
            onMouseLeave={() => setIsUnlocked(false)}
          >
            {labelAdd && (
              <p className="mr-2 text-sm font-medium sm:text-base">
                {labelAdd}
              </p>
            )}
            {isLoadingExtCall ? (
              <Spinner />
            ) : (
              <Lock
                className="w-5 h-5 mr-1 group-cart-el"
                isUnlocked={isUnlocked}
              />
            )}
          </div>
        ) : (
          <div
            className={`relative z-10 h-9 flex flex-grow items-center justify-center text-white ${
              availableUnits != 0
                ? "group-cart bg-green-500 hover:bg-green-600 transition-colors duration-100"
                : "bg-gray-500 cursor-default"
            }`}
            onClick={async () =>
              !preview &&
              availableUnits != 0 &&
              (await handleUpdateCart(
                cart,
                updateCart,
                productCart,
                slicerId,
                slicerAddress,
                productId,
                price,
                currency,
                isUSD,
                extCallValue,
                buyerCustomData,
                name,
                productCart ? ++productCart.quantity : 1,
                String(id),
                dbId,
                image,
                uid,
                creator,
                texts,
                shortcodes,
                redeemButton,
                extRelativePrice,
                externalPriceAddress,
                customMessage
              ))
            }
          >
            {labelAdd && (
              <p className="mr-2 text-sm font-medium sm:text-base">
                {labelAdd}
              </p>
            )}
            <Cart className="w-5 h-5 mr-1 group-cart-el" />
          </div>
        )}
        <div
          className="relative z-10 flex items-center justify-center w-1/3 text-white transition-colors duration-100 bg-blue-500 h-9 rounded-r-md nightwind-prevent group-cart hover:bg-blue-600"
          onClick={() =>
            handleRedeemProduct(
              config,
              account,
              dbId,
              slicerId,
              productId,
              name,
              image,
              uid,
              creator,
              texts,
              setLoading,
              setModalView,
              shortcodes,
              redeemButton
            )
          }
        >
          {loading ? (
            <Spinner />
          ) : (
            <ShoppingBag className="w-5 h-5 group-cart-el" />
          )}
        </div>
      </div>
    )
  ) : !productCart ? (
    extCheckSig != "0x" && extCheckSig != "0x00000000" && !isSuccessExtCall ? (
      <div
        className={`relative z-10 flex items-center justify-center w-full h-9 text-center text-white rounded-md nightwind-prevent group-cart ${
          isFailExtCall
            ? "bg-red-500 hover:bg-red-600"
            : "bg-gray-500 hover:bg-gray-600"
        } transition-colors duration-100`}
        onClick={
          account ? async () => await handleExtCall() : () => openConnectModal()
        }
        onMouseEnter={() => setIsUnlocked(true)}
        onMouseLeave={() => setIsUnlocked(false)}
      >
        {labelAdd && (
          <p className="mr-2 text-sm font-medium sm:text-base">{labelAdd}</p>
        )}
        {isLoadingExtCall ? (
          <Spinner />
        ) : (
          <Lock
            className="w-5 h-5 mr-1 group-cart-el"
            isUnlocked={isUnlocked}
          />
        )}
      </div>
    ) : (
      <div
        className={`relative z-10 flex items-center justify-center w-full h-9 text-center text-white rounded-md nightwind-prevent ${
          !isDisabled && availableUnits != 0
            ? "group-cart bg-green-500 hover:bg-green-600 transition-colors duration-100"
            : "bg-gray-500 cursor-default"
        }`}
        onClick={async () =>
          !preview &&
          availableUnits != 0 &&
          !isDisabled &&
          (await handleUpdateCart(
            cart,
            updateCart,
            productCart,
            slicerId,
            slicerAddress,
            productId,
            price,
            currency,
            isUSD,
            extCallValue,
            buyerCustomData,
            name,
            productCart ? ++productCart.quantity : 1,
            String(id),
            dbId,
            image,
            uid,
            creator,
            texts,
            shortcodes,
            redeemButton,
            extRelativePrice,
            externalPriceAddress,
            customMessage
          ))
        }
      >
        {labelAdd && (
          <p className="text-sm font-medium sm:text-base">{labelAdd}</p>
        )}
        {maxUnits != 0 && <Cart className="w-5 h-5 ml-2 mr-1 group-cart-el" />}
      </div>
    )
  ) : maxUnits != 1 ? (
    <div className="relative z-10 grid items-center justify-center w-full grid-cols-4 overflow-hidden text-center bg-white border border-gray-100 rounded-md shadow-md">
      <div
        className="flex items-center justify-center text-red-500 transition-colors duration-100 h-9 hover:bg-red-500 hover:text-white"
        onClick={async () =>
          await handleUpdateCart(
            cart,
            updateCart,
            productCart,
            slicerId,
            slicerAddress,
            productId,
            price,
            currency,
            isUSD,
            extCallValue,
            buyerCustomData,
            name,
            --productCart.quantity,
            String(id),
            dbId,
            image,
            uid,
            creator,
            texts,
            shortcodes,
            redeemButton,
            extRelativePrice,
            externalPriceAddress,
            customMessage
          )
        }
      >
        <Minus className="w-[17px] h-[17px]" />
      </div>
      <div className="flex items-center justify-center col-span-2 pl-3 text-sm text-black border-l border-r border-gray-200 cursor-default h-9">
        <input
          value={productCart.quantity}
          type="number"
          className="w-full text-center bg-transparent border-none outline-none focus:ring-0 form-input"
          onWheel={(e) => e.currentTarget.blur()}
          onChange={(e) => {
            handleUpdateCart(
              cart,
              updateCart,
              productCart,
              slicerId,
              slicerAddress,
              productId,
              price,
              currency,
              isUSD,
              extCallValue,
              buyerCustomData,
              name,
              Number(e.target.value),
              String(id),
              dbId,
              image,
              uid,
              creator,
              texts,
              shortcodes,
              redeemButton,
              extRelativePrice,
              externalPriceAddress,
              customMessage
            )
          }}
        />
      </div>
      <div
        className={`flex items-center justify-center h-9 transition-colors duration-100 ${
          adjustedAvailability != 0 &&
          (maxUnits == 0 || purchasedQuantity + productCart.quantity < maxUnits)
            ? "text-green-500 hover:bg-green-500 hover:text-white"
            : "text-white bg-gray-500 cursor-default"
        }`}
        onClick={async () =>
          adjustedAvailability != 0 &&
          (maxUnits == 0 ||
            purchasedQuantity + productCart.quantity < maxUnits) &&
          (await handleUpdateCart(
            cart,
            updateCart,
            productCart,
            slicerId,
            slicerAddress,
            productId,
            price,
            currency,
            isUSD,
            extCallValue,
            buyerCustomData,
            name,
            productCart ? ++productCart.quantity : 1,
            String(id),
            dbId,
            image,
            uid,
            creator,
            texts,
            shortcodes,
            redeemButton,
            extRelativePrice,
            externalPriceAddress,
            customMessage
          ))
        }
      >
        <Plus className="w-[17px] h-[17px]" />
      </div>
    </div>
  ) : (
    <div
      className="relative z-10 flex items-center justify-center w-full text-center text-white transition-colors duration-100 bg-red-500 rounded-md h-9 nightwind-prevent group-cart hover:bg-red-600"
      onClick={async () =>
        await handleUpdateCart(
          cart,
          updateCart,
          productCart,
          slicerId,
          slicerAddress,
          productId,
          price,
          currency,
          isUSD,
          extCallValue,
          buyerCustomData,
          name,
          --productCart.quantity,
          String(id),
          dbId,
          image,
          uid,
          creator,
          texts,
          shortcodes,
          redeemButton,
          extRelativePrice,
          externalPriceAddress,
          customMessage
        )
      }
    >
      {labelRemove && (
        <p className="mr-2 text-sm font-medium sm:text-base">{labelRemove}</p>
      )}
      <Trash className="w-5 h-5 mr-1 group-cart-el" />
    </div>
  )
}

export default CartButton

// TODO: Refactor.
