// import { useState, Dispatch, SetStateAction, useEffect, useRef } from "react"
// import {
//   Button,
//   MessageBlock,
//   AddProductFormAvailability,
//   AddProductFormPrice,
//   AddProductFormGeneral,
//   AddProductFormPurchases,
//   AddProductFormPreview,
//   AddProductFormFiles,
//   AddProductProgress
// } from "@components/ui"
// import handleMessage, { Message } from "@utils/handleMessage"
// import { NewImage } from "pages/slicer/[id]"
// import { useAppContext } from "../context"
// import { ProductParamsStruct } from "types/typechain/ProductsModule"
// import AddProductFormExternal from "../AddProductFormExternal"
// import ethToWei from "@utils/ethToWei"
// import { useAddRecentTransaction } from "@rainbow-me/rainbowkit"
// import { track } from "@vercel/analytics"
// import { emptyExternalCall, HookParams } from "@components/hooks/purchaseHooks"
// import { ReducedShortcode } from "@utils/useDecodeShortcode"
// import {
//   strategiesRender,
//   Strategy,
//   StrategyParams
// } from "@components/priceStrategies/strategies"
// import { Step } from "pages/slicer/[id]/products/new"
// import { BigNumber, ethers, Signer } from "ethers"
// import { LogDescription } from "ethers/lib/utils"
// import { useEthersSigner } from "@utils/ethers"
// import { availableCurrencies } from "../AddProductFormPrice/AddProductFormPrice"
// import * as strategies from "../../priceStrategies/index"
// import { waitForTransactionReceipt, writeContract } from "@wagmi/core"
// import ProductsModule from "artifacts/contracts/ProductsModule.sol/ProductsModule.json"
// import handleLog from "@utils/handleLog"
// import { productsModuleAddress } from "@lib/initProvider"
// import { Log, decodeEventLog, parseAbi } from "viem"
// import { useConfig } from "wagmi"

// type Props = {
//   slicerId: number
//   idPath: string
//   progressStep: string
//   progressStepIndex: number
//   uploadStep: number
//   steps: Step[]
//   setProgressStep: Dispatch<SetStateAction<string>>
//   setSteps: Dispatch<SetStateAction<Step[]>>
//   setUploadStep: Dispatch<SetStateAction<number>>
//   setUploadPct: Dispatch<SetStateAction<number>>
//   setSuccess: Dispatch<SetStateAction<boolean>>
//   setLogs: Dispatch<SetStateAction<LogDescription[]>>
//   setCloneAddress: Dispatch<SetStateAction<string>>
//   priceParams: StrategyParams
//   setPriceParams: Dispatch<SetStateAction<StrategyParams>>
// }

// const AddProductForm = ({
//   slicerId,
//   idPath,
//   progressStep,
//   progressStepIndex,
//   uploadStep,
//   steps,
//   setProgressStep,
//   setSteps,
//   setUploadStep,
//   setUploadPct,
//   setSuccess,
//   setLogs,
//   setCloneAddress,
//   priceParams,
//   setPriceParams
// }: Props) => {
//   const { account, setModalView } = useAppContext()
//   const signer = useEthersSigner({
//     chainId: Number(process.env.NEXT_PUBLIC_CHAIN_ID)
//   })
//   const addRecentTransaction = useAddRecentTransaction()
//   const config = useConfig()

//   const [usdValue, setUsdValue] = useState<number>()
//   const [ethValue, setEthValue] = useState<number>()
//   const [name, setName] = useState("")
//   const [shortDescription, setShortDescription] = useState("")
//   const [description, setDescription] = useState("")
//   const [newImage, setNewImage] = useState<NewImage>({
//     url: "",
//     file: undefined
//   })
//   const [isUSD, setIsUSD] = useState(false)
//   const [isMultiple, setIsMultiple] = useState(false)
//   const [isLimited, setIsLimited] = useState(false)
//   const [isFree, setIsFree] = useState(false)
//   const [units, setUnits] = useState(0)
//   const [maxUnits, setMaxUnits] = useState(1)
//   const [productCurrency, setProductCurrency] = useState(
//     availableCurrencies[0].value
//   )

//   const [selectedHook, setSelectedHook] = useState(undefined)
//   const [priceStrategy, setPriceStrategy] = useState<Strategy>(
//     strategiesRender[0]
//   )
//   const [customShortcodes, setCustomShortcodes] = useState<ReducedShortcode>({})
//   const [purchaseHookParams, setPurchaseHookParams] = useState<HookParams>({
//     externalCall: emptyExternalCall
//   })
//   const [clonePurchaseHook, setClonePurchaseHook] = useState(false)
//   const [thankMessage, setThankMessage] = useState("")
//   const [instructions, setInstructions] = useState("")
//   const [notes, setNotes] = useState("")
//   const [files, setFiles] = useState<File[]>([])
//   const [message, setMessage] = useState<Message>({
//     message: "",
//     messageStatus: "success"
//   })
//   const [redeemButton, setRedeemButton] = useState(false)
//   const submitEl = useRef(null)

//   const externalCall = purchaseHookParams.externalCall

//   const handleNext = async (e: React.SyntheticEvent<EventTarget>) => {
//     e.preventDefault()

//     setSteps((steps) => {
//       const newSteps = [...steps]
//       newSteps[progressStepIndex].status = "success"
//       return newSteps
//     })

//     setProgressStep(steps[progressStepIndex + 1].label)
//   }
//   const handleSetMessageError = () => {
//     setMessage({
//       messageStatus: "error",
//       message: "Please fix the errors before submitting"
//     })
//     setTimeout(() => {
//       setMessage({ messageStatus: "success", message: "" })
//     }, 3000)
//   }

//   const submit = async (e: React.SyntheticEvent<EventTarget>) => {
//     e.preventDefault()

//     // track("create_product_attempt")
//     const { beforeCreate, handleReject, handleSuccess } = await import(
//       "@lib/handleCreateProduct"
//     )

//     const chainId = process.env.NEXT_PUBLIC_CHAIN_ID

//     const filteredShortcodes = Object.entries(customShortcodes).filter(
//       (shortcode) => {
//         const [, val] = shortcode
//         return val.length != 0
//       }
//     )

//     let image: string,
//       newProduct: any,
//       data: string,
//       purchaseDataCID: string,
//       purchaseData: string

//     try {
//       const productInfo = await beforeCreate(
//         account,
//         slicerId,
//         name,
//         shortDescription,
//         description,
//         purchaseHookParams,
//         newImage,
//         files,
//         redeemButton,
//         thankMessage,
//         instructions,
//         notes,
//         filteredShortcodes,
//         setUploadStep,
//         setUploadPct
//       )

//       image = productInfo.image
//       newProduct = productInfo.newProduct
//       data = productInfo.data
//       purchaseDataCID = productInfo.purchaseDataCID
//       purchaseData = productInfo.purchaseData

//       if (purchaseHookParams?.deploy != undefined) {
//         const { deployments, abi, args } = purchaseHookParams.deploy
//         const deployParams = { slicerId, args }
//         const hookAbi =
//           abi[clonePurchaseHook ? "clonerInterface" : "factoryInterface"]

//         const factoryAddress =
//           deployments[clonePurchaseHook ? "cloner" : "factory"][chainId]

//         const hash = await writeContract(config, {
//           address: factoryAddress as `0x${string}`,
//           // @ts-ignore
//           abi: hookAbi,
//           functionName: clonePurchaseHook ? "clone" : "deploy",
//           args: [productsModuleAddress, slicerId, ...args]
//         })

//         if (hash) {
//           addRecentTransaction({
//             hash,
//             description: "Deploy purchase hook"
//           })
//           const waitData = await waitForTransactionHash(config, { hash })

//           let hookAddress: string

//           const filteredLogs = waitData.logs.filter(
//             (log: Log) => log.address == factoryAddress.toLowerCase()
//           )

//           // // TODO: Find the right log in a better way
//           // if (clonePurchaseHook) {
//           //   // TODO: Check it works with clones
//           //   hookAddress = filteredLogs[1].address
//           // } else {
//           //   const topics = decodeEventLog({
//           //     abi: parseAbi(["event ContractCreated(address)"]),
//           //     data: filteredLogs[0].data,
//           //     topics: filteredLogs[0].topics
//           //   })
//           //   hookAddress = topics.args[0]
//           // }

//           externalCall.externalAddress = hookAddress
//           setCloneAddress(hookAddress)
//         } else {
//           throw new Error()
//         }
//       }
//       setUploadStep(7)
//       // Create product on smart contract

//       const weiValue = ethToWei(ethValue || 0)

//       const productPrice =
//         isUSD || productCurrency == availableCurrencies[1].value
//           ? BigNumber.from(Math.floor(usdValue * 1000000))
//           : weiValue
//       const isStrategyConfigurable = priceParams?.abi != undefined
//       const currencyPrices =
//         Number(productPrice) != 0 || priceParams?.address
//           ? [
//               {
//                 currency: productCurrency,
//                 value: productPrice,
//                 dynamicPricing: isUSD,
//                 externalAddress:
//                   priceParams?.address || ethers.constants.AddressZero
//               }
//             ]
//           : []

//       const productParams: ProductParamsStruct = {
//         isFree: priceParams?.address ? false : isFree,
//         maxUnitsPerBuyer: maxUnits,
//         isInfinite: !isLimited,
//         availableUnits: units,
//         data,
//         purchaseData,
//         subSlicerProducts: [],
//         currencyPrices,
//         isExternalCallPaymentRelative: true,
//         isExternalCallPreferredToken:
//           productCurrency != availableCurrencies[0].value
//       }

//       try {
//         const hash = await writeContract(config, {
//           address: productsModuleAddress as `0x${string}`,
//           abi: ProductsModule.abi,
//           functionName: "addProduct",
//           args: [slicerId, productParams, externalCall]
//         })
//         addRecentTransaction({
//           hash,
//           description: `Create product | Slicer #${slicerId}`
//         })
//         const waitData = await waitForTransaction({ hash })
//         const { productsModule } = await import("@lib/initProvider")
//         const contract = productsModule(signer)
//         const eventLogs = await handleLog(contract, waitData)

//         if (eventLogs) {
//           // track("create_product_success")
//           setLogs(eventLogs)

//           if (!isStrategyConfigurable) {
//             setUploadStep(11)

//             setTimeout(() => {
//               setUploadStep(12)
//             }, 3000)
//           }

//           await handleSuccess(
//             signer as unknown as Signer,
//             slicerId,
//             idPath,
//             newProduct.id,
//             eventLogs,
//             priceParams,
//             setUploadStep
//           )
//           setSuccess(true)
//           setModalView({ name: "" })
//         } else {
//           throw new Error()
//         }
//       } catch (error) {
//         console.log(error)

//         const message = error.data?.message
//           ?.split("reverted with reason string '")[1]
//           ?.slice(0, -1)
//         handleMessage({ message, messageStatus: "error" }, setMessage)
//       }
//     } catch (err) {
//       console.log(err)

//       // track("create_product_fail")
//       setUploadStep(9)

//       await handleReject(slicerId, image, data, purchaseDataCID, newProduct.id)
//       setUploadStep(10)
//       setCloneAddress("")
//       if (err.message) {
//         setMessage({
//           message: err.message,
//           messageStatus: "error"
//         })
//       }
//     }
//   }

//   useEffect(() => {
//     setIsFree(ethValue || usdValue ? false : true)
//   }, [ethValue, usdValue])

//   useEffect(() => {
//     setMaxUnits(isMultiple ? 0 : 1)
//   }, [isMultiple])

//   return (
//     <form
//       className="w-full mx-auto"
//       onSubmit={progressStepIndex < steps.length - 1 ? handleNext : submit}
//     >
//       <div className="flex justify-center pb-6">
//         <AddProductProgress
//           name={name}
//           description={description}
//           steps={steps}
//           priceParams={priceParams}
//           progressStep={progressStep}
//           setSteps={setSteps}
//           setProgressStep={setProgressStep}
//         />
//       </div>
//       <div className="max-w-xl pt-4 mx-auto space-y-6">
//         {progressStep == "General" && (
//           <>
//             <div className="pb-10">
//               <h1 className="pb-6">General</h1>
//               <p className="text-lg text-gray-600">
//                 Choose the name, description, and image for your product
//               </p>
//             </div>
//             <AddProductFormGeneral
//               slicerId={slicerId}
//               newImage={newImage}
//               setNewImage={setNewImage}
//               name={name}
//               shortDescription={shortDescription}
//               description={description}
//               setName={setName}
//               setDescription={setDescription}
//               setShortDescription={setShortDescription}
//             />
//           </>
//         )}
//         {progressStep == "Availability" && (
//           <>
//             <div className="pb-10">
//               <h1 className="pb-6">Availability</h1>
//               <p className="text-lg text-gray-600">
//                 Set product availability and max units for each buyer
//               </p>
//             </div>
//             <AddProductFormAvailability
//               isMultiple={isMultiple}
//               isLimited={isLimited}
//               units={units}
//               maxUnits={maxUnits}
//               priceParams={priceParams}
//               setIsMultiple={setIsMultiple}
//               setIsLimited={setIsLimited}
//               setUnits={setUnits}
//               setMaxUnits={setMaxUnits}
//               setPriceParams={setPriceParams}
//             />
//           </>
//         )}
//         {progressStep == "Pricing" && (
//           <>
//             <div className="pb-10">
//               <h1 className="pb-6">Pricing</h1>
//               <p className="text-lg text-gray-600">
//                 Set up the product&apos;s pricing strategy
//               </p>
//             </div>
//             <AddProductFormPrice
//               isFree={isFree}
//               ethValue={ethValue}
//               usdValue={usdValue}
//               isUSD={isUSD}
//               units={units}
//               priceStrategy={priceStrategy}
//               setEthValue={setEthValue}
//               setUsdValue={setUsdValue}
//               setIsUSD={setIsUSD}
//               priceParams={priceParams}
//               setPriceParams={setPriceParams}
//               setPriceStrategy={setPriceStrategy}
//               productCurrency={productCurrency}
//               setProductCurrency={setProductCurrency}
//             />
//           </>
//         )}
//         {progressStep == "Onchain action" && (
//           <AddProductFormExternal
//             slicerId={slicerId}
//             currency={productCurrency}
//             ethProductPrice={ethValue}
//             usdProductPrice={usdValue}
//             clonePurchaseHook={clonePurchaseHook}
//             setClonePurchaseHook={setClonePurchaseHook}
//             params={purchaseHookParams}
//             setParams={setPurchaseHookParams}
//             selectedHook={selectedHook}
//             setSelectedHook={setSelectedHook}
//           />
//         )}
//         {progressStep == "Purchase view" && (
//           <AddProductFormPurchases
//             slicerId={slicerId}
//             instructions={instructions}
//             setInstructions={setInstructions}
//             customShortcodes={customShortcodes}
//             setCustomShortcodes={setCustomShortcodes}
//             redeemButton={redeemButton}
//             setRedeemButton={setRedeemButton}
//           />
//         )}
//         {progressStep == "Notes & files" && (
//           <AddProductFormFiles
//             notes={notes}
//             setNotes={setNotes}
//             files={files}
//             setFiles={setFiles}
//           />
//         )}
//         {progressStep == "Review" && (
//           <AddProductFormPreview
//             slicerId={slicerId}
//             name={name}
//             shortDescription={shortDescription}
//             description={description}
//             newImage={newImage}
//             maxUnits={Number(maxUnits)}
//             isLimited={isLimited}
//             units={Number(units)}
//             ethValue={ethValue}
//             usdValue={Math.round(usdValue * 100) / 100}
//             isUSD={isUSD}
//             thankMessage={thankMessage}
//             instructions={instructions}
//             notes={notes}
//             files={files}
//             setModalView={setModalView}
//             externalCallValue={externalCall?.value}
//             extAddress={externalCall?.externalAddress}
//             extCheckSig={externalCall?.checkFunctionSignature}
//             extExecSig={externalCall?.execFunctionSignature}
//             externalAddress={priceParams?.address}
//             currency={productCurrency}
//             redeemButton={redeemButton}
//             customPrice={
//               (priceParams?.label?.includes("VRGDA") &&
//                 priceParams?.args[1][0][0]) ||
//               ((priceParams?.label === strategies.NFTDiscount.label ||
//                 priceParams?.label === strategies.KeysDiscount.label) &&
//                 priceParams?.args[0][0][1])
//             }
//           />
//         )}
//         <div className="pt-12 pb-4">
//           <Button
//             label={
//               progressStepIndex < steps.length - 1 ? "Next" : "Create product"
//             }
//             type={progressStepIndex < steps.length - 1 ? "submit" : "button"}
//             onClick={() => {
//               progressStepIndex == steps.length - 1 &&
//                 (steps.filter((step) => step.status == "error").length > 0
//                   ? handleSetMessageError()
//                   : setModalView({
//                       cross: true,
//                       name: "CREATE_PRODUCT_CONFIRM_VIEW",
//                       params: { submitEl, uploadStep, setModalView }
//                     }))
//             }}
//           />
//           <button className="hidden" ref={submitEl} type="submit" />
//         </div>
//         <div className="pb-3">
//           <MessageBlock msg={message} />
//         </div>
//       </div>
//     </form>
//   )
// }

// export default AddProductForm

// // Todo: What else to add to data and purchaseData (on pinata and web3storage)
// // tags?

export default {}
