import Trash from "@components/icons/Trash"
import { useState } from "react"
import Spinner from "@components/icons/Spinner"
import { useAccount, useConfig, useWriteContract } from "wagmi"
import { useRouter } from "next/router"
import { constants } from "@lib/constants"
import ProductsModuleContract from "artifacts/contracts/ProductsModule.sol/ProductsModule.json"
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit"
import { track } from "@vercel/analytics"
import fetcher from "@utils/fetcher"
import executeTransaction from "@utils/executeTransaction"
import { Chain } from "viem"
import { base } from "viem/chains"

type Props = {
  slicerId: number
  productId: number
}

const DeleteButton = ({ slicerId, productId }: Props) => {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const { address: account } = useAccount()
  const config = useConfig()

  const addRecentTransaction = useAddRecentTransaction()
  const { writeContractAsync } = useWriteContract()

  const settlementLogic = async () => {
    // track("remove_product_success")
    await fetcher(`/api/slicer/${slicerId}/product/${productId}/remove`, {
      method: "DELETE"
    })
    router.reload()
  }

  const deleteProduct = async () => {
    // track("remove_product_attempt")

    await executeTransaction(
      config,
      async () =>
        await writeContractAsync({
          address:
            constants[process.env.NEXT_PUBLIC_CHAIN_ID][
              process.env.NEXT_PUBLIC_ENVIRONMENT
            ].addresses.ProductsModule,
          abi: ProductsModuleContract.abi,
          functionName: "removeProduct",
          args: [slicerId, productId],
          account,
          chain: base as Chain
        }),
      setLoading,
      `Remove product ${productId} | Slicer #${slicerId}`,
      addRecentTransaction,
      async () => await settlementLogic()
    )
  }

  return (
    <div
      className="relative z-10 flex items-center justify-center w-full py-2 text-center text-white transition-colors duration-150 bg-red-500 rounded-md cursor-pointer group-cart hover:bg-red-600 nightwind-prevent"
      onClick={async () => await deleteProduct()}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <p className="mr-2 text-sm font-medium">Delete</p>
          <Trash className="w-5 h-5 group-cart-el" />
        </>
      )}
    </div>
  )
}

export default DeleteButton
