import { Input, NoteText } from "@components/ui"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { defaultExternalCall, Hook, HookProps } from "../purchaseHooks"

import { gql } from "@apollo/client"
import client from "@utils/apollo-client"

import clonerInterface from "./abi/cloner.json"
import factoryInterface from "./abi/factory.json"
import deployments from "./deployments.json"
import { formatEther, parseEther } from "viem"
import { useAccount } from "wagmi"

const label = "Mint token (ERC20)"

const description = "Mint ERC20 tokens for each unit purchased."

const Component = ({
  params,
  setParams,
  slicerId
}: HookProps & { slicerId: number }) => {
  const { address: account } = useAccount()
  const [
    initName,
    initSymbol,
    initTokensPerUnit,
    initMaxSupply,
    ,
    initPremintAmount,
    initProductId
  ] = params?.deploy?.args || []
  const [name, setName] = useState(initName || "")
  const [symbol, setSymbol] = useState(initSymbol || "")
  const [tokensPerUnit, setTokensPerUnit] = useState(
    initTokensPerUnit ? formatEther(BigInt(initTokensPerUnit)) : 1000
  )
  const [maxSupply, setMaxSupply] = useState(
    initMaxSupply ? formatEther(BigInt(initMaxSupply)) : ""
  )
  const [premintAmount, setPremintAmount] = useState(
    initPremintAmount ? formatEther(BigInt(initPremintAmount)) : 0
  )
  const [productId, setProductId] = useState(initProductId || 1)

  useEffect(() => {
    getNextProductId({ slicerId, setProductId })
  }, [])

  useEffect(() => {
    setParams({
      externalCall: {
        ...defaultExternalCall
      },
      deploy: {
        deployments,
        abi: {
          clonerInterface: clonerInterface.abi,
          factoryInterface: factoryInterface.abi
        },
        args: [
          name,
          symbol,
          parseEther(String(tokensPerUnit || "0")),
          parseEther(String(maxSupply || "0")),
          account,
          parseEther(String(premintAmount || "0")),
          productId
        ]
      }
    })
  }, [
    name,
    symbol,
    tokensPerUnit,
    maxSupply,
    productId,
    account,
    premintAmount,
    setParams
  ])

  return (
    <>
      <div>
        <Input
          label="Token name"
          value={name}
          onChange={setName}
          required
          placeholder="My Coin"
        />
      </div>
      <div>
        <Input
          label="Symbol"
          value={symbol}
          onChange={setSymbol}
          required
          placeholder="COIN"
        />
      </div>
      <div>
        <Input
          type="number"
          label="Tokens per unit sold"
          min={1}
          value={tokensPerUnit}
          onChange={setTokensPerUnit}
          required
        />
      </div>
      <div>
        <Input
          type="number"
          label="Max supply"
          helpText="Leave empty to allow mints as long as product units are available."
          min={0}
          placeholder="Unlimited"
          value={maxSupply}
          onChange={setMaxSupply}
        />
      </div>
      <div>
        <Input
          label="Premint Amount"
          helpText='Premint tokens are minted to your address. Use "0" to disable.'
          value={premintAmount}
          onChange={setPremintAmount}
          required
        />
      </div>
      <div className="text-left pt-4">
        <NoteText text="Mints will be possible as long as the product is available for purchase. New mints can be prevented by deleting the product." />
      </div>
    </>
  )
}

const hook: Hook = { label, description, Component, deployments }

export default hook

async function getNextProductId({
  slicerId,
  setProductId
}: {
  slicerId: number
  setProductId: Dispatch<SetStateAction<number>>
}) {
  const tokensQuery = /* GraphQL */ `
    query SlicerQuery($hexSlicerId: ID!) {
      slicer(id: $hexSlicerId) {
        products(first: 1, orderBy: createdAtTimestamp, orderDirection: desc) {
          id
        }
      }
    }
  `
  const hexSlicerId = "0x" + slicerId.toString(16)
  const { data } = await client.query({
    query: gql(tokensQuery),
    variables: { hexSlicerId }
  })

  const lastProductId = data.slicer.products[0]?.id.split("-")[1]
  if (lastProductId) setProductId(Number(lastProductId) + 1)
}
