import { useAccount, useEnsAvatar } from "wagmi"
import sliceLogo from "public/slice_logo.png"
import { cn } from "@/lib/utils"
import Image from "next/image"
import { useReverseENSLookUp } from "@lib/useReverseENSLookUp"
import { normalize } from "viem/ens"

export const ENSAvatar = ({ className }: { className?: string }) => {
  const { address } = useAccount()
  const ensName = useReverseENSLookUp(address || "")
  const { data: avatar } = useEnsAvatar({
    scopeKey: ensName,
    assetGatewayUrls: {
      ipfs: "https://slice.mypinata.cloud"
    },
    name: ensName && normalize(ensName),
    chainId: 1
  })

  return (
    <Image
      width={24}
      height={24}
      src={avatar || sliceLogo}
      alt={"Store logo"}
      className={cn("rounded-full", className)}
    />
  )
}
