import { lookupAddress } from "@utils/lookupAddress"
import { useEffect, useState } from "react"
import { usePublicClient } from "wagmi"

export const useReverseENSLookUp = (address: string | `0x${string}`) => {
  const client = usePublicClient({ chainId: 1 })
  const [name, setName] = useState<string>()

  useEffect(() => {
    if (address && client) {
      lookupAddress(client, address)
        .then((name: string | null) => {
          if (!name) return
          setName(name)
        })
        .catch((error: any) => {
          console.log(`error resolving reverse ens lookup: `, error)
        })
    }
    return () => {
      setName("")
    }
  }, [address, client])

  return name
}
