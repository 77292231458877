import { ProductCart } from "@lib/handleUpdateCart"
import { PurchaseListCard } from ".."

type Props = {
  cart: ProductCart[]
}

const PurchasesConfirmationList = ({ cart }: Props) => {
  return (
    <div className="relative mb-6">
      <div className="space-y-6 max-h-[300px] sm:max-h-[450px] overflow-y-scroll">
        {cart?.map((product, key) => {
          const {
            slicerId,
            productId,
            quantity,
            name,
            dbId,
            image,
            uid,
            creator,
            texts,
            shortcodes,
            redeemButton
          } = product

          return (
            <PurchaseListCard
              key={key}
              productName={name}
              quantity={quantity}
              slicerId={slicerId}
              productId={productId}
              dbId={dbId}
              image={image}
              uid={uid}
              creator={creator}
              texts={texts}
              shortcodes={shortcodes}
              redeemButton={redeemButton}
            />
          )
        })}
        <div className="h-2" />
      </div>
      <div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-b from-transparent to-white" />
    </div>
  )
}

export default PurchasesConfirmationList
