import { Input, InputAddress, InputPrice } from "@components/ui"
import useEthUsd from "@utils/useEthUsd"
import { ethers, BigNumber } from "ethers"
import { useEffect, useState } from "react"
import { Hook, HookProps } from "../purchaseHooks"
import { availableCurrencies } from "@components/ui/AddProductFormPrice/AddProductFormPrice"
import formatNumber from "@utils/formatNumber"

type Props = HookProps & {
  currency: string
  ethProductPrice: number
  usdProductPrice: number
}

const label = "Split income with address"

const description = "Send ETH or USDC to an address upon purchase"

const Component = ({
  params,
  currency,
  ethProductPrice,
  usdProductPrice,
  setParams
}: Props) => {
  // const ethUsd = useEthUsd()

  const paramsExternalAddress = params?.externalCall?.externalAddress
  const paramsValue = params?.externalCall?.value
  const initAddress =
    (paramsExternalAddress &&
      paramsExternalAddress != "0x00000000" &&
      paramsExternalAddress != ethers.constants.AddressZero &&
      paramsExternalAddress) ||
    ""
  const initPercentageValue = paramsValue ? Number(paramsValue) / 100 : 0
  const productPrice =
    currency == availableCurrencies[0].value ? ethProductPrice : usdProductPrice
  // const initValue = paramsValue
  //   ? Number(BigNumber.from(paramsValue).div(BigNumber.from(10).pow(10))) / 1e8
  //   : 0

  // const [usdValue, setUsdValue] = useState(Number(initValue) * Number(ethUsd))
  // const [ethValue, setEthValue] = useState(initValue)
  const [address, setAddress] = useState(initAddress)
  const [resolvedAddress, setResolvedAddress] = useState("")
  const [percentageValue, setPercentageValue] = useState(initPercentageValue)

  const currencySymbol =
    currency == availableCurrencies[0].value ? "ETH" : "USDC"
  // const isPrimaryUsd = currency == availableCurrencies[1].value

  useEffect(() => {
    const externalAddress = address
      ? ![".eth", "⌐◨-◨"].includes(address.substring(address.length - 4))
        ? resolvedAddress != "Invalid ENS or NNS name"
          ? address
          : ethers.constants.AddressZero
        : resolvedAddress
      : ethers.constants.AddressZero
    // const decimals = BigNumber.from(10).pow(9)
    // const ethToWei = BigNumber.from((ethValue * 10 ** 9).toFixed(0)).mul(
    //   decimals
    // )

    // TODO: IF extAddress == addressZero, set params empty?

    const cleanedPercentageValue = percentageValue > 100 ? 100 : percentageValue
    setParams({
      externalCall: {
        data: "",
        value: cleanedPercentageValue * 100,
        externalAddress,
        checkFunctionSignature: "0x00000000",
        execFunctionSignature: "0x00000000"
      }
    })
  }, [percentageValue, address, resolvedAddress])

  return (
    <>
      <div>
        <InputAddress
          label="Receiver address*"
          address={address as `0x${string}`}
          onChange={setAddress}
          required
          resolvedAddress={resolvedAddress}
          setResolvedAddress={setResolvedAddress}
        />
      </div>
      <div className="py-3">
        <Input
          label="Percentage sent to receiver*"
          type="number"
          placeholder="10"
          max={100}
          step={0.01}
          value={percentageValue}
          onChange={setPercentageValue}
          error={percentageValue > 100}
        />
        <p className="text-sm text-left text-gray-600">
          Total price:{" "}
          <b>
            {currencySymbol} {formatNumber(Number(productPrice || 0))}
          </b>
        </p>
        <p className="pt-1 text-sm text-left text-gray-600">
          Sent to receiver:{" "}
          <b>
            {currencySymbol}{" "}
            {productPrice
              ? formatNumber(
                  Math.round(
                    Number(productPrice) * Number(percentageValue) * 10
                  ) / 1000
                )
              : 0}
          </b>
        </p>
      </div>
    </>
  )
}

const hook: Hook = { label, description, Component }

export default hook
