import React, { InputHTMLAttributes } from "react"
import { Question } from ".."

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: string
  helpText?: string
  after?: string
  error?: boolean
  loading?: boolean
  inverted?: boolean
  submit?: boolean
  question?: JSX.Element
  questionPosition?: string
  onChange?: (...args: any[]) => any
  handleOnChangeCustom?: (...args: any[]) => any
}

const InputRange: React.FC<Props> = (props) => {
  const {
    className,
    label,
    helpText,
    after,
    children,
    error,
    loading,
    disabled,
    inverted,
    submit,
    question,
    questionPosition = "bottom-[20px] left-[-40px] xs:left-0",
    onChange,
    handleOnChangeCustom,
    ...rest
  } = props

  const rootClassName = `peer h-0.5 px-2 w-full appearance-none rounded-t-sm ${
    !error ? "shadow-light-focusable" : ""
  } ease-in-out focus:outline-none ${className} ${
    error
      ? "text-red-500 bg-red-400 bg-white focus:bg-red-400 shadow-error"
      : inverted
      ? "text-white bg-blue-600 focus:bg-sky-300 placeholder-gray-500 disabled:text-gray-400 disabled:bg-blue-800 disabled:bg-gray-900"
      : "text-black bg-blue-300 focus:bg-sky-600 placeholder-gray-400 disabled:text-gray-500 disabled:bg-blue-100 disabled:bg-gray-50"
  }`

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value)
    }
    return null
  }

  return (
    <label className="text-left">
      {label && (
        <>
          <div className="pb-2">
            <div className="relative flex items-center">
              <p
                className={`text-sm font-semibold pr-1 ${
                  inverted ? "text-gray-200" : "text-gray-700"
                }`}
              >
                {label}
              </p>
              {question && (
                <Question position={questionPosition} text={question} />
              )}
            </div>
            {helpText && (
              <p className="pb-2 text-sm text-gray-600">{helpText}</p>
            )}
          </div>
        </>
      )}
      <div className="flex flex-row-reverse mb-3 mt-2 rounded-t-sm">
        <input
          type="range"
          className={rootClassName}
          onChange={
            handleOnChangeCustom
              ? (e: any) => handleOnChangeCustom(e)
              : handleOnChange
          }
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          disabled={disabled || loading}
          onWheel={(e) => e.currentTarget.blur()}
          {...rest}
        ></input>
      </div>
    </label>
  )
}

export default InputRange
