const CarouselGames = (color: string) => {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 1024 918"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M143.816 417.539C195.982 619.297 455.148 834.672 578.21 917.14C701.272 834.672 960.437 619.297 1012.6 417.539C1077.81 165.341 843.831 106.847 800.68 106.847C757.528 106.847 653.006 117.395 578.21 198.904C503.414 117.395 398.891 106.847 355.739 106.847C312.587 106.847 78.6091 165.341 143.816 417.539Z"
        fill="currentColor"
        className={color}
      />
      <path
        d="M35.3971 334.692C87.5627 536.45 346.729 751.825 469.791 834.293C592.853 751.825 852.018 536.45 904.184 334.692C969.391 82.4945 735.413 24 692.261 24C649.109 24 544.587 34.5482 469.791 116.057C394.995 34.5482 290.472 24 247.32 24C204.168 24 -29.8099 82.4945 35.3971 334.692Z"
        fill="black"
        stroke="white"
        strokeWidth="47"
      />
    </svg>
  )
}

export default CarouselGames
