import dynamic from "next/dynamic"
import Link from "next/link"
import Logo from "@components/icons/Logo"
import Nightwind from "@components/icons/Nightwind"
import { Container, SliceConnectButton } from "@components/ui"
import { useAppContext } from "@components/ui/context"
import { useEffect, useRef, useState } from "react"
import { useAccount } from "wagmi"
import useQuery from "@utils/subgraphQuery"
import { useReverseENSLookUp } from "@lib/useReverseENSLookUp"
import { useAccountModal } from "@rainbow-me/rainbowkit"
import { cn } from "@/lib/utils"
import { Button } from "@components/base/button"
import { ENSAvatar } from "../ENSAvatar"
import shortenAddress from "@utils/shortenAddress"
import { BlackCreateWalletButton } from "../BlackCreateWalletButton/BlackCreateWalletButton"
import { Menu } from "lucide-react"

const DropdownMenu = dynamic(() => import("@components/ui/DropdownMenu"), {
  ssr: false
})

const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef(null)

  const { address, connector } = useAccount()
  const ensName = useReverseENSLookUp(address)
  const { openAccountModal } = useAccountModal()

  const { account } = useAppContext()
  const tokensQuery = /* GraphQL */ `
  payee(id: "${account?.toLowerCase()}") {
    slicers (
      where: {slices_gt: "0"}
    ) {
      slices
      slicer {
        id
        minimumSlices
      }
    }
  }
`
  let subgraphData = useQuery(tokensQuery, [account])
  const slicers = subgraphData?.payee?.slicers || []
  const ownedSlicers = slicers?.filter(
    (slicer: any) => Number(slicer.slices) > Number(slicer.slicer.minimumSlices)
  )

  useEffect(() => {
    function handleClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClick)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClick)
    }
  }, [dropdownRef])

  return (
    <header className="shadow-sm">
      <Container>
        <nav className="relative flex items-center justify-between h-[72px] px-3 mx-auto sm:px-6">
          <div className="z-10 flex items-center space-x-6 sm:space-x-8">
            <Link href="/" aria-label="Slice logo">
              <Logo className="w-6 h-6" />
            </Link>
            <Link href="/slicer">
              <p className="text-[0.925rem] font-medium">Explore</p>
            </Link>
          </div>
          <div className="relative z-10 flex items-center space-x-6">
            <div>
              <Nightwind size="h-[20px]" />
            </div>
            {connector ? (
              <div className="flex items-center gap-4">
                <Button
                  variant="outline"
                  className={cn("font-semibold px-2 sm:px-4")}
                  onClick={openAccountModal}
                >
                  <ENSAvatar className="w-6 h-6" />
                  <p className="text-xs sm:text-sm">
                    {ensName || shortenAddress(address, "...")}{" "}
                  </p>
                </Button>

                <a
                  className="text-gray-600"
                  onClick={() =>
                    setShowDropdown((showDropdown) => !showDropdown)
                  }
                  ref={dropdownRef}
                >
                  <Menu />
                </a>
              </div>
            ) : (
              <>
                <div className="hidden sm:block">
                  <BlackCreateWalletButton />
                </div>
                <SliceConnectButton />
              </>
            )}
          </div>
          {showDropdown && (
            <div className="absolute top-0 right-0" ref={dropdownRef}>
              <DropdownMenu
                setShowDropdown={setShowDropdown}
                ownedSlicers={ownedSlicers}
              />
            </div>
          )}
        </nav>
      </Container>
    </header>
  )
}

export default Navbar
