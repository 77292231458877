const CarouselGames = (color: string) => {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 1000 667"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M918.889 174.488C799.631 71.147 650.844 84.0035 650.844 84.0035C549.897 84.0037 564.495 83.97 549.897 84.0037C535.3 84.0205 549.897 84.0882 445.545 84.0877C445.545 84.0877 296.74 71.1975 177.466 174.538C58.2257 370.405 106.279 583.714 106.279 583.714C114.697 635.943 142.445 670.796 194.808 666.418C247.02 662.057 339.775 525.761 339.775 525.761C349.136 514.53 368.751 505.354 383.316 505.354L712.955 505.304C727.536 505.304 747.134 514.48 756.479 525.71C756.479 525.71 849.25 662.007 901.546 666.368C953.843 670.745 981.658 635.859 990.026 583.664C990.009 583.664 1038.15 370.388 918.889 174.488Z"
          fill="currentColor"
          className={color}
        />
        <path
          d="M453.214 21.0105L453.226 21.0105L453.238 21.0105C456.177 21.0037 457.925 20.9996 459.674 20.9975C466.398 20.9893 473.121 21.0093 547.513 21.0103C551.582 21.0104 555.723 20.8581 559.375 20.7237C560.072 20.6981 560.751 20.6731 561.409 20.65C587.913 19.717 696.343 20.938 791.052 92.3163C801.053 99.8538 809.408 109.76 815.654 121.162C862.926 207.453 878.332 295.903 881.326 365.514C882.747 398.525 876.474 451.829 861.464 495.582C853.946 517.497 844.782 535.375 834.599 547.063C824.685 558.442 815.559 562.199 806.59 561.448L806.584 561.447C801.531 561.026 792.27 557.068 779.02 546.823C766.491 537.134 752.984 523.885 739.792 509.378C713.468 480.428 690.244 448.529 681.103 435.595C680.96 435.393 680.812 435.182 680.657 434.963C678.101 431.334 673.946 425.434 668.201 420.546C654.811 409.155 634.324 400.31 616.247 400.31H616.244L286.608 400.361C286.607 400.361 286.605 400.361 286.604 400.361C268.568 400.362 248.118 409.18 234.728 420.526C228.917 425.45 224.725 431.401 222.141 435.071C221.987 435.29 221.839 435.5 221.696 435.702C212.523 448.681 189.307 480.568 163.012 509.493C149.835 523.988 136.347 537.222 123.839 546.899C110.61 557.133 101.375 561.078 96.3525 561.498L96.3509 561.498C76.2133 563.182 62.7735 557.625 53.2158 548.448C43.0387 538.677 35.1862 522.76 31.0177 500.486L30.772 499.172C30.2841 496.564 29.7983 493.966 29.2869 491.291C23.7399 462.268 -4.016 287.87 87.2823 121.216C93.5297 109.812 101.886 99.9046 111.89 92.3667C206.592 21.0076 315.007 19.7943 341.543 20.732C342.198 20.7552 342.875 20.7802 343.569 20.8058C347.237 20.9411 351.396 21.0946 355.48 21.0945C429.04 21.0927 439.075 21.0548 445.801 21.0294C447.953 21.0213 449.767 21.0145 453.214 21.0105Z"
          fill="#010002"
          stroke="white"
          strokeWidth="42"
        />
        <rect
          x="220.122"
          y="116.696"
          width="51.1242"
          height="171.811"
          rx="15"
          fill="white"
        />
        <rect
          x="331.589"
          y="177.039"
          width="51.1242"
          height="171.811"
          rx="15"
          transform="rotate(90 331.589 177.039)"
          fill="white"
        />
        <ellipse
          cx="605.388"
          cy="202.836"
          rx="29.2567"
          ry="29.2567"
          fill="white"
        />
        <ellipse
          cx="734.118"
          cy="203.811"
          rx="29.2567"
          ry="29.2567"
          fill="white"
        />
        <ellipse
          cx="669.753"
          cy="267.2"
          rx="29.2567"
          ry="29.2567"
          fill="white"
        />
        <ellipse
          cx="669.753"
          cy="138.471"
          rx="29.2567"
          ry="29.2567"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="1000" height="667" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CarouselGames
