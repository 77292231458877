import * as React from "react"
import { useWalletClient } from "wagmi"
import { providers } from "ethers"
import { usePublicClient } from "wagmi"
import { PublicClient, WalletClient, type HttpTransport } from "viem"

export function walletClientToSigner(
  walletClient: WalletClient,
  chainId?: number
) {
  const { account, chain, transport } = walletClient

  const network = {
    chainId: chain?.id || chainId,
    name: chain?.name,
    ensAddress: chain?.contracts?.ensRegistry?.address
  }
  // @ts-ignore
  const provider = new providers.Web3Provider(transport, network)
  const signer = provider.getSigner(account.address)
  return signer
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: walletClient } = useWalletClient({ chainId })
  return React.useMemo(
    () =>
      walletClient ? walletClientToSigner(walletClient, chainId) : undefined,
    [walletClient]
  )
}

export function publicClientToProvider(
  publicClient: PublicClient,
  ensAddress?: `0x${string}`
) {
  const { chain, transport } = publicClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: ensAddress || chain.contracts?.ensRegistry?.address
  }
  if (transport.type === "fallback")
    return new providers.FallbackProvider(
      (transport.transports as ReturnType<HttpTransport>[]).map(
        ({ value }) => new providers.JsonRpcProvider(value?.url, network)
      )
    )
  return new providers.JsonRpcProvider(transport.url, network)
}

/** Hook to convert a viem Public Client to an ethers.js Provider. */
export function useEthersProvider({ chainId }: { chainId?: number } = {}) {
  const publicClient = usePublicClient({ chainId })
  return React.useMemo(
    () => publicClientToProvider(publicClient),
    [publicClient]
  )
}
