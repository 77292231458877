const CoinbaseWalletLogo = ({ className = "" }) => {
  return (
    <svg
      className={className || "w-full h-full"}
      width="1000"
      height="1000"
      viewBox="0 0 1000 1000"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="500" cy="500" r="500" fill="#0052FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150 500C150 693.3 306.7 850 500 850C693.3 850 850 693.3 850 500C850 306.7 693.3 150 500 150C306.7 150 150 306.7 150 500ZM410.556 387.222C397.669 387.222 387.222 397.669 387.222 410.556V589.444C387.222 602.331 397.669 612.778 410.556 612.778H589.444C602.331 612.778 612.778 602.331 612.778 589.444V410.556C612.778 397.669 602.331 387.222 589.444 387.222H410.556Z"
        fill="white"
      />
    </svg>
  )
}

export default CoinbaseWalletLogo
