import { Input } from "@components/ui"
import useEthUsd from "@utils/useEthUsd"
import { Dispatch, SetStateAction, useEffect, useState } from "react"

type Props = {
  ethValue: number
  usdValue: number
  setEthValue: Dispatch<SetStateAction<number>>
  setUsdValue: Dispatch<SetStateAction<number>>
  isPrimaryUsd?: boolean
  isUSD?: boolean
  loading?: boolean
  error?: boolean
  required?: boolean
  disabled?: boolean
  label?: string
  actionLabel?: string
  marginLabel?: string
  question?: JSX.Element
  action?: () => any
}

const InputPrice = ({
  ethValue,
  usdValue,
  setEthValue,
  setUsdValue,
  isPrimaryUsd,
  isUSD,
  loading,
  error,
  required,
  disabled,
  label,
  actionLabel,
  marginLabel,
  question,
  action
}: Props) => {
  const ethUsd = useEthUsd()

  const [isEth, setIsEth] = useState(!isPrimaryUsd)

  const currency = isEth ? "Ξ" : "$"
  const destinationCurrency = isEth ? "$" : "Ξ"

  const handleChange = (value: BigInt) => {
    if (isEth) {
      setEthValue(value && Number(value))
      if (ethUsd) setUsdValue(Number(value) * Number(ethUsd))
    } else {
      setUsdValue(Number(value))
      if (ethUsd) setEthValue(Number(value) / Number(ethUsd))
    }
  }

  useEffect(() => {
    if (isEth) {
      setEthValue(Math.floor(ethValue * 10000) / 10000)
    } else {
      setUsdValue(Math.floor(usdValue * 100) / 100)
    }
  }, [isEth])

  useEffect(() => {
    if (isUSD) {
      setIsEth(false)
    } else {
      setIsEth(true)
    }
  }, [isUSD])

  useEffect(() => {
    setIsEth(!isPrimaryUsd)
  }, [isPrimaryUsd])

  return (
    <div className="w-full mx-auto space-y-6">
      <div className="relative">
        <Input
          type="number"
          placeholder={isEth ? "0.1" : "100"}
          min={0}
          step={isEth ? 0.0001 : 0.01}
          label={label}
          prefix={currency}
          prefixAction={() =>
            ethUsd && !isPrimaryUsd && setIsEth((isEth) => !isEth)
          }
          value={isEth ? ethValue : usdValue}
          onChange={handleChange}
          disabled={disabled}
          error={error}
          required={required}
          question={question}
          onClick={action && (() => action())}
          onClickLabel={action && actionLabel}
          loading={action && loading}
        />
        {ethUsd && ethValue != 0 && (
          <div
            className={`absolute bottom-0 right-0 flex items-center pb-3 ${
              marginLabel || "mr-8"
            }`}
          >
            <p className="text-sm text-gray-600">
              {destinationCurrency}{" "}
              {isEth
                ? usdValue
                  ? Math.floor(usdValue * 100) / 100
                  : 0
                : ethValue
                ? Math.floor(ethValue * 10000) / 10000
                : 0}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default InputPrice
