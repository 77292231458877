import "react-day-picker/dist/style.css"
import { constants } from "@lib/constants"
import ProductsModuleContract from "artifacts/contracts/ProductsModule.sol/ProductsModule.json"
import Button from "../Button"
import { useState } from "react"
import executeTransaction from "@utils/executeTransaction"
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit"
import { useAccount, useConfig, useWriteContract } from "wagmi"
import { base, Chain } from "viem/chains"

export default function CloseStore({ storeClosed, setStoreClosed, slicerId }) {
  const [loading, setLoading] = useState(false)
  const config = useConfig()

  const addRecentTransaction = useAddRecentTransaction()
  const { address } = useAccount()
  const { writeContractAsync } = useWriteContract()

  return (
    <div className="ml-4 text-left">
      <h2 className="mb-2 text-lg font-semibold">Open and close store</h2>
      <p className="mb-4">
        Enable / disable all product purchases on this store.
      </p>
      <div>
        <div className="space-y-8 sm:space-y-0 sm:flex sm:gap-6">
          <Button
            label={storeClosed ? "Open store" : "Close store"}
            disabled={storeClosed == null}
            loading={storeClosed == null || loading}
            onClick={async () => {
              executeTransaction(
                config,
                async () =>
                  await writeContractAsync({
                    account: address,
                    address: constants[process.env.NEXT_PUBLIC_CHAIN_ID][
                      process.env.NEXT_PUBLIC_ENVIRONMENT
                    ].addresses.ProductsModule as `0x${string}`,
                    abi: ProductsModuleContract.abi,
                    functionName: "_setStoreConfig",
                    args: [slicerId, !storeClosed, 0],
                    chain: base as Chain
                  }),
                setLoading,
                storeClosed ? "Open store" : "Close store",
                addRecentTransaction,
                () => setStoreClosed(!storeClosed)
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}
