import { ProductCart } from "@lib/handleUpdateCart"

export function sendMessage(cart: ProductCart[], buyer: string) {
  const formattedBuyer =
    buyer.slice(0, 2) === "0x"
      ? buyer.slice(0, 6) + "..." + buyer.slice(-4)
      : buyer

  fetch("/api/send_base_messages", {
    method: "POST",
    body: JSON.stringify({ buyer: formattedBuyer, cart })
  })
}
