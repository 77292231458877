const Delete = ({ ...props }) => {
  const { disabled, onClick } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`text-black opacity-50 ${
        !disabled ? "cursor-pointer hover:text-red-500 hover:opacity-100" : ""
      }`}
      {...props}
      onClick={!disabled ? onClick : undefined}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="15" y1="9" x2="9" y2="15"></line>
      <line x1="9" y1="9" x2="15" y2="15"></line>
    </svg>
  )
}

export default Delete
