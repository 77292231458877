import Image, { StaticImageData } from "next/image"
import { useAppContext } from "../context"

type Props = {
  name: string
  description?: string
  type: string
  image: StaticImageData
  url: string
}

export default function HomeHeroCard({
  name,
  description,
  type,
  image,
  url
}: Props) {
  const { color2 } = useAppContext()

  return (
    <div
      key={name}
      // href={url}
      // target="_blank"
      // rel="noreferrer"
      className="relative w-full bg-black/70 rounded-md overflow-hidden" // duration-200 group hover:text-white hover:bg-black/0
    >
      <Image
        src={image}
        fill={true}
        alt={name}
        className="z-[-10] object-cover"
      />
      <div className="flex items-end h-24 px-4 pt-4 pb-3 text-left duration-200 group-hover:opacity-0 backdrop-blur-[2.5px]">
        <div>
          <p
            className={`absolute top-[15px] right-[20px] font-bold text-xs nightwind-prevent ${color2[2]}`}
          >
            {type}
          </p>
          <p className="font-bold text-white leading-7 nightwind-prevent">
            {name}
          </p>
          {description && (
            <p className="text-sm text-gray-400 nightwind-prevent">
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
