import { Button, DoubleText, LoadingStep } from "@components/ui"
import formatBlockscanUrl from "@utils/formatBlockscanUrl"

export const CREATE_PRODUCT_VIEW = (params: any) => {
  const { uploadStep, uploadPct, setModalView, cloneAddress, strategyLabel } =
    params
  const processing = uploadStep !== 10

  let uploadState: string
  switch (uploadStep) {
    case 1:
      uploadState = "Uploading image"
      break
    case 2:
      uploadState = "Saving metadata"
      break
    case 3:
      uploadState = "Encrypting files"
      break
    case 4:
      uploadState = `Uploading files: ${uploadPct < 100 ? uploadPct : 100}%`
      break
    case 5:
      uploadState = "Finishing setting up"
      break
    case 6:
      uploadState = "Deploying action ..."
      break
    case 7:
      uploadState = "Transaction in progress ..."
      break
    case 8:
      uploadState = `Configuring ${strategyLabel} ...`
      break
    case 9:
      uploadState = "Reverting"
      break
    case 10:
      uploadState = "Done, reverted!"
      break
    case 11:
      uploadState = "Finalizing"
      break
    case 12:
      uploadState = "Almost done"
      break

    default:
      uploadState = ""
  }

  return (
    <div className="text-center">
      <div className="pb-8 text-center">
        <DoubleText inactive logoText="Creating product" />
      </div>
      <div className="grid items-center grid-cols-6 gap-2 px-4">
        <LoadingStep
          initCondition={uploadStep < 3}
          uploadState={uploadState}
          endState="Done"
        />
        <LoadingStep
          nullCondition={uploadStep < 3}
          initCondition={uploadStep < 7}
          uploadState={uploadState}
          waitingState="File upload"
          endState={
            cloneAddress ? (
              <>
                Contract deployed!{" "}
                <a
                  href={formatBlockscanUrl(cloneAddress)}
                  target="_blank"
                  rel="noreferrer"
                  className="highlight"
                >
                  See on block explorer
                </a>
              </>
            ) : (
              "Done"
            )
          }
        />
        <LoadingStep
          nullCondition={uploadStep < 7}
          initCondition={strategyLabel ? uploadStep < 8 : processing}
          uploadState={uploadState}
          waitingState="Blockchain interaction"
          endState={strategyLabel && "Done"}
        />
        {strategyLabel && (
          <LoadingStep
            nullCondition={uploadStep < 8}
            initCondition={processing}
            uploadState={uploadState}
            waitingState="Pricing strategy"
          />
        )}
      </div>
      <div className="pt-10">
        {uploadStep === 10 ? (
          <Button
            label={"Go back to product"}
            onClick={() => setModalView({ name: "" })}
          />
        ) : (
          <p className="max-w-sm mx-auto text-sm font-bold text-yellow-600">
            Please wait until the process is completed
          </p>
        )}
      </div>
    </div>
  )
}

export default CREATE_PRODUCT_VIEW
