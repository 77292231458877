import Bolt from "@components/icons/Bolt"
import ShoppingBag from "@components/icons/ShoppingBag"
import Units from "@components/icons/Units"
import { ProductCart } from "@lib/handleUpdateCart"
import formatNumber from "@utils/formatNumber"
import { ethers, utils } from "ethers"
import { BlockchainProduct } from "pages/slicer/[id]"
import { useEffect, useState } from "react"
import { Card, CartButton, DeleteButton } from ".."
import { Purchase, useAppContext } from "../context"
import { ExternalPrices } from "../ProductsGrid/ProductsGrid"
import { Product } from "../SlicerProducts/SlicerProducts"
import { useAccount } from "wagmi"
// import { constants } from "@lib/constants"

type Props = {
  slicerId: number
  slicerAddress: string
  product: Product
  chainInfo: BlockchainProduct
  ethUsd: number
  editMode: boolean
  displayProduct: boolean
  externalPrices: ExternalPrices
  addToCartQuantity: number
  storeClosed: boolean
}

const chainId = Number(process.env.NEXT_PUBLIC_CHAIN_ID)

const ProductCard = ({
  slicerId,
  slicerAddress,
  product,
  chainInfo,
  ethUsd,
  editMode,
  displayProduct,
  externalPrices,
  addToCartQuantity,
  storeClosed
}: Props) => {
  const { chain } = useAccount()
  const { account, cart, modalView, setModalView, purchases } = useAppContext()
  const {
    id: dbId,
    productId,
    name,
    shortDescription,
    description,
    hash,
    images,
    purchaseInfo,
    uid,
    creator,
    texts,
    allowedAddresses,
    isDelivery
  } = product || {
    id: NaN,
    productId: NaN,
    name: "",
    shortDescription: "",
    description: "",
    hash: "",
    images: [],
    purchaseInfo: {},
    uid: "",
    creator: "",
    texts: {
      thanks: "",
      instructions: ""
    },
    isDelivery: false,
    allowedAddresses: []
  }

  const prices = chainInfo?.prices
  const currencyPrice = prices && prices[0]
  const currency = currencyPrice?.currency.id || ethers.constants.AddressZero
  const price = currencyPrice?.price
  const isUSD = currencyPrice?.dynamicPricing || false
  const externalPriceAddress = currencyPrice?.externalAddress
  const isCustomPriced =
    externalPriceAddress &&
    externalPriceAddress != "0x00000000" &&
    externalPriceAddress != ethers.constants.AddressZero

  // TODO Refactor this to handle  multiple currencies

  const isInfinite = chainInfo?.isInfinite
  const maxUnits = chainInfo?.maxUnitsPerBuyer
  const availableUnits = Number(chainInfo?.availableUnits)
  const totalPurchases = Number(chainInfo?.totalPurchases)
  const extAddress = chainInfo?.extAddress
  const extValue = chainInfo?.extValue
  const extRelativePrice = chainInfo?.extRelativePrice
  const extCheckSig = chainInfo?.extCheckSig
  const extExecSig = chainInfo?.extExecSig
  const referralFeeProduct = chainInfo?.referralFeeProduct
  const isEditable = !!editMode // && account == creator

  // TODO: handle externalValue (eth sent) when currency is USDC
  const totalPrice = isCustomPriced
    ? externalPrices[slicerId] &&
      externalPrices[slicerId][productId] &&
      BigInt(
        externalPrices[slicerId][productId][currency][
          currency === ethers.constants.AddressZero
            ? "ethPrice"
            : "currencyPrice"
        ]
      ) + BigInt(extValue)
    : (price ? Number(price) : 0) + (extValue ? Number(extValue) : 0)
  const externalCallEth = extValue && utils.formatEther(extValue)
  const externalCallUsd =
    externalCallEth && Number(externalCallEth) * Number(ethUsd) * 100

  // const createdAtTimestamp = chainInfo?.createdAtTimestamp

  const [isModalOpened, setIsModalOpened] = useState(false)
  const [convertedEthUsd, setConvertedEthUsd] = useState(0)

  // const cafeId =
  //   constants[process.env.NEXT_PUBLIC_CHAIN_ID][
  //     process.env.NEXT_PUBLIC_ENVIRONMENT
  //   ].constants["slicerIdCafe"]

  const formattedEthPrice =
    totalPrice && currency === ethers.constants.AddressZero
      ? `Ξ ${Math.round(Number(BigInt(totalPrice) / BigInt(10 ** 15))) / 1000}`
      : "Free"
  const formattedUsdPrice = convertedEthUsd
    ? `$ ${formatNumber(Math.round(convertedEthUsd))}`
    : "Free"
  const formattedUSDCPrice =
    totalPrice && currency != ethers.constants.AddressZero
      ? `USDC ${formatNumber(
          Math.round(Number(BigInt(totalPrice) / BigInt(10 ** 4))) / 100
        )}`
      : "Free"
  const purchasedQuantity =
    Number(
      purchases?.find(
        (p: Purchase) =>
          p.slicerId === String(slicerId) && p.productId === String(productId)
      )?.totalQuantity
    ) || 0

  const productPrice = chainInfo
    ? !!currencyPrice || Number(extValue)
      ? isCustomPriced
        ? externalPrices?.[slicerId]?.[productId]
          ? {
              eth: formattedEthPrice,
              usd: `$ ${convertedEthUsd}`,
              usdc: formattedUSDCPrice
            }
          : {
              eth: "Ξ ...",
              usd: "$ ...",
              usdc: "USDC ..."
            }
        : {
            eth: isUSD ? `Ξ ${convertedEthUsd}` : formattedEthPrice,
            usd: isUSD
              ? `$ ${formatNumber(
                  Math.round((Number(price) + externalCallUsd) / 1e6)
                )}`
              : formattedUsdPrice,
            usdc: formattedUSDCPrice
          }
      : {
          eth: "Free",
          usd: "Free",
          usdc: "Free"
        }
    : {
        eth: "Ξ ...",
        usd: "$ ...",
        usdc: "USDC ..."
      }

  const productCart: ProductCart = cart?.find(
    (product) =>
      product.slicerAddress == slicerAddress && product.productId == productId
  )
  const availabilityColor =
    availableUnits &&
    (availableUnits < 10 ? "text-yellow-600" : "text-green-600")

  const priceLabel =
    currency === ethers.constants.AddressZero
      ? productPrice?.eth != "Free"
        ? productPrice.eth
        : "Free"
      : productPrice?.usdc != "Free"
      ? productPrice.usdc
      : "Free"

  const handleOnClick = () => {
    setModalView({
      name: "PRODUCT_VIEW",
      cross: true,
      params: {
        account,
        dbId,
        slicerId,
        productId,
        name,
        shortDescription,
        description,
        image: images?.[0] || "",
        uid,
        creator,
        texts,
        allowedAddresses,
        productPrice,
        isUSD,
        extAddress,
        extValue,
        extCheckSig,
        extExecSig,
        isInfinite,
        maxUnits,
        availableUnits,
        totalPurchases,
        purchaseInfo,
        slicerAddress,
        price,
        editMode,
        purchasedQuantity,
        availabilityColor,
        externalPriceAddress,
        externalPrices,
        isCustomPriced,
        currency,
        extRelativePrice,
        isDelivery,
        referralFeeProduct
      }
    })
  }

  useEffect(() => {
    if (totalPrice && ethUsd) {
      let convertedPrice: number
      if (isUSD) {
        convertedPrice =
          Math.round(
            ((Number(price) + externalCallUsd) * 10) / Number(ethUsd) / 1e4
          ) / 1000
      } else {
        convertedPrice =
          currency === ethers.constants.AddressZero
            ? Math.round(
                Number(
                  (BigInt(totalPrice) *
                    BigInt(Math.round(Number(ethUsd) * 1000))) /
                    BigInt(10 ** 21)
                )
              )
            : Math.round(Number(BigInt(totalPrice) / BigInt(10 ** 4))) / 100
      }
      setConvertedEthUsd(convertedPrice)
    }
  }, [price, totalPrice, ethUsd])

  useEffect(() => {
    if (
      !isModalOpened &&
      displayProduct &&
      (modalView?.name == "" || modalView?.name == "PRODUCT_VIEW") &&
      (!chain || Number(chain.id) === chainId)
    ) {
      handleOnClick()
      if (productPrice.eth !== "Ξ ...") {
        setIsModalOpened(true)
      }
    }
  }, [chain, modalView, displayProduct, productPrice])

  return (
    <>
      {/* {displayProduct && (
        <>
          <NextSeo
            title={`${name} | Product #${productId} | Slicer #${slicerId}`}
            openGraph={{
              title: `${name} | Product #${productId} | Slicer #${slicerId}`,
              description: shortDescription || description,
              url: `${domain}/slicer/${slicerId}?product=${productId}`,
              images: [
                {
                  url: image || `${domain}/product_default.png`,
                  alt: `${name} cover image`
                }
              ]
            }}
          />
          <Head>
            <meta
              name="twitter:image"
              content={image || `${domain}/product_default.png`}
            />
          </Head>
        </>
      )} */}
      <div className="relative h-full">
        <Card
          product
          containerClassName="h-full cursor-pointer"
          cardClassName="flex flex-col group h-full overflow-hidden transition-all duration-300 ease-out bg-white rounded-xl backdrop-blur-0 shadow-medium-random hover:scale-[1.025] mb-14"
          className="rounded-none"
          name={name}
          image={images?.[0] || ""}
          isEditable={isEditable}
          size="h-52"
          topLeft={{
            title: "Purchases",
            content: (
              <>
                <p className="mr-2 text-indigo-600">
                  {totalPurchases ? formatNumber(totalPurchases) : 0}
                </p>
                <ShoppingBag className="w-[18px] h-[18px] text-indigo-600" />
              </>
            )
          }}
          topRight={
            ethUsd && {
              title: "Product price",
              content: (
                <div className="flex items-center justify-center">
                  {isCustomPriced && (
                    <div className="w-5 h-5 mr-2 -ml-1 text-yellow-500 animate-pulse">
                      <Bolt />
                    </div>
                  )}
                  <p
                    className={`text-sm capitalize font-medium text-black${
                      productPrice.usd == "Free" ? " text-green-600" : ""
                    }`}
                  >
                    {productPrice.usd}
                  </p>
                </div>
              )
            }
          }
          bottomLeft={
            chainInfo &&
            !isInfinite &&
            availableUnits && {
              title: "Available units",
              content: (
                <>
                  <p className={`mr-2 ${availabilityColor}`}>
                    {formatNumber(availableUnits)}
                  </p>
                  <Units className={`w-[18px] h-[18px] ${availabilityColor}`} />
                </>
              )
            }
          }
          onClick={() => handleOnClick()}
        >
          <div className="h-full">
            <div className="flex items-center justify-between">
              <div className="mt-1.5">
                <p className="font-medium">
                  {name}{" "}
                  {editMode && (
                    <span className="ml-1 text-xs font-normal text-gray-500">
                      #{productId}
                    </span>
                  )}
                </p>
              </div>
            </div>
            {shortDescription && (
              <div>
                <p className="pt-3 overflow-hidden text-gray-500 overflow-ellipsis">
                  {shortDescription}
                </p>
              </div>
            )}
            <div className="absolute bottom-0 w-full px-5 mb-5 transform -translate-x-1/2 left-1/2">
              {/* slicerId != cafeId && */}
              {!editMode
                ? (!account || purchases) &&
                  chainInfo &&
                  (!isCustomPriced ||
                    (externalPrices[slicerId] &&
                      externalPrices[slicerId][productId])) && (
                    <CartButton
                      isDelivery={isDelivery}
                      slicerId={slicerId}
                      productCart={productCart}
                      slicerAddress={slicerAddress}
                      productId={productId}
                      price={
                        isCustomPriced &&
                        externalPrices[slicerId] &&
                        externalPrices[slicerId][productId]
                          ? externalPrices[slicerId][productId][currency][
                              currency === ethers.constants.AddressZero
                                ? "ethPrice"
                                : "currencyPrice"
                            ]
                          : price
                      }
                      currency={currency}
                      isUSD={isCustomPriced ? false : isUSD}
                      extAddress={extAddress}
                      extCallValue={extValue}
                      extCheckSig={extCheckSig}
                      image={images?.[0] || ""}
                      name={name}
                      maxUnits={Number(maxUnits)}
                      labelAdd={priceLabel}
                      labelRemove={priceLabel}
                      availableUnits={
                        storeClosed ? 0 : isInfinite ? -1 : availableUnits
                      }
                      redeemButton={purchaseInfo?.redeemButton}
                      purchasedQuantity={purchasedQuantity}
                      uid={uid}
                      creator={creator}
                      texts={texts}
                      allowedAddresses={allowedAddresses}
                      shortcodes={purchaseInfo?.shortcodes}
                      dbId={dbId}
                      externalPriceAddress={externalPriceAddress}
                      addToCartQuantity={addToCartQuantity}
                      extRelativePrice={extRelativePrice}
                    />
                  )
                : isEditable && (
                    <DeleteButton slicerId={slicerId} productId={productId} />
                  )}
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default ProductCard
