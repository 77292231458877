import { DoubleText, SliceConnectButton } from "@components/ui"
import { useAppContext } from "@components/ui/context"
import { useEffect } from "react"

export const CONNECT_VIEW = () => {
  const { account, setModalView } = useAppContext()

  useEffect(() => {
    if (account) {
      // track("connect_wallet_success")
      setModalView({ name: "" })
    }
  }, [account])

  return (
    <>
      <div className="pb-6 text-center">
        <DoubleText inactive logoText="Before moving on" />
      </div>
      <div className="text-center">
        <p className="pb-6">
          You need to connect your wallet to complete this action
        </p>
      </div>
      <SliceConnectButton />
    </>
  )
}

export default CONNECT_VIEW
