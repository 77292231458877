export const chainData = {
  1: {
    label: "Ethereum Mainnet",
    url: "https://mainnet.slice.so"
  },
  5: {
    label: "Ethereum Goerli (testnet)",
    url: "https://testnet.slice.so"
  },
  10: {
    label: "Optimism",
    url: "https://optimistic.slice.so"
  },
  8453: {
    label: "Base Mainnet",
    url: "https://slice.so"
  },
  84531: {
    label: "Base Goerli (testnet)",
    url: "https://base-testnet.slice.so"
  }
}
