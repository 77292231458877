import { AddressList, Input, InputAddress, NoteText } from "@components/ui"
import { useEffect, useState } from "react"
import { defaultExternalCall, Hook, HookProps } from "../purchaseHooks"

import clonerInterface from "./abi/cloner.json"
import factoryInterface from "./abi/factory.json"
import deployments from "./deployments.json"
import Add from "@components/icons/Add"

const label = "NFT gate (ERC721)"

const description =
  "Allow purchases only from buyers holding the minimum amount of the specified NFT collections"

const Component = ({ params, setParams }: HookProps) => {
  const [initAddresses, initMinAmount] = params?.deploy?.args || []
  const [addresses, setAddresses] = useState<string[]>(initAddresses || [""])
  const [minAmount, setMinAmount] = useState(initMinAmount || "")

  useEffect(() => {
    setParams({
      externalCall: defaultExternalCall,
      deploy: {
        deployments,
        abi: {
          clonerInterface: clonerInterface.abi,
          factoryInterface: factoryInterface.abi
        },
        args: [addresses, minAmount]
      }
    })
  }, [addresses, minAmount])

  return (
    <>
      <p className="text-sm font-semibold text-gray-700 text-left">
        NFT addresses (ERC721)*
      </p>

      {[...Array(addresses?.length)].map((el, key) => {
        const i = key
        return (
          <AddressList
            key={key}
            index={i}
            addresses={addresses}
            setAddresses={setAddresses}
          />
        )
      })}

      <div className="text-left pt-2">
        <div
          className="inline-flex gap-4 text-green-600 opacity-75 cursor-pointer hover:opacity-100"
          onClick={() => setAddresses((prev) => [...prev, ""])}
        >
          <Add />

          <p className="inline-block font-semibold">Add address</p>
        </div>
      </div>
      <div>
        <Input
          label="Minimum NFT collections to hold*"
          type="number"
          placeholder="1"
          helpText="How many collections must the buyer hold to be able to purchase this product"
          min={1}
          value={minAmount}
          onChange={setMinAmount}
          error={minAmount > addresses.length}
          required
        />
        {minAmount > addresses.length && (
          <div className="text-left">
            <NoteText
              text={`You cannot exceed the total number of NFT addresses (${addresses.length})`}
              error
            />
          </div>
        )}
      </div>
    </>
  )
}

const hook: Hook = { label, description, Component, deployments }

export default hook
