const Immutable = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="32px"
      height="32px"
      {...props}
    >
      <circle
        style={{
          fill: "none",
          stroke: "currentColor",
          strokeWidth: 2,
          strokeMiterlimit: 10
        }}
        cx="16"
        cy="16"
        r="4"
      />
      <g>
        <g>
          <path
            style={{
              fill: "none",
              stroke: "currentColor",
              strokeWidth: 2,
              strokeMiterlimit: 10
            }}
            d="M25,16c0-0.677-0.081-1.335-0.223-1.97l2.615-2.298l-2-3.464l-3.304,1.118c-0.966-0.89-2.122-1.578-3.407-1.979L18,4h-4l-0.681,3.406c-1.286,0.401-2.441,1.089-3.407,1.979L6.608,8.268l-2,3.464l2.615,2.298C7.081,14.665,7,15.323,7,16s0.081,1.335,0.223,1.97l-2.615,2.298l2,3.464l3.304-1.118c0.966,0.89,2.122,1.578,3.407,1.979L14,28h4l0.681-3.406c1.286-0.401,2.441-1.089,3.407-1.979l3.304,1.118l2-3.464l-2.615-2.298C24.919,17.335,25,16.677,25,16z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Immutable
