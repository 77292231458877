import { Dispatch, SetStateAction, useEffect } from "react"
import {
  InputPrice,
  InputSwitch,
  NoteText,
  CardText,
  InputSelect
} from "@components/ui"
import {
  strategiesRender,
  Strategy,
  StrategyParams
} from "@components/priceStrategies/strategies"
import useEthUsd from "@utils/useEthUsd"
import { ethers } from "ethers"
import { constants } from "@lib/constants"
import { EURCAddress } from "@slicekit/core"

export const availableCurrencies = [
  {
    name: "ETH",
    value: ethers.constants.AddressZero
  },
  {
    name: "USDC",
    value:
      constants[process.env.NEXT_PUBLIC_CHAIN_ID][
        process.env.NEXT_PUBLIC_ENVIRONMENT
      ].addresses.USDCPriceFeed
  },
  {
    name: "EURC",
    value: EURCAddress
  }
]

type Props = {
  isFree: boolean
  ethValue: number
  usdValue: number
  isUSD: boolean
  units: number
  productCurrency: string
  priceParams: StrategyParams
  priceStrategy: Strategy
  setEthValue: Dispatch<SetStateAction<number>>
  setUsdValue: Dispatch<SetStateAction<number>>
  setIsUSD: Dispatch<SetStateAction<boolean>>
  setPriceParams: Dispatch<SetStateAction<StrategyParams>>
  setPriceStrategy: Dispatch<SetStateAction<Strategy>>
  setProductCurrency: Dispatch<SetStateAction<string>>
  disabled?: boolean
  currencyDisabled?: boolean
}

const AddProductFormPrice = ({
  isFree,
  ethValue,
  usdValue,
  isUSD,
  units,
  productCurrency,
  priceParams,
  priceStrategy,
  setEthValue,
  setUsdValue,
  setIsUSD,
  setPriceParams,
  setPriceStrategy,
  setProductCurrency,
  disabled,
  currencyDisabled
}: Props) => {
  const StrategyComponent =
    priceStrategy != undefined && priceStrategy.Component

  const ethUsd = useEthUsd()
  const isPrimaryUsd = productCurrency == availableCurrencies[1].value

  const handleSetStrategy = (chosenStrategy: string) => {
    setPriceStrategy(
      strategiesRender.find((strategy) => strategy.label == chosenStrategy)
    )

    const data = { address: "", fields: {} }

    // Set strategy defaults
    switch (chosenStrategy) {
      case "VRGDA":
        if (!data.fields["rate"]) data.fields["rate"] = "Linear"
        break
    }

    setPriceParams(data)
  }

  useEffect(() => {
    if (priceStrategy.label != "Standard") {
      setEthValue(0)
      setUsdValue(0)
      setIsUSD(false)
      if (priceStrategy.label == "VRGDA") {
        const data = { ...priceParams }
        if (!data.fields) {
          data.fields = {}
        }
        if (!data.fields["rate"]) {
          data.fields["rate"] = "Linear"
        }
        setPriceParams(data)
      }
    }
  }, [priceStrategy])

  useEffect(() => {
    if (productCurrency != availableCurrencies[0].value) {
      setIsUSD(false)
    }
  }, [productCurrency])

  return (
    <>
      <div className="pb-6 space-y-3">
        {strategiesRender.map(
          (strategy, i) =>
            strategy && (
              <div key={i} onClick={() => handleSetStrategy(strategy.label)}>
                <CardText
                  label={strategy.label}
                  isActive={strategy.label == priceStrategy.label}
                />
              </div>
            )
        )}
      </div>
      {priceStrategy?.description && (
        <p className="text-gray-600">{priceStrategy.description}</p>
      )}
      <InputSelect
        label="Currency"
        values={availableCurrencies}
        value={productCurrency}
        onChange={(e: any) => setProductCurrency(e.target.value)}
        disabled={currencyDisabled}
      />
      {priceStrategy.label == "Standard" ? (
        <>
          <InputPrice
            ethValue={ethValue}
            setEthValue={setEthValue}
            usdValue={usdValue}
            setUsdValue={setUsdValue}
            label="Price per unit"
            disabled={disabled}
            isPrimaryUsd={isPrimaryUsd}
            isUSD={isUSD}
          />
          {ethUsd != 0 && !isPrimaryUsd && (
            <InputSwitch
              label="Dynamic pricing"
              questionText={
                <>
                  <p>
                    If enabled, the product will be priced in USD{" "}
                    {usdValue != 0 && <b>(${Number(usdValue).toFixed(2)})</b>}{" "}
                    and the ETH amount to be paid is calculated at the moment of
                    purchase.
                  </p>
                </>
              }
              enabled={isUSD}
              setEnabled={setIsUSD}
              disabled={disabled}
            />
          )}
          {isFree ? (
            <div className="text-left">
              <NoteText text="If a price is not set this product can be purchased for free" />
            </div>
          ) : null}
        </>
      ) : (
        <StrategyComponent
          priceParams={priceParams}
          setPriceParams={setPriceParams}
          productCurrency={productCurrency}
          units={units}
          disabled={disabled}
        />
      )}
    </>
  )
}

export default AddProductFormPrice
