import { Button, DoubleText } from "@components/ui"
import { useAppContext } from "@components/ui/context"

export const IRREVERSIBLE_VIEW = () => {
  const { setModalView } = useAppContext()
  return (
    <>
      <div className="pb-6 text-center">
        <DoubleText inactive logoText="Be careful" />
      </div>
      <div className="text-center">
        <p className="pb-4">
          You won&apos;t be able to change the name, description and image of
          your slicer a second time.
        </p>
        <p className="font-bold text-yellow-600">
          Make sure the slicer details and image are correct
        </p>
      </div>
      <div className="flex justify-center pt-8">
        <Button label="Continue" onClick={() => setModalView({ name: "" })} />
      </div>
    </>
  )
}

export default IRREVERSIBLE_VIEW
