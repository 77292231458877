import { Config, readContract } from "@wagmi/core"
import { productsModuleAddress } from "@lib/initProvider"
import { erc20Abi } from "viem"

const GetERC20Allowance = async (
  config: Config,
  address: `0x${string}`,
  buyer: `0x${string}`
) => {
  try {
    return await readContract(config, {
      address,
      abi: erc20Abi,
      functionName: "allowance",
      args: [buyer, productsModuleAddress]
    })
  } catch (err) {
    throw err
  }
}

export default GetERC20Allowance
