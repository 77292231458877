import { hexToString, PublicClient, slice } from "viem"

/**
 * Lookup an address in the NNS registry with fallback to ENS
 */
export async function lookupAddress(
  client: PublicClient,
  address: string | `0x${string}`
) {
  try {
    const res = await client.call({
      to: "0x849f92178950f6254db5d16d1ba265e70521ac1b",
      data: ("0x55ea6c47000000000000000000000000" +
        address.substring(2)) as `0x${string}`
    })

    if (!res.data) return null

    const offset = Number(slice(res.data, 0, 32))
    const length = Number(slice(res.data, offset, offset + 32))
    const data = slice(res.data, offset + 32, offset + 32 + length)
    return hexToString(data) || null
  } catch (e) {
    return null
  }
}
