import Arrow from "@components/icons/Arrow"
import Spinner from "@components/icons/Spinner"
import React, { InputHTMLAttributes } from "react"
import { Question } from ".."

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: string | JSX.Element
  labelSize?: string
  helpText?: string | JSX.Element
  prefix?: string
  after?: string
  error?: boolean
  loading?: boolean
  inverted?: boolean
  submit?: boolean
  question?: JSX.Element
  questionPosition?: string
  onClickLabel?: string
  prefixAction?: (...args: any[]) => any
  onClick?: (...args: any[]) => any
  onChange?: (...args: any[]) => any
  handleOnChangeCustom?: (...args: any[]) => any
}

const Input: React.FC<Props> = (props) => {
  const {
    className,
    label,
    labelSize = "text-sm",
    helpText,
    prefix = "",
    after,
    children,
    error,
    loading,
    disabled,
    inverted,
    submit,
    question,
    questionPosition = "bottom-[20px] left-[-40px] xs:left-0",
    prefixAction,
    onClick,
    onClickLabel,
    onChange,
    handleOnChangeCustom,
    ...rest
  } = props

  const rootClassName = `relative z-10 peer border-0 !ring-0 py-2 pl-5 w-full appearance-none 
  ${
    !prefix
      ? `rounded-tl-sm rounded-bl-sm ${!error ? "shadow-light-focusable" : ""}`
      : ""
  } ${
    !onClick ? "rounded-tr-sm rounded-br-sm" : ""
  } ease-in-out pr-3 focus:outline-none ${className} ${
    error
      ? "text-red-500 bg-white focus:shadow-error"
      : inverted
      ? "bg-black text-white placeholder-gray-500 disabled:text-gray-400 disabled:bg-gray-900"
      : "bg-white text-black placeholder-gray-400 disabled:text-gray-500 disabled:bg-gray-50"
  }`

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value)
    }
    return null
  }

  return (
    <label className="text-left">
      {label && (
        <>
          <div className="pb-2">
            <div className="relative flex items-center">
              <p
                className={`${labelSize} font-semibold pr-1 ${
                  inverted ? "text-gray-200" : "text-gray-700"
                }`}
              >
                {label}
              </p>
              {question && (
                <Question position={questionPosition} text={question} />
              )}
            </div>
            {helpText && (
              <p className="pb-1 text-sm text-gray-600">{helpText}</p>
            )}
          </div>
        </>
      )}
      <div
        className={`relative flex flex-row-reverse mb-3 ${
          prefix && !error ? "shadow-light-focusable overflow-hidden" : ""
        }`}
      >
        <div
          className={`${
            disabled ? "opacity-0" : ""
          } rounded-sm w-full h-full absolute top-0 left-0 shadow-light-focusable transition-all duration-150 animate-pulse-slow`}
        />
        {onClick && (
          <div
            className={`relative text-sm font-medium group flex rounded-tr-sm rounded-br-sm items-center justify-center px-5 text-white nightwind-prevent ${
              error
                ? "cursor-pointer shadow-error bg-red-500"
                : `${
                    !disabled && !loading
                      ? "cursor-pointer bg-gradient-to-br from-gray-800 to-gray-700"
                      : "bg-gray-500 cursor-not-allowed"
                  }`
            }`}
            onClick={!disabled && !loading ? onClick : null}
          >
            {onClickLabel && (
              <span className={`mr-1 ${loading ? "-z-10" : ""}`}>
                {onClickLabel}
              </span>
            )}{" "}
            <div
              className={`w-[1.2rem] h-[1.2rem] text-white nightwind-prevent transition-transform duration-150 ${
                loading ? "-z-10" : ""
              } ${!disabled ? "group-hover:translate-x-1" : ""}`}
            >
              <Arrow />
            </div>
            {loading && (
              <div className="absolute flex items-center justify-center w-full h-full">
                <Spinner />
              </div>
            )}
          </div>
        )}
        <input
          className={rootClassName}
          onChange={
            handleOnChangeCustom
              ? (e: any) => handleOnChangeCustom(e)
              : handleOnChange
          }
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          disabled={disabled || loading}
          onWheel={(e) => e.currentTarget.blur()}
          {...rest}
        />
        {prefix && (
          <div
            className={`flex overflow-hidden items-center text-sm font-medium border-0 justify-center rounded-tl-sm rounded-bl-sm px-5 text-gray-600 bg-gray-200 dark:bg-gray-700 z-10 ${
              prefixAction && !disabled && !loading
                ? "cursor-pointer hover:bg-gray-100"
                : ""
            } ${disabled || loading ? "text-gray-400 bg-gray-100" : ""}`}
            onClick={
              prefixAction && !disabled && !loading
                ? () => prefixAction()
                : null
            }
          >
            {prefix}
          </div>
        )}
      </div>
    </label>
  )
}

export default Input
