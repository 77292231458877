import Button from "../Button"
import Login from "@components/icons/Login"
import { useAccount } from "wagmi"
import { ConnectButton, useConnectModal } from "@rainbow-me/rainbowkit"

type Props = {
  size?: "small" | "large"
}

const SliceConnectButton = ({ size = "small" }: Props) => {
  const { openConnectModal } = useConnectModal()
  const { connector } = useAccount()

  return (
    <>
      {connector && (
        <ConnectButton
          accountStatus={{
            smallScreen: "avatar",
            largeScreen: "full"
          }}
          chainStatus="none"
        />
      )}
      <Button
        className={
          size == "small"
            ? "h-9 font-bold tracking-wide m-[2px] rounded-full overflow-hidden px-6 focus:outline-none"
            : undefined
        }
        rounded={size == "small" ? "rounded-full" : undefined}
        label={
          connector ? null : (
            // ? <><p>{connectText}</p><Login className="w-[18px] h-[18px] mt-[1px]" /></> :
            <div className="flex items-center gap-2 text-sm">
              <p>Login</p>
              <Login className="w-[18px] h-[18px] mt-[1px]" />
            </div>
          )
        }
        onClick={async () => {
          try {
            if (!connector) {
              openConnectModal()
            }
          } catch (e) {
            console.error("Failed to connect", e)
          }
        }}
      />
    </>
  )
}

export default SliceConnectButton
