import { InputAddress } from "@components/ui"
import { useEffect, useState } from "react"
import { Strategy, StrategyProps } from "../strategies"

const label = "Custom"
const description = (
  <>
    Add a custom pricing logic in a smart contract which inherits the{" "}
    <a
      href="https://github.com/slice-so/contracts-pricing/blob/master/src/Slice/interfaces/utils/ISliceProductPrice.sol"
      target="_blank"
      rel="noreferrer"
      className="highlight"
    >
      ISliceProductPrice
    </a>{" "}
    interface.
  </>
)

const Component = ({
  priceParams,
  setPriceParams,
  disabled
}: StrategyProps) => {
  const [address, setAddress] = useState(priceParams?.address || "")
  const [resolvedAddress, setResolvedAddress] = useState("")

  useEffect(() => {
    if (
      address &&
      resolvedAddress != "" &&
      resolvedAddress != "Invalid ENS or NNS name"
    ) {
      setPriceParams({
        label: "",
        address: resolvedAddress || address,
        fields: priceParams?.fields
      })
    }
    if (address == "") {
      return () => {
        setPriceParams(undefined)
      }
    }
  }, [address, resolvedAddress])

  return (
    <>
      <div>
        <InputAddress
          label="Contract address*"
          placeholder="0x…"
          address={address as `0x${string}`}
          onChange={setAddress}
          required
          resolvedAddress={resolvedAddress}
          setResolvedAddress={setResolvedAddress}
          disabled={disabled}
        />
      </div>
    </>
  )
}

const hook: Strategy = { label, description, Component }

export default hook
