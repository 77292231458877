import { FC } from "react"
import Spinner from "@components/icons/Spinner"
import Link from "next/link"
import { track } from "@vercel/analytics"

interface ButtonProps {
  loading?: boolean
  wrapperClassName?: string
  className?: string
  color?: string
  bgColor?: string
  type?: "button" | "submit" | "reset"
  label?: string | JSX.Element
  loadingLabel?: string
  href?: string
  external?: boolean
  targetBlank?: boolean
  disabled?: boolean
  onClick?: any
  trackName?: string
  rounded?: string
}

const Button: FC<ButtonProps> = (props) => {
  const {
    wrapperClassName = "",
    className = "h-9 font-bold tracking-wide m-[2px] rounded-[5px] overflow-hidden px-5 min-w-[150px] focus:outline-none",
    type = "button",
    label,
    loadingLabel,
    href,
    onClick,
    loading = false,
    color = `text-random2-600 hover:text-white bg-white hover:bg-transparent duration-150 text-shadow`,
    bgColor = "bg-gradient-to-br from-random1-300 to-random2-300 nightwind-prevent shadow-light-random",
    external = false,
    targetBlank = true,
    disabled = false,
    trackName = "",
    rounded = "rounded-md",
    ...rest
  } = props

  return (
    label && (
      <div
        className={`relative inline-block ${wrapperClassName}`}
        // onClick={() => (trackName ? track(trackName) : null)}
      >
        {href ? (
          !external ? (
            <Link href={href} className="relative z-10 peer">
              <button className={`${className} ${color}`}>
                <div className="flex items-center justify-center text-sm">
                  <p>{label}</p>
                </div>
              </button>
            </Link>
          ) : targetBlank ? (
            <a
              href={href}
              target="_blank"
              rel="noreferrer"
              className="relative z-10 peer"
            >
              <button className={`${className} ${color}`}>
                <div className="flex items-center justify-center">
                  <p>{label}</p>
                </div>
              </button>
            </a>
          ) : (
            <a href={href} className="relative z-10 peer">
              <button className={`${className} ${color}`}>
                <div className="flex items-center justify-center">
                  <p>{label}</p>
                </div>
              </button>
            </a>
          )
        ) : (
          <button
            className={`peer relative z-10 ${className} ${
              disabled ? "text-white bg-gray-500 cursor-not-allowed" : color
            }`}
            type={type}
            onClick={!disabled && !loading ? onClick : null}
            disabled={disabled}
          >
            {loading ? (
              <div className="flex items-center justify-center w-full">
                {loadingLabel && <p className="mr-3">{loadingLabel}</p>}
                <Spinner className="w-5 h-5" />
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <div>{label}</div>
              </div>
            )}
          </button>
        )}
        <div
          className={`${
            disabled ? "opacity-0" : ""
          } w-full h-full absolute top-0 left-0 ${bgColor} transition-all duration-150 animate-pulse-slow peer-hover:animate-none ${rounded}`}
        />
      </div>
    )
  )
}

export default Button
