const chainId = process.env.NEXT_PUBLIC_CHAIN_ID

const formatBlockscanUrl = (address: string) => {
  let url: string

  switch (chainId) {
    case "5":
      url = "goerli.etherscan.io/"
      break
    case "84531":
      url = "goerli.basescan.org/"
      break
    case "8453":
      url = "basescan.org/"
      break
    case "10":
      url = "optimistic.etherscan.io/"
      break
    default:
      url = "etherscan.io/"
      break
  }

  return "https://" + url + "address/" + address
}

export default formatBlockscanUrl
