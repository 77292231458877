import { productsModuleAddress } from "@lib/initProvider"
import { NewTransaction } from "@rainbow-me/rainbowkit/dist/transactions/transactionStore"
import { Config } from "@wagmi/core"
import { BigNumber } from "ethers"
import { writeContract } from "@wagmi/core"
import { base, Chain } from "viem/chains"
import { erc20Abi } from "viem"

const SetERC20Approve = async (
  config: Config,
  address: string,
  amount: BigNumber,
  addRecentTransaction: (transaction: NewTransaction) => void
) => {
  try {
    const hash = await writeContract(config, {
      address: address as `0x${string}`,
      abi: erc20Abi,
      functionName: "approve",
      args: [productsModuleAddress, BigInt(amount.toHexString())],
      account: address as `0x${string}`,
      chain: base as Chain
    })
    addRecentTransaction({
      hash,
      description: "Approve USDC"
    })
    return hash
  } catch (err) {
    throw err
  }
}

export default SetERC20Approve
