import { DoubleText } from "@components/ui"

export const LOADING_VIEW = () => {
  return (
    <>
      <div className="pb-6 text-center">
        <DoubleText inactive logoText="Loading..." />
      </div>
      <p className="text-lg text-center"> Please wait </p>
    </>
  )
}

export default LOADING_VIEW
