import React from "react"
import { Input } from ".."
import Delete from "@components/icons/Delete"
import { isAddress } from "viem"

type Props = {
  index: number
  label?: string
  addresses: string[]
  setAddresses: React.Dispatch<React.SetStateAction<string[]>>
}

const AddressList: React.FC<Props> = ({
  label,
  index,
  addresses,
  setAddresses
}) => {
  const handleSetAddress = (index: number, value: string) => {
    let items = [...addresses]
    items[index] = value
    setAddresses(items)
  }

  const handleRemove = (index: number) => {
    let items = [...addresses]
    items.splice(index, 1)
    setAddresses(items)
  }

  return (
    <div>
      <div className="flex items-center gap-4">
        <div className="pb-3">
          <Delete
            disabled={addresses?.length === 1}
            onClick={() => handleRemove(index)}
          />
        </div>
        <div className="flex-grow">
          <Input
            label={label}
            value={addresses[index]}
            handleOnChangeCustom={(e: any) =>
              handleSetAddress(index, e.target.value)
            }
            placeholder="0x…"
            error={addresses[index] && !isAddress(addresses[index])}
            required
          />
        </div>
      </div>
    </div>
  )
}

export default AddressList
