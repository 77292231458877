import { WalletClient } from "viem"
import { walletActionsEip5792 } from "viem/experimental"

export const waitForTransactionHash = async (
  walletClient: WalletClient,
  params: { id: string }
) => {
  const { id } = params

  let hash: `0x${string}`
  if (id.startsWith("0x") && id.length == 66) {
    hash = id as `0x${string}`
  } else {
    let callsStatus
    const client = walletClient.extend(walletActionsEip5792())

    while (callsStatus?.status !== "CONFIRMED") {
      // @ts-ignore
      callsStatus = await client.getCallsStatus({
        id
      })
      await new Promise((resolve) => setTimeout(resolve, 500))
    }
    hash = callsStatus?.receipts?.[0].transactionHash as `0x${string}`
  }
  return hash
}
