import { CardImage, ViewPurchaseButton } from ".."
import { RedeemTexts } from "@lib/handleUpdateCart"

export type RedeemData = {
  quantity: number
  purchaseHash: string
}

type Props = {
  dbId: number
  slicerId: number
  productId: number
  name: string
  image: string
  uid: string
  creator: string
  purchaseInfo: string
  texts: RedeemTexts
  shortcodes: string[]
  redeemButton: boolean | undefined
}

const ProductPurchaseCard = ({
  dbId,
  slicerId,
  productId,
  name,
  image,
  uid,
  creator,
  purchaseInfo,
  texts,
  shortcodes,
  redeemButton
}: Props) => {
  return (
    <div className="sm:flex">
      <CardImage
        href={`/slicer/${slicerId}?product=${productId}`}
        name={name}
        imageUrl={image}
        product
      />
      <div className="pt-5 sm:pt-4 sm:ml-6 md:ml-14">
        <div className="flex items-center">
          {name ? (
            <h3 className="text-lg h-6 mb-2 font-medium inline-block">
              {name}
            </h3>
          ) : (
            <div className="w-32 h-6 mb-2 rounded-md bg-sky-300 animate-pulse" />
          )}
          {name && (
            <p className="h-full ml-3 text-sm font-normal text-gray-500">
              {slicerId} / #{productId}
            </p>
          )}
        </div>
        {purchaseInfo != "" ? (
          <div className="text-gray-700">
            <p className="text-sm">
              Contains <b>{purchaseInfo || "..."}</b>
            </p>
          </div>
        ) : null}
        <div className="mt-4">
          <ViewPurchaseButton
            slicerId={slicerId}
            productId={productId}
            dbId={dbId}
            productName={name}
            image={image}
            uid={uid}
            creator={creator}
            texts={texts}
            shortcodes={shortcodes}
            redeemButton={redeemButton}
          />
        </div>
      </div>
    </div>
  )
}

export default ProductPurchaseCard
