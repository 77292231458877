"use client"

import { useContractRead } from "wagmi"
import { priceFeedAddress } from "@lib/initProvider"
import PriceFeedContract from "artifacts/contracts/PriceFeed.sol/PriceFeed.json"
import { parseEther } from "viem"
import { constants } from "@lib/constants"

const chainId = process.env.NEXT_PUBLIC_CHAIN_ID

export const quoteParams = [
  parseEther("1"),
  constants[chainId][process.env.NEXT_PUBLIC_ENVIRONMENT].addresses[
    "ETHPriceFeed"
  ],
  constants[chainId][process.env.NEXT_PUBLIC_ENVIRONMENT].addresses[
    "USDCPriceFeed"
  ],
  1800 // TWAP Interval
]

export default function useEthUsd() {
  // return 1000

  const params = {
    chainId: Number(chainId),
    address: priceFeedAddress,
    abi: PriceFeedContract.abi,
    functionName: "getQuote",
    args: quoteParams,
    watch: true
  }
  // {
  //     address: chainlinkFeedAddress,
  //     abi: ChainlinkContract.abi,
  //     functionName: "latestRoundData",
  //     args: [],
  //     watch: true
  //   }

  const calldata = useContractRead(params)

  return calldata?.data && Number(calldata?.data) / 1e6
  // Chainlink: calldata?.data && Number(calldata?.data[1]) / 1e8
}
