import { Input } from "@components/ui"
import { Dispatch, SetStateAction } from "react"
import Add from "@components/icons/Add"
import { DiscountList } from "."
import { availableCurrencies } from "@components/ui/AddProductFormPrice/AddProductFormPrice"
import { DiscountParams } from "../NFTDiscount/NFTDiscount"

type Props = {
  discountParams: DiscountParams
  setDiscountParams: Dispatch<SetStateAction<DiscountParams>>
  currency: string
}

const KeysDiscountCurrency = ({
  discountParams,
  setDiscountParams,
  currency
}: Props) => {
  return (
    <div className="text-left space-y-6">
      <div>
        <Input
          type="number"
          label={`Base price*`}
          helpText="The product price per unit without discounts applied"
          placeholder={currency == availableCurrencies[0].value ? "0.1" : "100"}
          min={currency == availableCurrencies[0].value ? 0.001 : 0.01}
          step={currency == availableCurrencies[0].value ? 0.001 : 0.01}
          value={discountParams.basePrice || ""}
          handleOnChangeCustom={(e: any) =>
            setDiscountParams((prev) => ({
              ...prev,
              basePrice: e.target.value
            }))
          }
          required
        />
      </div>
      <ul className="pt-4 space-y-2">
        {discountParams.discounts?.map((el, key) => {
          const i = key
          return (
            <DiscountList
              key={key}
              index={i}
              discountParams={discountParams}
              setDiscountParams={setDiscountParams}
            />
          )
        })}
      </ul>
      <div
        className="inline-flex gap-4 text-green-600 opacity-75 cursor-pointer hover:opacity-100"
        onClick={() =>
          setDiscountParams((prev) => ({
            ...prev,
            discounts: [
              ...prev.discounts,
              {
                nft: "",
                discount: 0,
                minQuantity: 1
              }
            ]
          }))
        }
      >
        <Add />

        <p className="inline-block font-semibold">Add keys discount</p>
      </div>
    </div>
  )
}

export default KeysDiscountCurrency
